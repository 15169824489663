.utility{
  background: #261A39;
  min-height: 100px;
  position: relative;

  p{
    font-size: 12px;
  }
}
.claimedRibbon{
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path:
          polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
                  calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
                  var(--r) calc(50% - var(--f)/2));
  background: #0ddcfd;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  width: 100px !important;
  height: 35px !important;
  color: #FFF;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
}


.claimButton{
  width: 60%;
  border-radius: 8px;
  display: flex;
  padding: 12px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
  cursor: pointer;
  color: #F2F6F9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  float: right;
  margin-right: 0rem;

  @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
      margin-top: 1rem;
  }

  &.submit{
    min-width: 150px;
    width: auto;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &:disabled{
    opacity: 0.6;
  }
}
