@import "../../styles/helpers";

.section {
    @include x {
        padding-top: 5rem; }
    @include m {
        padding-top: 4rem; } }

.container {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.wrapper {
    flex: 0 0 calc(100% - 352px);
    width: calc(100% - 352px);
    padding-right: 128px;
    @include x {
        padding-right: 4rem; }
    @include d {
        flex: 0 0 calc(100% - 304px);
        width: calc(100% - 304px);
        padding-right: 2rem; }
    @include t {
        width: 100%;
        padding: 0; } }

.preview {
    flex-shrink: 0;
    width: 352px;
    @include d {
        width: 304px; }
    @include t {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 3rem;
        background: rgba($neutrals1,.9);
        overflow: auto;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        transition: all .4s;
        @include dark {
            background: rgba($neutrals2,.9); }
        &.active {
            visibility: visible;
            opacity: 1; } }
    @include m {
        padding: 2rem 1rem; } }

.head {
    display: flex;
    margin-bottom: 2.5rem;
    @include d {
        flex-direction: column-reverse;
        align-items: flex-start; }
    @include m {
        margin-bottom: 2rem; }
    .button {
        flex-shrink: 0;
        margin-left: 1.5rem;
        @include d {
            margin: 0 0 1rem; } } }

.title {
    margin-right: auto; }

.list {
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;
    border-bottom: 0.063rem solid $neutrals6;
    @include m {
        margin-bottom: 2rem;
        padding-bottom: 2rem; }
    @include dark {
        border-color: $neutrals3; } }

.item {
    &:not(:last-child) {
        margin-bottom: 2.5rem;
        @include m {
            margin-bottom: 2rem; } } }

.note {
    margin-top: 0.25rem;
    @include caption-2;
    color: $neutrals4; }

.file {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 182px;
    margin-top: 1rem;
    border-radius: 1rem;
    overflow: hidden;
    background: $neutrals7;
    @include dark {
        background: $neutrals2; } }

.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 400px;
    opacity: 0; }

.icon {
    margin-bottom: 0.625rem;
    svg {
        fill: $neutrals4; } }

.format {
    @include caption-2;
    color: $neutrals4; }

.category {
    @include body-bold-2; }

.fieldset {
    margin-top: 2rem;
    .field {
        &:not(:last-child) {
            margin-bottom: 2rem;
            @include m {
                margin-bottom: 1.25rem; } } } }

.label {
    margin-bottom: 0.75rem;
    @include hairline-2;
    color: $neutrals5; }

.row {
    display: flex;
    margin: 0 -0.625rem;
    @include m {
        display: block;
        margin: 0; } }

.col {
    flex: 0 0 calc(33.333% - 1.25rem);
    width: calc(33.333% - 1.25rem);
    margin: 0 0.625rem;
    @include m {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 1.25rem; } } }

.options {
    margin-bottom: 2.5rem;
    @include m {
        margin-bottom: 2rem; } }

.option {
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
        margin-bottom: 2rem; } }

.box {
    flex-grow: 1; }

.switch {
    flex-shrink: 0;
    margin-left: 1.5rem; }

.text {
    margin-top: 0.25rem;
    @include caption-2;
    color: $neutrals4; }

.foot {
    display: flex;
    align-items: center;
    @include m {
        display: block;
        text-align: center; }
    .button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 1.5rem;
            @include m {
                margin: 0 0 0.75rem; } } } }

.saving {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    @include button-1; }

.loader {
    margin-left: 1.5rem; }

