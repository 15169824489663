@import "../../styles/helpers";

.menuWrapper_container {
    display: flex;
    @include m {
        justify-content: center; } }

.menuWrapper {
    display: flex;
    background: #1F152E;
    border-radius: 5px;
    margin-bottom: 1em;
    column-gap: 0.625rem;
    padding: 0.625rem;
    font-size: 0.75rem;
    color: #7B7B7B;

    .selected {
        cursor: pointer;
        background: #3E2F5F;
        border-radius: 5px;
        padding: 0.625rem 1.25rem;
        color: white;
        @include s {
            padding: 0.225rem; } }

    .nonSelected {
        cursor: pointer;
        padding: 0.625rem 1.25rem;
        @include s {
            padding: 0.225rem; } } }

