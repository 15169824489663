.cardElement{
  border: solid 1px #EFEFEF;
  background: transparent;
  color: #FFF;

  &.StripeElement--invalid{
    border: solid 1px #fa755a;
  }

  &.StripeElement--complete{
    border: solid 2px #3ab04a;
  }

  @media (max-width: 1024px){
    font-size: 15px;
    padding: 10px 20px;
  }
}
.cardErrorMessage{
  color: #fa755a;
  margin-top: .5rem;
  margin-left: .5rem;
  min-height: 2rem;
}
