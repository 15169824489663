// common styles
body {
    //min-width: 375px
    //background: white
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    word-break: break-word;
    overflow: inherit !important;
    @include poppins;
    font-size: 0.875rem;
    line-height: (24/14);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $neutrals2;
    background-color: #251838 !important;
    @include dark-body {
        background: $neutrals1;
        color: $neutrals8; } }

a {
    text-decoration: none !important; }

svg,
img {
    vertical-align: middle; }

.desktop {
    &-hide {
        @include d {
            display: none !important; } }
    &-show {
        display: none !important;
        @include d {
            display: block !important; } }
    &-text-right {
        @include d {
            text-align: right; } } }

.tablet {
    &-hide {
        @include t {
            display: none !important; } }
    &-show {
        display: none !important;
        @include t {
            display: block !important; } } }

.mobile {
    &-hide {
        @include m {
            display: none !important; } }
    &-show {
        display: none !important;
        @include m {
            display: block !important; } } }

.gdpr-popup-expand-heading {
    color: $secondary !important; }

.gdpr-popup-btn {
    background-color: $neutrals1 !important;
    margin-top: .5em; }

.gdpr-popup-position-top-right {
    border-left: 0.063rem solid #000000 !important; }

.gdpr-popup-heading {
    @include m {
        font-size: 0.75rem; } }

.gdpr-popup-text {
    @include m {
        display: none; } }

.gdpr-popup-container-id {
    form {
        @include m {
            font-size: 0.625rem; } } }

.gdpr-popup-checkbox-heading-container {
    @include m {
        margin-bottom: 0.1em!important; } }

.dark-mode {
    .gdpr-popup-container {
        background-color: #000000 !important; }
    .gdpr-popup-heading, .gdpr-popup-text, .gdpr-popup-save-pref-label, .gdpr-popup-table {
        color: $neutrals6 !important; }
    .gdpr-popup-expand-info {
        background-color: #df67dd;
        border-radius: 50%; } }

.gdpr-popup-btn-green {
    background-color: $secondary !important; }

.gdpr-popup-container {
    max-height: 450px !important;
    padding: 1.25rem 1.125rem !important;
    border: 0.063rem solid #000000;
    @include m {
        padding: 0.75rem 0.625rem !important; } }

.gdpr-popup-save-pref-checkbox {
    &:checked {
        color: $secondary !important; } }

.walletconnect-qrcode__base {
    padding: 4.063rem !important;
    // +m
 }    //     padding: 1.875rem !important

.walletconnect-modal__mobile__toggle {
    a {
        color: $neutrals1 !important; } }

.gdpr-popup-blurred-background {
    display: none !important; }

.page_no {
    cursor: pointer;
    a {
        &:hover {
            background: #1f8ff9;
            color: $white; } } }

.page_switch {
    cursor: pointer;
    &:hover {
        background: #1f8ff9;
        color: $white; } }

.MuiDrawer-paperAnchorRight {
    height: max-content !important;
    border-radius: 0.625rem !important;
    background-color: #FCFCFD !important;
    // +m
    //     width: 50%
    // +s
 }    //     max-width: 60%

.MuiSelect-select:focus {
    background-color: White !important; }

.blocked-countries-dropdown__control {
    div {
        margin-bottom: 0 !important; }
    input {
        height: 1.563rem !important;
        &:after {
            display: none; } }
    .blocked-countries-dropdown__input-container {
         max-height: 1.563rem !important;
         height: auto; } }

.country-select {
    width: 100%;
    height: 2.813rem;
    border: 0.063rem solid #CDCACA;
    border-radius: 0.375rem;
    padding: 0.25rem 0.875rem;
    font-size: 0.875rem;
    cursor: pointer;
    background-color: #fff;

    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em; }

.noRecordsFound {
    padding: 2rem; }
