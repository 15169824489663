@import "../../../styles/helpers";

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -2rem -1rem 0;
    @include x {
        margin: -2rem -0.625rem 0; }
    @include d {
        margin: -2rem -1rem 0; }
    @include m {
        display: block;
        margin: 0; } }

.card {
    flex: 0 0 calc(33.333% - 2rem);
    width: calc(33.333% - 2rem);
    margin: 2rem 1rem 0;
    @include x {
        flex: 0 0 calc(33.333% - 1.25rem);
        width: calc(33.333% - 1.25rem);
        margin: 2rem 0.625rem 0; }
    @include d {
        flex: 0 0 calc(50% - 2rem);
        width: calc(50% - 2rem);
        margin: 2rem 1rem 0; }
    @include m {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 2rem; } } }

.loader {
    margin: 2rem auto 0.625rem; }
