@import "../../../styles/helpers";

.CollectionBannerInfoContainer {
    display: flex;
    border-radius: 0.625rem;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
 }    // height: 14rem

.collectionBody {
    margin: 1rem;

    h1 {
      color: #FFF; }

    h3 {
        font-size: 1.125rem;
        text-align: center; }
    span {
        text-align: center;
        width: 100%; }
    menuWrapper {
        selected {
            font-size: 1.125rem; } } }

.dashBoardWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 100%
    padding: 1.875rem 0.938rem;
    //background-color: #F4F4F4
    border-radius: 0.625rem;

    .imageHolder {
        img {
            height: 20rem;
            width: 100%;
            border-radius: 0.313rem;
            object-fit: cover; } }


    @media only screen and (max-width: "1060px") {
        width: 100%; } }

.collectionContainer {
    width: 100%; }

.collectionInfo {
    h2 {
        font-weight: 700;
        font-size: 1.875rem;
        color: #FFF;
        margin-top: 1rem; }
    .subText {
        color: #8B9CA9; }
    p {
      color: #8B9CA9;
      margin-bottom: 0;
      i {
        color: #FFF;
        font-style: normal; } } }


.collectionCard {
    flex: 0 0 calc(25% - 1.25rem);
    max-width: calc(25% - 1.25rem);
    margin:  2rem 1.25rem 2rem 0px;
    border-radius: 0.625rem;
    transition: transform .1s;
    background: #1F152E;
    float: left;


    @include s {
        max-width: unset;
        width: 100%; }
    @include dark {
       box-shadow: 0px 0px 0.125rem 0.125rem rgba(37, 47, 63, 0.12); } }

subText {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.2); }

.assetContainer {
    display: flex;
    flex-direction: column; }

.infoTable {
    td {
        padding: 0.085rem 0.25rem;
        text-align: left; }


    justify-content: center; }

.pagination {
  display: flex;
  justify-content: center;
  column-gap: 0.313rem;
  list-style: none;
  margin-top: 2rem;
  position: relative;
  overflow: auto;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0 {
 }    //   background: #f6fdfd
  padding: 0.938rem 0px;
  border-radius: 0.625rem;
  a {


    border-radius: 0.625rem;
    padding: 0.938rem;
    color: #FFF;
    background: #3e2f5f;
    border-radius: 0.625rem;
    border-color: #3e2f60; } }

.pagination__link {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }

.pagination__button {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }



.pagination__link__active a {
  color: #FFF !important;
  background: #1f8ff9;
  border-radius: 0.625rem; }

.pagination__link__disabled a {
  color: #FFF;
  border: 0.125rem solid #3e2f5f {
    border-radius: 0.625rem; } }



.page_switch {
    cursor: pointer;
    &:hover {
        background: $secondary;
        color: #ffffff; } }


.collectionCardContainer {
  position: relative; }



