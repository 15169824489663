[class^="section"] {
    padding: 3.125rem 0;
    @include x {
        padding: 0.625rem; }
    @include m {
        padding-top: 1.875rem !important; } }

.section-bg {
    @include dark-common {
        // background: $secondary
        background: $neutrals3; } }

.sectionFixed {
    background: transparent !important;
    @include dark-common {
        background: $neutrals2; } }

.section-pb {
    padding-top: 0;
    @include d {
        padding-top: 0; }
    @include m {
        padding-top: 0; } }

.section-pt80 {
    padding-top: 1.25rem;
    @include d {
        padding-top: 4rem; }
    @include m {
        padding-top: 2rem; } }
