.container {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    @include t {
        padding: 0 2.5rem; }
    @include m {
        padding: 0px; }
    @include largeDevice {
        max-width: 1500px; } }
