@import "../../../styles/helpers";

.category {
    @include body-bold-2; }

.text {
    margin-top: 0.25rem;
    @include caption-2;
    color: $neutrals4; }

.cards {
    display: flex;
    margin: 1.5rem -0.25rem 0;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    @include m {
        margin: 1.5rem -2rem 0; }
    &::-webkit-scrollbar {
        display: none; }
    @include m {
        &:before,
        &:after {
            content: "";
            flex-shrink: 0;
            width: 2rem;
            height: 0.063rem; } } }

.card {
    flex-shrink: 0;
    width: 10rem;
    margin: 0 0.25rem;
    padding: 1.5rem;
    border-radius: 1rem;
    background: $neutrals7;
    @include button-2;
    cursor: pointer;
    @include m {
        margin: 0;
        &:not(:last-child) {
            margin-right: 0.5rem; } }
    @include dark {
        background: $neutrals2; }
    &:hover {
        .plus {
            svg {
                opacity: 1; } } } }

.plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-bottom: 0.75rem;
    svg {
        fill: $neutrals8;
        opacity: 0;
        transition: opacity .2s; } }
