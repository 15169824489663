@import "../../styles/helpers";

.wrapper {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    align-items: center;

    @media only screen and (max-width: "630px") {
        flex-direction: column;
        align-items: flex-start; } }

.WalletDisplayBarBalanceText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2.125rem;
    line-height: 132.5%;
    letter-spacing: -0.02em;
    color: #FFF;
    @media only screen and (max-width: "630px") {
        font-size: 1.563rem; } }

.WalletDisplayBarBalanceLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.03emg;
    color: #FFF; }

.WalletDisplayBarIcon {
 }    //flex: 0 0 20%

.WalletDisplayBarAction {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 0 0 30%;
    @media (max-width: 768px) {
        width: 100%; } }

.WalletDisplayBarBalance {
 }    //flex: 0 0 30%

.copyWalletDiv {
    color: #FFF;
    font-size: 0.7rem;
    cursor: pointer;
    text-align: center;
    font-family: 'Poppins';
    background: linear-gradient(143.38deg, #04DCFD 11.56%, #1F90FA 94.12%);
    border-radius: 10px;
    width: 90%;
    margin: 1rem auto;
    padding: 0.3rem .5rem;
    FONT-WEIGHT: 700;

    span {
        display: inline-block;
        font-weight: normal; } }

.walletInfo {
    display: flex;
    align-items: center;
    flex: 0 0 35%;
    img {
        width: 5rem;
        margin: 2rem;
        @include m {
            margin: 1rem; }
        @media only screen and (max-width: "630px") {
            width: 2.813rem; } } }

.button {
    padding: 16px 24px;
    color: #FFFFFF;
    background: linear-gradient(143.38deg, #04DCFD 11.56%, #1F90FA 94.12%);
    border-radius: 8px;
    color: #FFF;

    @include m {
        padding: 8px 15px;
        margin-top: 1rem; } }

.WalletDisplayBarIcon {
    display: flex;
    justify-content: center; }

.TransferModal {
    display: flex;
    flex-direction: column;
    justify-content: center; }

.depositContainer {
    overflow: auto;
    max-height: 80vh; }


.transferModalLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 4.125rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #1A1A1A; }

.transferModalDescription {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.875rem;
    text-align: center;
    color: #272727;
    margin-top: 0.875rem;
    margin-bottom: 0.75rem;
    text-align: center; }

.TransferModalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    border-bottom: 0.063rem solid #9C9C9C;
    margin-bottom: 1.25rem; }

.transferWrapper {
    background-color: #FFFFFF !important;
    height: 88vh;
    width: 37%;
    align-items: center;
    position: absolute;
    border-radius: 0.625rem;
    left: 25%;
    right: 25%;
    top: 20%;
    bottom: 25%;
    margin: auto;
    padding: 1rem;
    @include t {
        width: 80%;
        left: 11%;
        right: 11%; }
    @include m {
        width: 75%;
        left: 13%;
        right: 13%; }
    @include s {
        width: 95%;
        left: 2%; } }

.depoistPopupContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 1rem;
    background-color: rgba(0,0,0, 0.5);
    z-index: 1001; }


.transferModalCloseButton {
    font-size: 1.25rem;
    cursor: pointer;
    font-weight: 600; }

.paymentButton {
    background: #1A1A1A;
    border-radius: 0.375rem;
    padding: 0.813rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.688rem;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: center;
    width: 244px;
    margin-top: 1.5em;
    cursor: pointer; }


.cryptoAddressLabel {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 2.063rem;
    text-align: center;
    color: #1A1A1A;
    letter-spacing: 0em;
    margin-top: 2rem; }

.cryptoQrCode {
    display: flex;
    flex-direction: row;
    justify-content: center; }

.cryptoAddressButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center; }

.cryptoAddress {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: 0.02em;
    color: #1A1A1A; }

.warningMessage {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center; }

.backArrowContainer {
    border-radius: 50%;
    padding: 0.75rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 }    // height: 3.125rem


.modal {
    // display: flex
    // justify-content: center
    // align-items: center
    height: fit-content; }


.modalContainer {
    width: 100%;
    margin-top: 17%; }

// .selectWallet
//     width: 100%
//     height: 40px

// .selectWallet:active
//     background-color: white


