@import "../../styles/helpers";

.top {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    @include t {
        display: block;
        margin-bottom: 2rem;
        padding-bottom: 0;
        border: none; }
    @include m {
        margin-bottom: 1.5rem; }
    @include dark {
        border-color: $neutrals3; } }

.title {
    margin-right: auto;
    @include body-1;
    @include t {
        display: none; } }

.search {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.625rem;
    flex-shrink: 0;
    width: 100%;
    margin: 0;
    .dropdown {
        width: 11em;
        margin-left: 0.625rem;
        @include m {
            width: 13em; } }
    @include t {
        margin: 0; }
    @media only screen and (max-width: "475px") {
        justify-content: space-between; } }

.sort_container {
    display: flex;
    align-items: center; }

.input {
    width: 100%;
    height: 3rem;
    padding: 0 3rem 0 0.875rem;
    border-radius: 0.75rem;
    background: none;
    border: 0.125rem solid $neutrals6;
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: $blue;
    transition: background .2s;
    svg {
        fill: $neutrals8; }
    &:hover {
        background: darken($blue, 10); } }

.sorting {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    @include t {
        display: block; }
    @include m {
        margin-bottom: 2rem; }
    .dropdown {
        width: 256px;
        margin-right: auto;
        @include t {
            width: 100%;
            margin: 0 0 2rem; } } }

.nav {
    display: flex;
    @include m {
        margin: 0 -2rem;
        overflow: auto;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; }
        &:before,
        &:after {
            content: "";
            flex-shrink: 0;
            width: 2rem;
            height: 0.063rem; } } }

.link {
    flex-shrink: 0;
    margin: 0 0.375rem;
    padding: 0.375rem 0.75rem;
    border-radius: 0.875rem;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    -webkit-tap-highlight-color: transparent;
    @include m {
        margin: 0; }
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        @include m {
            margin-right: 0.75rem; } } }

.row {
    display: flex;
    @include t {
        display: block; } }

.wrapper {
    flex-grow: 1;
    padding-bottom: 1.25rem;
    height: auto;
    overflow: hidden;
    position: relative;

    &.productslist {
        transition: transform 1s; }

    .list {
        height: auto;
        overflow: hidden;
        margin-bottom: 2.5rem;
        @include s {
            padding-top: 0.625rem; }
        // background-color: #e6e6e6
        @include dark {
            background-color: #313131af; } }
    @include t {
        padding-left: 0;
        padding-right: 0; } }

.filters {
    height: 50%;
    border-radius: 0.625rem;
    box-sizing: border-box;
    padding: 1rem;
    flex-shrink: 0;
    margin-right: 0.625rem;
    margin-top: 2.15em;
    background: #3E2F5F;
    color: #FFFFFF;
    transition: transform 1s;

    @include t {
        width: 100%;
        margin-bottom: 2rem; }
    @media only screen and (max-width: "475px") {
        box-shadow: none; } }

// .range
//     margin-bottom: 1.5rem

.scale {
    display: flex;
    justify-content: space-between;
    font-weight: 500; }

.group {
    margin-bottom: 0px;
    padding: 0px 0px 1.5rem 0px;
    // border-width: 0.063rem 0
    border-style: solid;
    border-color: $neutrals6;
    @include dark {
        border-color: $neutrals3; } }

.item {
    &:not(:last-child) {
        margin-bottom: 0.938rem; } }


.marketplaceTitle {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 500;
    line-height: 72px;

    @include m {
        font-size: 30px; } }

.sortButton {
    padding: 16px 24px 16px 24px;
    border-radius: 8px;
    background: linear-gradient(143.38deg, #04DCFD 11.56%, #1F90FA 94.12%);
    color: #FFF;
    cursor: pointer;

    @include m {
        padding: 8px 24px; } }




.labelHeading {
    margin-bottom: 0.75rem;
    // border: 0.125rem solid
    display: flex;
    align-items: center;
    @include poppins;
    font-weight: 500;
    font-size: 1rem;
    color: $neutrals1;
    margin-left: -0.375rem;
    @media only screen and (max-width: "475px") {
        justify-content: space-between; }
    @include dark {
        color: $white; }
    img {
        margin-right: 0.313rem; } }
.subLabel {
    font-weight: 500;
    margin-bottom: 0.313rem;
    color: #FFF;
    @include poppins; }
.reset {
    display: inline-flex;
    align-items: center;
    @include button-2;
    cursor: pointer;
    transition: color .2s;
    svg {
        margin-right: 0.5rem;
        fill: $neutrals2;
        transition: fill .2s;
        @include dark {
            fill: $neutrals8; } }
    &:hover {
        color: $blue;
        svg {
            fill: $blue; } } }

.list {
    display: flex;
    flex-wrap: wrap;
    // margin: -2rem -1rem 0
    margin-bottom: 1rem;
    padding-bottom: 1.25rem;
    background: transparent !important;
    // justify-content: space-between
    @include m {
        display: block;
 } }        // margin: 0 0 2.25rem 0

.card {
    flex: 0 0 calc(25% - 1rem);
    max-width: calc(25% - 1rem);
    // margin: 2rem 0.563rem 0
    margin:  2rem 0.5rem 2rem 0.5rem;
    border-radius: 0.625rem;
    // box-shadow: 0px 72.3232px 57.8586px rgba(0, 0, 0, 0.205)
    // padding: 0.938rem
    // box-shadow: 0px 0px 0.188rem 0.188rem rgba(37, 47, 63, 0.12)
    // border-radius: 0.5rem
    transition: transform 1s;
    background: #261A39;
    // &:hover
    //     border: 0.125rem solid $secondary
    //     padding: 0.813rem
    @include r(1280) {
        flex: 0 0 calc(33.33% - 1rem);
        max-width: 350px; }

    @include r(879) {
        flex: 0 0 calc(50% - 1rem);
        max-width: 350px; }
    @include m {
        max-width: 100%;
        margin: auto;
        &:not(:last-child) {
            margin-bottom: 2rem; } }

    @include dark {
       box-shadow: 0px 0px 0.125rem 0.125rem rgba(37, 47, 63, 0.12); } }

.compactCard {
    flex: 0 0 calc(33.33% - 1rem);
    max-width: calc(33.33% - 1rem);
    margin:  2rem 0.5rem 2rem 0.5rem;
    border-radius: 0.625rem;
    transition: transform 1s;
    background: #261A39;
    @include r(1280) {
        flex: 0 0 calc(33.33% - 1rem);
        max-width: 350px; }

    @include r(879) {
        flex: 0 0 calc(50% - 1rem);
        max-width: 350px; }
    @include m {
        max-width: 100%;
        margin: auto;
        &:not(:last-child) {
            margin-bottom: 2rem; } }
    @include s {
        max-width: 95%; }
    @include dark {
        box-shadow: 0px 0px 0.125rem 0.125rem rgba(37, 47, 63, 0.12); } }


.card.compact {
    flex: 0 0 calc(33.33% - 1rem);
    max-width: calc(33.33% - 1rem); }

.btns {
    margin-top: 2rem;
    text-align: center; }

.filterRangeContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.313rem;
    margin: 0.625rem 0px 0.625rem 0.313rem; }


.maxmin {
    text-align: left;
    width: 6.75rem;
    border: 0.063rem solid $asphalt;
    padding: 0.625rem;
    border-radius: 0.313rem;
    background: $neutrals8;
    // color: $neutrals1
    ::placeholder {
        color: $neutrals1; }
    @include dark {
        background: transparent;
        box-shadow: inset 0 0 0 0.125rem #353945;
        border: none;
        color: $neutrals8; } }

margin {
    text-align: center;
    padding: 0.313rem;
    width: 44%;
    border-radius: 0.75rem;
    background: none;
    border: 0.125rem solid $neutrals6;
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }


.pagination {
  display: flex;
  justify-content: center;
  column-gap: 0.313rem;
  list-style: none;
  margin-top: 2rem;
  position: absolute;
  overflow: auto;
  bottom: 0;
  left: 0;
  right: 0 {
 }    //   background: #f6fdfd
  padding: 0.938rem 0px;
  border-radius: 0.625rem;
  a {
    border-radius: 0.625rem;
    padding: 0.938rem;
    color: #FFF !important;
    background: #3e2f5f;
    border-radius: 0.625rem;
    border-color: #3e2f60; } }


.pagination__link {
    border-radius: 0.625rem !important;
    font-weight: bold;
    white-space: nowrap;
    border: 0.125rem solid; }

.pagination__button {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }




.pagination__link__active a {
    color: #FFF !important;
    background: #1f8ff9;
    border-radius: 0.625rem; }

.pagination__link__disabled a {
    color: #FFF;
    border: 0.125rem solid #3e2f5f {
        border-radius: 0.625rem; } }

.pagination__link__disabled a {
    color: #FFF;
    border: 0.125rem solid #3e2f5f {
        border-radius: 0.625rem; } }





.reset_done_btn_wrap {}
//   border: 0.125rem solid
//display: flex
//column-gap: 0.625rem
//align-items: center


.done {
    @include button-2;
    &:hover {
        color: $blue; } }

.filter_btn {
    @include poppins;
    position: fixed;
    bottom: 3.313rem;
    width: 156px;
    margin-left: -4.875rem;
    left: 50%;
    z-index: 10;
    font-weight: 600;
    background-color: $secondary;
    border-radius: 2.188rem;
    color: $white;
    padding: 0.625rem;
    letter-spacing: 0.063rem;
    font-size: 0.875rem; }


.filter_drawer_wrap {
    padding: 0.625rem 1.25rem;
    height: 100vh;
    @include dark {
        background-color: $neutrals1;
        position: fixed; } }



.filter_drawer_wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    z-index: 999;
    margin-top: 85px !important;
    @include d {
 }        // flex-grow: 0
    @include m {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        align-items: stretch;
        height: 100vh;
        margin: 0;
        padding: 4.125rem 2rem 2.5rem;
        box-shadow: 0px 4rem 4rem rgba(31, 47, 70, 0.2);
        background: $neutrals8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: $neutrals1; }
        &.active {
            visibility: visible;
            opacity: 1; } }
    .button {
        display: none;
        @include m {
            display: inline-flex;
            margin-top: 1rem; } } }

.nav {
    display: flex;
    margin-right: auto;
    border-left: 0.125rem solid $neutrals6;
    @include d {
 }        // display: none
    @include m {
        display: flex;
        flex-direction: column;
        margin: 0 0 2.5rem;
        border: none; }
    @include dark {
        border-color: $neutrals3; } }
.button {
    border-radius: 0.375rem;
    padding: 0px 1rem;
    height: 2.5rem;
    font-weight: 700;
    border: 0.125rem solid black; }

.customButton {
    border-radius: 0.375rem;
    margin-right: .5rem;
    width: 7rem;
    color: #FFF;
    background-color: transparent;
    padding: 0px 1rem;
    height: 2.5rem;
    font-weight: 700;
    border: solid 1px #04DCFD; }




.button_black {
    border-radius: 0.375rem;
    margin-left: 1.25rem;
    width: 6.75rem;
    color: $white;
    background-color: $black;
    padding: 0px 1rem;
    height: 2.5rem;
    font-weight: 700;
    border: 0.125rem solid black; }

.button_blue {
    border-radius: 8px;
    display: flex;
    padding: 7px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
    cursor: pointer;
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    float: right;
    width: 6.75rem; }


.filter_icon {
  display: flex;
  align-items: center;
  padding: 0.625rem;
  border-radius: 2.5rem;
  height: 2.5rem;
  background: #FCFCFD;
  border: 0.063rem solid #d4d4d4;
  margin-left: 0.313rem;
  @include m {
    font-size: 2rem !important;
    height: 2.813rem; }
  @include s {
    font-size: 1.2rem !important; } }


.loaderContent {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background: #e6e8ec52
    margin: auto;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center; }

.collectionCardContainer {
    padding: 0.625rem;
    width: 33%;
    @include m {
        width: 100%; } }
