@import "../../styles/helpers";
.profileContainer {
    display: flex;
    column-gap: 1.25rem;
    @media only screen and (max-width: "1060px") {
        flex-wrap: wrap; } }
.section {
    position: relative; }
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3.75rem 0.938rem;
    background-color: #F4F4F4;
    border-radius: 0.625rem;

    .profilePic {
        margin-right: 0.625rem;
        img {
            height: 3.438rem;
            width: 3.438rem;
            border-radius: 50%; } }

    @media only screen and (max-width: "1060px") {
        width: 100%; } }

.userDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: "1060px") {
        display: flex;
        flex-direction: column;
        align-items:center {}
        justify-content: center; }
    @include s {
        flex: 1 1 0px; }
    h3 {
        font-size: 1.875rem;
        @include s {
            font-size: 1.25rem; } }
    p {
        font-size: 0.875rem;
        color: rgba(0, 0, 0, 0.4); } }

.dashtab {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    align-items: self-start; }

.loaderContent {
    height: 31.25rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background: #e6e8ec52
    margin: auto;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center; }

.menuWrapper {
    display: flex;
    background: #1F152E;
    border-radius: 5px;
    margin-bottom: 1em;
    column-gap: 0.625rem;
    padding: 0.625rem;
    font-size: 0.75rem;
    color: #7B7B7B;
    word-break: break-word;

    .selected {
        cursor: pointer;
        background: #3E2F5F;
        border-radius: 5px;
        padding: 0.625rem 1.25rem;
        color: white;
        @include s {
            padding: 0.625rem; } }

    .nonSelected {
        cursor: pointer;
        padding: 0.625rem .625rem;
        @include s {
            padding: 0.625rem; } } }

.socialMedia {
    margin-top: 1.25rem;
    display: flex;
    column-gap: 0.5rem;
    img {
        cursor: pointer; } }
.cardArea {
    display: flex;
    margin-top: 1.25rem;
    flex-wrap: wrap; }
.cardContainer {
    padding: 0.625rem;
    width: 25%;
    @include m {
        width: 100%; } }
.card {
    border-radius: 0.625rem;
    cursor: pointer;

    background: #1F152E;
    width: 100%;
    .collectionMainInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        padding: .5rem; }

    .assetCount {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            color: #8B9CA9;
            display: inline-block;
            margin-bottom: 0;
            margin-left: 0.3rem; } }


    .collectionName {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFF;
        @include s {
            font-size: 18px;
            line-height: 24px; } }

    .collectionDescription {
        padding: .5rem;
        h4 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #8B9CA9;
            @include m {
                font-size: 14px; } }

        .description {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #FFFFFF;
            @include m {
                font-size: 12px; } } } }





// border: 0.125rem solid
.previewImage {
    position: relative;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    background-color: #80808047;
    align-items: center;
    display: flex;
    overflow: hidden;
    height: 27em;
    img {

        height: 100%;
        object-fit: cover;
        transition: all .2s;
        width: 100%;
        &:hover {
            transform: scale(1.2); } }


    p {
        color: #fff;
        position: absolute;
        top: 86.5%;
        left: 4%;
        font-weight: 700; } }

.assetName {
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 160%;
    margin: 0 0.625rem;
    text-shadow: 0.0.313rem 0.0.313rem $black;
    color: $white;
    z-index: 4;
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: .5rem; }

.logoOwnerWishlist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.125rem;
    font-weight: 500;
    word-break: break-all;
    border-bottom: 0.063rem solid #E7E7E7;
    padding-bottom: 0.75rem;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    margin-top: 1.2em; }
.logoOwner {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    h5 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 800;
        font-size: 1em;
        line-height: 1.313rem;
        letter-spacing: -0.03em;
        color: #000000; }
    p {
        font-weight: 300;
        font-size: 1em;
        line-height: 1.313rem;
        letter-spacing: -0.03em;
        opacity: 0.9; }
    img {
        height: 2.688rem;
        width: 2.688rem;
        border-radius: 50%; } }
.priceRow {
    padding: 0em 1.2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
        margin-bottom: 0;
        color: #8B9CA9;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
        margin-right: 1rem; }

    .priceRowValue {
        color: #F2F6F9;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; } }

.priceRowTwo {
    margin-bottom: 3.474rem; }
.pagination {
  display: flex;
  justify-content: center;
  column-gap: 0.313rem;
  list-style: none;
  margin-top: 2rem;

  overflow: auto;
  bottom: 0;
  left: 0;
  right: 0 {
 }    //   background: #f6fdfd
  padding: 1.188rem 0px;
  border-radius: 0.625rem;
  a {


    border-radius: 0.625rem;
    padding: 0.625rem;
    color: #FFF;
    background: #3e2f5f;
    border-radius: 0.625rem;
    border-color: #3e2f60; } }

.pagination__link {

  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }



.pagination__link__active a {
    color: #FFF !important;
    background: #1f8ff9;
    border-radius: 0.625rem; }

.pagination__link__disabled a {
    color: #FFF;
    border: 0.125rem solid #3e2f5f {
        border-radius: 0.625rem; } }

.msgCenter {
    display: flex;
    justify-content: center;
    width: 100%;
    color: #FFF; }
.backArrowContainer {
    border: 0.063rem solid #E8E8E8;
    border-radius: 50%;
    padding: 0.75rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    margin-bottom: 0.625rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
.profileBorder {

    inset: 0;
    background:  #d6d6d63b;
    z-index: -10; }

.ProfileInfoWrapper {

    // background:  #F5F5F7
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    //.coverImageSection
    //    height: 230px
    //    position: absolute
    //    top: 0
    //    left: 0
    //    z-index: -1
    //    width: 100vw
    //    object-fit: cover
    //    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, rgba(120, 108, 133, 0.37), rgba(120, 108, 133, 0.37))
    //    transform: matrix(-1, 0, 0, 1, 0, 0)
    .profileImage {
        background-size: cover;
        border-radius: 50%;
        height: 7rem;
        width: 7rem;
        max-width: 7rem;
        border-radius: 50%;
        border: solid 5px #04DCFD;
        background-position: center; }

    .profileInfoSection {

 }        // max-width: 360px
    .infoContainer {
        display: flex;
        align-items: center;
        column-gap: 0.625rem; }
    .profileDetailsInfo {
        min-width: 170px;
        h3 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #F2F6F9;
            margin-bottom: 0; }
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #8B9CA9; } }

    .bioDesc {
        padding: 0.625rem;
        max-width: 400px;
        color: #8b9ca9; } }



.bottomContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 2.125rem; }

.profileSocialMediaLinks {
    display: flex;
    flex-direction: row;
    column-gap: 0.625rem;
    margin-top: 0.625rem;
    a {
        text-decoration: none;
        img {
            pointer: cursor;
            width: 1.313rem;
            height: 1.313rem; } } }

.profileNameWrapper {
    display: flex;
    flex-direction: column; }

.container {
    position: relative;
    margin-bottom: 200px; }

.profileName {
    font-family: Poppins;
    font-size: 2.188rem;
    font-weight: 600;
    line-height: 2.813rem;
    letter-spacing: -0.02em;
    text-align: left; }

.profileUsername {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
    color: rgba(0, 0, 0, 0.4); }

.profileBio {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 146.5%;
    color: #7C7C7C; }
.buyNowOrBidNow {
    padding: 0.625rem; }
.buy {
    width: 100%;
    border-radius: 8px;
    display: flex;
    padding: 12px 12px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 8px;
    background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
    cursor: pointer;
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    float: right; }
