@import "../../../styles/helpers";

.item {
    display: flex;
    padding-bottom: 2rem;
    border-bottom: 0.063rem solid $neutrals6;
    @include m {
        position: relative;
        display: block;
        padding: 0 6.25rem 0 0;
        border: none; }
    @include dark {
        border-color: $neutrals3; }
    &:not(:last-child) {
        margin-bottom: 3rem;
        @include m {
            margin-bottom: 2rem; } } }

.follower {
    display: flex;
    align-items: center;
    width: 326px;
    flex: 0 0 326px;
    padding-right: 1.5rem;
    @include d {
        flex: 0 0 244px;
        width: 244px; }
    @include m {
        width: 100%;
        padding-right: 0; } }

.wrap {
    width: calc(100% - 326px);
    flex: 0 0 calc(100% - 326px);
    @include d {
        width: calc(100% - 244px);
        flex: 0 0 calc(100% - 244px); }
    @include m {
        display: none; } }

.avatar {
    flex-shrink: 0;
    width: 88px;
    height: 88px;
    margin-right: 1.25rem;
    @include m {
        width: 4.5rem;
        height: 4.5rem;
        margin-right: 1rem; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }

.title {
    @include body-bold-2; }

.counter {
    @include caption-2;
    color: $neutrals4; }

.button {
    height: 2rem;
    margin-top: 0.75rem;
    padding: 0 0.75rem;
    @include m {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin-top: 0; } }

.gallery {
    display: flex;
    margin: 0 -0.25rem;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none; } }

.preview {
    flex-shrink: 0;
    width: 112px;
    margin: 0 0.25rem;
    img {
        width: 100%;
        border-radius: 0.75rem; } }

.loader {
    margin: 3rem auto 0.625rem; }
