.nav-item.dropdown{
  border-radius: 8px;
  background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
  display: flex;
  padding: 16px 24px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  color: #F2F6F9;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-right: 5.5rem;

  @media (max-width: 768px) {
    background: transparent;
    padding-right: 0px;
    width: 0px;
    color: transparent;
  }
}
.dropdown-toggle::after{
  content: "";
  border: 0px !important;
  display: block;
  width: 24px; /* Adjust width and height as needed */
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M20 5H4V6.596H20V5Z' fill='%23F2F6F9'%3E%3C/path%3E%3Cpath d='M20 11.2031H4V12.7991H20V11.2031Z' fill='%23F2F6F9'%3E%3C/path%3E%3Cpath d='M20 17.4062H4V19.0022H20V17.4062Z' fill='%23F2F6F9'%3E%3C/path%3E%3C/svg%3E");
  background-size: contain; /* Adjust background size if necessary */
  background-repeat: no-repeat;
  position: absolute;
  right: 1rem;
  display: inline-block !important;
}

#siteMenu{
  width: 65px !important;
  @media (max-width: 768px) {
    width: 0px !important;
  }
}

.dropdown-menu{
  transition: all 0.5s ease-in-out !important;
}

.dropdown-menu.show{
  transform: unset !important;
  top: 0px !important;
  background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
  padding-right: 34px !important;

  @media (max-width: 768px) {
    top: 66px !important;
  }

}
.dropdown-item{
  color: #F2F6F9 !important;
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 20px 24px;
  height: 50px;
  line-height: 30px;
  padding-left: 25px !important;

  &:hover{
    background: transparent !important;
  }

}


.noRecordsFound{
  color: #FFFFFF;
}
