@import "../../styles/helpers";

.auction {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include t {
        display: block; } }

.title {
    margin-right: auto;
    @include body-1; }

.link {
    padding: 0.375rem 0.75rem;
    border-radius: 0.875rem;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        margin-right: 0.5rem; } }

.nav {
    display: flex;
    margin: 2rem 0px;
    padding: 0.375rem;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 0.125rem $neutrals6;
    @include dark {
        box-shadow: inset 0 0 0 0.125rem $neutrals3; }
    @include m {
        flex-wrap: wrap;
        button {
            padding: 0.625rem; } } }

.info {
    margin-bottom: 2.5rem;
    @include body-2;
    color: $neutrals4;
    a {
        text-decoration: underline;
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; }
        &:hover {
            text-decoration: none; } } }


.orderitem {
    border-radius: 0.25rem;
    box-shadow: 0px 0px 0.188rem $secondary;
    margin: 1.125rem 0px;
    display: flex;
    flex-wrap: wrap;
    .playerwrapper {
        width: 10%;
        min-width: 6.125rem;
        height: 110px;
        img {
            height: 100%; } }

    .itemdetails {
        padding-left: 0.938rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        @include m {
            font-size: 0.625rem;
            padding-left: 0.063rem;
            min-width: 270px;
            button, a {
                height: 1.875rem;
                padding: 0px 0.625rem;
                font-size: 0.625rem; } }
        h4:first-of-type {
            min-width: 200px; }
        .itemdetailsrow {
            display: flex;
            justify-content: space-between;
            padding: 0.938rem 0.625rem;
            div {
                text-align: center; } }
        .itemdetailsrow:first-of-type {
            border-bottom: 0.125rem solid #9999999a; } } }

.pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
    margin-top: 3rem; }

// a
//     padding: 0.625rem
//     border-radius: 0.313rem
//     border: 0.063rem solid #6c7ac9
//     color: #6c7ac9

.pagination__link {
    font-weight: bold; }

.pagination__link__active a {
    color: #FFF !important;
    background: #1f8ff9;
    border-radius: 0.625rem; }

.pagination__link__disabled a {
    color: #FFF;
    border: 0.125rem solid #3e2f5f {
        border-radius: 0.625rem; } }

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  margin-top: 3rem;

  a {
    padding: 0.625rem;
    color: #FFF;
    background: #3e2f5f;
    border-radius: 0.625rem;
    border-color: #3e2f60; } }

.pagination__link {
  font-weight: bold; }

.pagination__link__active a {
    color: #FFF !important;
    background: #1f8ff9;
    border-radius: 0.625rem; }

.pagination__link__disabled a {
    color: #FFF;
    border: 0.125rem solid #3e2f5f {
        border-radius: 0.625rem; } }
