@import "../../../styles/helpers";

.wrapper {
    display: flex;
    flex-direction: row;
    background-color: #F4F4F4 !important;
    width: 53.313rem !important;
    height: 11.563rem;
    align-items: center;
    overflow: auto; }

.WalletDisplayBarBalanceText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2.125rem;
    line-height: 132.5%;
    letter-spacing: -0.02em;
    color: #000000; }

.WalletDisplayBarBalanceLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.03emg;
    color: rgba(0, 0, 0, 0.4); }

.WalletDisplayBarIcon {
    flex: 0 0 20%; }

.WalletDisplayBarAction {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex: 0 0 50%; }

.WalletDisplayBarBalance {
    flex: 0 0 30%; }

.button {
    background: linear-gradient(143.38deg, #04DCFD 11.56%, #1F90FA 94.12%);
    color: #FFF;
    box-sizing: border-box;
    border-radius: 0.375rem;
    width: 142px;
    height: 3.438rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer; }

.WalletDisplayBarIcon {
    display: flex;
    justify-content: center; }

.TransferModal {
    display: flex;
    flex-direction: column;
    justify-content: center; }


.transferModalLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 4.125rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #FFF; }

.transferModalDescription {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.875rem;
    text-align: left;
    color: #FFF;
    margin-top: 0.875rem;
    margin-bottom: 0.75rem; }

.TransferModalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // padding: 0 1.5rem
    border-bottom: 0.063rem solid rgba(156, 156, 156, 0.5);
    margin-bottom: 1.25rem; }

.transferWrapper {
    background-color: rgb(31, 21, 46)  !important;
    height: fit-content;
    width: 35%;
    align-items: center;
    position: absolute;
    border-radius: 0.625rem;
    left: 25%;
    right: 25%;
    top: 2%;
    bottom: 0%;
    margin: auto;
    padding: 2rem;
    @include d {
        width: 50%; }
    @include t {
        width: 80%;
        left: 11%;
        right: 11%; }
    @include m {
        width: 75%;
        left: 13%;
        right: 13%; }
    @include s {
        width: 95%;
        left: 2%; } }

.depoistPopupContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 1rem;
    background-color: rgba(0,0,0, 0.5);
    z-index: 1000; }


.transferModalCloseButton {
    color: #FFF;
    font-size: 1.25rem;
    cursor: pointer;
    font-weight: 600; }

.paymentButton {
    background: #1A1A1A;
    border-radius: 0.375rem;
    padding: 0.813rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.688rem;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: center;
    width: 244px;
    margin-top: 2.5rem;
    cursor: pointer; }


.balanceDisplayWrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    background: #F4F4F4;
    border-radius: 0.625rem;
    padding: 0.625rem 0.875rem; }

.balanceDisplayContainer {
    display: flex;
    flex-direction: row;
    // justify-content: center
    // padding: 0px 144px
    @include m {
        padding: 0px 4rem; }
    @include s {
        padding: 0px 0.125rem !important; } }

.balanceDisplayLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.313rem;
    color: #1A1A1A; }

// .paddingInputWrapper
//     // padding: 0px 6.063rem
//     +s
//         padding: 0px 2.125rem !important


.balanceDisplayContainer {
    display: flex;
    flex-direction: row; }

.balanceDisplay {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem; }

.circleBalance {
    flex: 0 0 50%; }


.cryptoBalance {
    flex: 0 0 50%; }


.formGroup {
    width: 100%;
    display: block;
    margin: 0.625rem 0px;


    label {
        display: block;
        color: #231F20;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #FFF; }

    .formControl {
        width: 100%;
        padding: 1.156rem 0.938rem;
        border-radius: 0.375rem;
        border: 0.063rem solid #04DCFD;
        background: transparent;
        color: #FFF; }

    .formBtn {
        background: #1A1A1A;
        border-radius: 0.375rem;
        padding: 0.75rem;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.688rem;
        text-transform: capitalize;
        color: #FFFFFF;
        width: 100%; }


    .withdrawContainer {
        padding: 0px 166px !important; }

    .totalAmount {
        display: flex;
        flex-direction: column;
        flex: 0 0 100%; }

    .totalAmountBlock {
        display: flex;
        flex-direction: row; } }

.withDrawLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.688rem;
    color: #FFF;
    margin-top: 1.375rem;
    align-self: flex-start; }

.withDrawModalLabel {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.875rem;
    color: #FFF;
    // width: 48%
    text-align: center; }

.choosePaymentMethodLabel {
    font-family: 'Poppins';
    font-weight: 400 !important;
    font-size: 1rem;
    line-height: 1.875rem;
    color: #FFF;
    text-align: center; }

.flexColumnCenter {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }

.depositAmount {
    width: 100%;
    padding: 1.156rem 0.938rem;
    border-radius: 0.375rem;
    border: 0.063rem solid #04DCFD;
    background: transparent;
    color: #FFF; }

.marginTop3p {
    margin-top: 3%; }

.font600 {
    font-weight: 600; }

.addWireAccount {
    padding: 0.625rem;
    border: 0.125rem solid #231F20;
    border-image-slice: 1 !important;
    border-width: 0.125rem;
    transition: 0.6s;
    border-radius: 0.625rem !important;
    transition: 0.3s ease-in-out;
    background: #231F20;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 900;
    color: #BFBFBF;
    font-size: 0.938rem;
    font-style: normal;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 1.5rem;
    text-transform: capitalize; }

.totalAmount {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    background: #F4F4F4;
    border-radius: 0.625rem;
    padding: 0.125rem 0.875rem; }

.totalAmountWrapper {
    display: flex;
    flex-direction: row;
    // justify-content: center
    align-items: center; }

.totalAmountBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.75rem 1.875rem;
    align-items: center; }

.totalAmountBlockLabel1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.313rem;
    color: #1A1A1A; }

.totalAmountBlockLabel2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.688rem;
    color: #1A1A1A; }

.totalAmountValue1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.688rem;
    display: flex;
    align-items: center;
    color: #1A1A1A; }

.totalAmountValue2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.688rem;
    display: flex;
    align-items: center;
    color: #1A1A1A; }

.withDrawBtn {
    background: linear-gradient(143.38deg, #04DCFD 11.56%, #1F90FA 94.12%);
    border-radius: 0.375rem;
    padding: 0.75rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.688rem;
    text-transform: capitalize;
    color: #FFFFFF;
    width: 100%;
    box-sizing: border-box; }

.lineSplitter {
    border-bottom: 0.063rem solid #D0D0D0;
    width: 82%;
    margin-left: 10%; }

.withDrawWirePaymentAccount {
    display: flex;
    flex-direction: row;
 }    // justify-content: center

.flexRowCenter {
    display: flex;
    flex-direction: row;
 }    // justify-content: center

.flexColumnCenter {
    // display: flex
    // flex-direction: column
 }    // justify-content: center

.withDrawWireMethod {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    // +s
 }    //  // width: 70% !important









