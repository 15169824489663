@import "../../styles/helpers";

.top {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 5rem;
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
    padding-bottom: 0.625rem;
    border-bottom: 0.063rem solid $neutrals6;
    @include t {
        display: block;
        margin-bottom: 2rem;
        padding-bottom: 0;
        border: none; }
    @include m {
        margin-bottom: 1.5rem; }
    @include dark {
        border-color: $neutrals3; } }


// img
//     max-width: 31.25rem

.title {
    margin-right: auto;
    font-size: 1.75rem;
    @include poppins;
    // +t
 }    //     display: none

@media only screen and (max-width: 700px) {
    .title {
        display: block !important; } }

.breadcrumb {
    span {
        font-weight: bold; } }

.section {
    padding: 6rem 0;
    @include x {
        padding: 5rem 0; }
    @include m {
        padding: 4rem 0; } }

.itemTabs {
    margin-top: .5rem; }

.container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    @include t {
        display: block; } }

.bg {
    position: relative;
    flex: 1 1 40%;
    @include x {
        margin-right: 4rem; }
    @include d {
        margin-right: 2rem; }
    @include t {
        margin: 0 0 2rem; } }

.details {
    display: flex;
    flex-direction: column;
    min-width: 334px;
    flex: 1 0 50%;
    margin-left: 1rem;
    border-radius: 0.6rem;
    //min-height: 600px

    @include d {
        margin-left: 0; } }

.itemRightBox {
    border-radius: 16px;
    background: #1F152E;
    padding: 1rem;
    margin-bottom: 1rem;
    flex: 1 0 100%;
    margin-right: 1rem;

    @include m {
        margin-right: 0; } }

.itemTopBox {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #3E2F5F;
    padding-bottom: 1rem;

    @include m {
        flex-direction: column; } }

.productTitle {
    font-weight: 700; }

.preview {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        border-radius: 0px;
        box-shadow: 0.063rem 0px 0.5rem rgba(0, 0, 0, .2);
        //max-width: 31.25rem
        @include m {
            width: 100% !important;
            object-fit: cover; } }
    @include m {
        align-items: center; } }

.categories {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem 0 0 -0.5rem; }

.category {
    margin: 0.5rem 0 0 0.5rem; }

.options {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 1.5rem;
    transform: translateX(-50%); }

.title {
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @include m {
        font-size: 28px !important; } }

.cost {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem; }

.price {
    @include dm-sans;
    font-size: 1.188rem;
    line-height: 1.875rem;
    &:not(:last-child) {
        margin-right: 0.5rem; } }

.counter {
    @include button-1;
    color: $secondary;
    span {
        font-weight: 500;
        color: $neutrals1;
        @include dark {
            color: $white; } } }
.badge {
    font-weight: 100;
    cursor: pointer;
    @include dark {
        background: $white;
        color: $neutrals1; } }

.categoryContainer {
    position: relative;
    display: flex;
    gap: 0.625rem;
    flex-direction: column; }

.multiBadgeContainer {
    width: 70%;
    border: 0.125rem solid; }

.badgeContainer {

    display: flex;
    gap: 0.625rem;

    margin-bottom: 0.938rem;
    justify-content: flex-start;
    flex-wrap: wrap; }
.categoryBadge {
    flex-grow: 1;

    flex-wrap: wrap;
    display: flex; }

.creatorBadge {
    display: flex;

    align-items: center;
    border-radius: 1.875rem;
    padding: 0.313rem;
    box-shadow: 0px 0.625rem 1.25rem 0px #0000000D;


    &:first-child {
        color: $black;
        line-height: 1rem; } }

.ownerBadge {
    display: flex;
    align-items: center;
    border-radius: 1.875rem;
    padding: 0.313rem;
    cursor: pointer;
    &:first-child {
        color: $black;
        line-height: 1rem; }

    .profileImg {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } } }

.ownerBadgeOuter {
    // max-width: 200px
    display: flex;
    align-items: center;
    border-radius: 1.875rem;
    padding: 0.625rem;
    //box-shadow: 0.188rem 1.25rem 2.313rem -0.188rem #0000000d
    // width: 11em
    height: 4em;
    margin-right: 1.1em;
    &:first-child {
        color: $black;
        line-height: 1rem; }
    .ownerDetails {
        padding-left: 0.6em;
        h5 {
            color: #8B9CA9;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: inherit;
            margin-bottom: 0; }
        p {
            color: #F2F6F9;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal; } } }

.inCatagory {
    flex-basis: 98%;
    display: flex;
    @include m {
        flex-basis: 92%;
        justify-content: space-between;
        min-width: 18.75rem; } }

.shortTitle {
    font-size: 0.875rem; }

.subDescription {
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }

.info, .infoDesc {
    margin-bottom: 1.25rem;
    border-radius: 16px;
    background: #1F152E;
    color: $white;
    padding: 1rem;
    @include body-2;
    // color: $neutrals4
    a {
        text-decoration: underline;
        font-weight: 500;
        color: $white;
        @include dark {
            color: $white; }
        &:hover {
            text-decoration: none; } } }

.infoDesc {
    @include dark {
        color: white !important; }
    * {
        @include dark {
            color: $neutrals8 !important; } }
    a {
        color: $secondary !important;
        @include dark {
            color: $secondary !important; } } }



.nav {
    display: flex;
    margin-bottom: 2rem;
    padding: 0.375rem;
    border-bottom: 0.125rem solid $light-pink;
    @include m {
        flex-wrap: wrap;
        button {
            padding: 0.625rem; } } }

.link {
    padding: 0.375rem 0.75rem;
    border-radius: 0.875rem;
    background: none;
    text-transform: uppercase;
    @include button-2;
    color: $neutrals1;
    font-weight: bold;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        color: $light-pink;
        @include dark {
            background: none;
            color: $light-pink; } }
    &:not(:last-child) {
        margin-right: 0.5rem; }
    @include dark {
        color: $white; } }

.users {
    margin-bottom: auto; }

.control {
    margin-top: 2.5rem;
    @include m {
        margin-top: 2rem; } }

.checkout {
    display: flex;
    z-index: 999;
    background: #00000044;
    //min-width: 20rem
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100vh;
    min-height: max-content;
    overflow: auto;
    &.LoaderPopup {
        position: fixed; }
    @include dark {
        background: #ffffff60; }
    .checkoutInner {
        display: flex;
        width: 30%;
        min-width: 20.75rem;
        min-height: 17.563rem;
        overflow: auto;
        border-radius: 0.8em;
        background: #3E2F5F;
        flex-direction: column;
        justify-content: center;
        color: #FFF;
        @include dark {
            background: #141416;
            box-shadow: 0px 0px 0.625rem #ffffffa9; }
        h2 {
            color: #F2F6F9;
            text-align: center;
            font-family: Poppins;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @include a {
                margin: 0px !important; }
            @include s {
                font-size: 1.313rem; } }
        .checkoutDetail {
            margin: 0px auto;
            width: 80%;
            // border-bottom: 0.063rem solid #E4E4E4
            > div {
                @include s {
                    padding: 0px; }
                > p {
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    font-size: 1.125rem;
                    line-height: 1.688rem; } }
            > img {
                width: 170px;
                height: 170px;
                border-radius: 0.313rem; } } } }

.completeCheckout {
    .checkoutInner {
        width: 90%;
        max-width: 680px;
        padding: 1.875rem;
        position: relative;
        @include x {
            width: 62%; }
        @include t {
            width: 75%; }
        @include m {
            width: 85%; }
        @include a {
            width: 95%; }
        @include s {
            padding: 0.625rem;
            top: 100px; }
        .closeBtn {
            position: absolute;
            top: 1.563rem;
            right: 2.5rem;
            width: 1.25rem;
            height: 1.25rem;
            img {
                width: 100%;
                height: 100%; } }
        h2 {
            text-align: left;
            margin-top: 0px !important;
            @include s {
                padding: 0.3em 0em 0.3em 0em; } }
        .checkoutDetail {
            width: 100%;
            display: flex;
            padding: 0.625rem;
            @include m {
                flex-direction: column;
                align-items: center; }
            video, img {
                border-radius: 0.625rem;
                width: 100%;
                height: 100%;
                object-fit: cover; }
            h4 {
                color: #F2F6F9;
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @include s {
                    margin-bottom: 0.938rem; } }
            .checkoutWalletBalance {
                border-radius: 8px;
                background: #3E2F5F;
                margin-left: 0.6rem;
                width: 100%;
                padding: 1rem;
                border-radius: 8px;
                margin-top: 1rem;

                .checkoutPriceInfo {
                    display: flex;
                    flex-direction: column;
                    align-items: self-start;
                    justify-content: space-between; } }

            .checkoutRightWrapper {
                display: flex;
                flex-direction: column;
                width: 61%;
                @include m {
                    width: 100%;
                    margin-top: 1rem; } }
            .space {
                margin-left: 0.6rem;
                width: 100%;
                padding: 1rem;
                border-radius: 8px;
                background: rgba(255, 255, 255, 0.05);
                @include t {
                    width: 100%; }
                @include a {
                   margin-left: 0; }
                @include s;

                .checkoutItemDisplay {
                    display: flex;
                    flex-direction: row; } }


            .checkoutPriceInfo {
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include m {
                    flex-basis: 18.75rem; }
                @include s {
                    padding-bottom: 0.625rem; }
                span {
                    color: #8B9CA9;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal; }
                p {
                    color: #F2F6F9;
                    font-family: Poppins;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 0; } }
            .checkoutTag {
                padding: 1.25rem 0px 0px 0px;
                .buttonFullWidth {
                    width: calc( 100% / 2 - 0.625rem );
                    @include m {
                        width: 100%; }
                    &:disabled {
                        opacity: 0.5; } } } } } }

.bigModal {
    .checkoutInner {
        max-width: 1024px;
        .checkoutDetail {
            .checkoutRightWrapper {
                width: 50%; } } } }

.buttonFullWidthAuc {
    width: 44%;
    padding: 0.5rem 0.938rem;
    border: 0.188rem solid $black;
    background: $black;
    border-radius: 0.375rem;
    @include m {
        width: 100%!important; }

    span {
        color: white;
        font-weight: 400;
        font-size: 0.875rem;
        width: 192px; } }

.buttonCardAucWithoutCrypto {
    width: 100%;
    padding: 0.5rem 0.938rem;
    border: 0.188rem solid $black;
    background: $black;
    border-radius: 0.375rem;
    @include m {
        width: 100%!important; }

    span {
        color: white;
        font-weight: 400;
        font-size: 0.875rem;
        width: 192px; } }
.checkoutWarning {
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @include s {
        font-size: 10px;
        margin-top: 0 !important; }
    span {
        opacity: 0.5; } }
.checkoutTag {
    padding: .875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    // button
    //  // margin-left: 0.625rem
    @include m {
        button {
            margin-bottom: 0.938rem;
            width: 45%; } }
    @include s {
        padding: 0px; } }

.productImages {
    width: 100%;
    margin-top: 0.625rem;
    display: flex;
    justify-content: center;
    position: relative;
    top: 5em;
    .productImageHolder {
        cursor: pointer;
        margin-bottom: 0.625rem;
        width: 5.75rem;
        padding-right: 0.5rem;
        &:not(:first-child) {
            padding: 0px 0.5rem; }
        &:not(:last-child) {
            border-right: 0.125rem solid $secondary; }
        img {
            width: 100%;
            height: 4.375rem;
            object-fit: cover;
            border-radius: 0px; } } }

.customImageDisplayer {
    width: 100%; }

.bidHistory {
    border: 0.063rem solid $secondary;
    text-align: center;
    padding: 0px; }

.buttonFullWidth {
    // width: 100%
    display: flex;
    height: 56px;
    padding: 16px 24px;
    justify-content: center;
    align-items: flex-start;
    gap: 14px;
    flex: 1 0 0;
    border-radius: 8px;
    background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);

    span {
        color: white;
        font-weight: 400;
        font-size: 0.875rem;
        width: 192px;
        @include s; }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed; } }


.buttonFullWidth_buynow {
    margin-top: 0.625rem;
    width: 100%;
    background: $black;
    padding: 0.5rem 0.938rem;
    color: $white;
    border-radius: 0.375rem;
    background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);

    span {
        color: $white;

        font-weight: 900; }
    &:disabled {
        background-color: #a1a1a1d4;
        border: 0.063rem solid #a1a1a1d4;
        color: $white; } }


.readMore {
    cursor: pointer;
    color: $secondary;
    font-weight: 500; }

.rdt_TableBody, .rdt_TableHeadRow {
    @include dark {
        background-color: #000;
        color: #fff; } }

.lightbox_image_wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    background: rgb(31, 21, 46);
    border-radius: 0.6rem;
    @include a {
        width: 100%;
 }        //height: auto
    img {
        width: 100%;
        border-radius: 0.625rem;
        object-fit: contain; } }


.checkoutPlayer {
    width: 400px;
    margin: 0 auto; }
.playerWrapper {
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.625rem; } }
.paymentButton {
    display: flex;
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #6e7881;
    color: #fff;
    font-size: 1em;
    cursor: pointer;
    height: 2.875rem;
    text-align: center;
    width: 190px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 1rem; }

.PaymentMethodWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.313rem; }

.priceContainer {
    box-shadow: 0px 0.563rem 0.938rem 0px #00000014;
    border-radius: 0.375rem;
    padding: 0;

    .priceSection {
        display: flex;
        background: #261A39;
        padding: 1rem;

        .priceMAT {
            flex-grow: 2;

            .priceLabel {
                color: #8B9CA9;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0; }

            .priceAmount {
                margin-top: 0;
                color: #F2F6F9;
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal; } }

        .priceDollar {
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-right: 2rem;

            .priceLabel {
                color: #8B9CA9;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0;
                text-align: center; }

            .priceAmount {
                margin-top: 0;
                color: #F2F6F9;
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal; }

            h2 {
                color: #666666;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center; } } } }

.usdcSymbol {
    width: 1.75rem;
    // height: 1.75rem
    padding-left: 0.5rem;
    bottom: 0.313rem;
    // position: absolute
    left: 2.75rem;
    margin-right: 0.5rem; }

.positionRelative {
    position: relative; }


.backArrowContainer {
    border: 0.063rem solid rgb(35, 38, 47);
    border-radius: 50%;
    padding: 0.75rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    margin-bottom: 0.625rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
.successPopup {
    background: rgba(0,0,0,.4);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    .successPopupContent {
        background: #3E2F5F;
        color: #FFF;
        padding: 1.25rem;
        border-radius: 0.563rem;
        text-align: center;
        margin: 1.25rem;
        width: 31.25rem;
        img {
            margin: 0.938rem auto; }
        p {
            width: 80%;
            margin: 0.938rem auto; }
        button {
            width: 80%;
            border-radius: 8px;
            display: flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            gap: 10px;
            border-radius: 8px;
            background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
            cursor: pointer;
            color: #F2F6F9;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; } } }



.sharePopup {
    position: fixed;
    .checkoutInner {
        padding: 1.25rem;
        width: 35%;
        position: relative;
        .closeBtn {
            position: absolute;
            top: 0.625rem;
            right: 0.625rem;
            width: 0.938rem;
            height: 0.938rem;
            img {
                width: 100%;
                height: 100%; } }
        h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2.188rem;
            // letter-spacing: -1.0.313rem
            margin-bottom: 1.25rem;
            margin-top: 0.625rem;
            color: #FFF;
            text-align: center; }
        .socialBtns {
            text-align: center;
            button {
                margin-right: 1.25rem;
                &:last-child {
                    margin-right: 0px; } } }

        .copyLink {
            width: 100%;
            margin: 0.625rem 0px;
            label {
                margin-bottom: 0.313rem;
                display: block;
                text-align: center; }
            .copyInput {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 0.063rem solid #ddd;
                padding: 0.625rem;
                border-radius: 0.375rem;
                background: #f1efef;

                input {
                    width: 80%;
                    font-size: 0.875rem;
                    font-family: 'Poppins';
                    font-style: normal;
                    &::disabled {
                        background: #f1efef; } }
                span {
                    cursor: pointer; } } } } }



.stop-scrolling {
    height: 100%;
    overflow: hidden; }

.forwardAndLove {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex: 0 0 19%;
    padding-left: 1rem; }

.acknowledgment {
    color: #8B9CA9;
    fontFamily: Poppins;
    fontStyle: normal;
    fontSize: 16px;
    width: 20rem;
    @include m {
        font-size: 12px; } }

.love {
    display: flex;
    height: 2.8em;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    color: $white;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; }

.paymentInfo {
    font-weight: 500;
    font-style: italic;
    font-size: 0.875rem!important;
    margin-top: 1.438rem; }


