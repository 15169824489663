@import "../../styles/helpers";

.top {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    width: calc(100% - 352px);
    padding-right: 128px;
    @include x {
        padding-right: 4rem; }
    @include d {
        width: calc(100% - 304px);
        padding-right: 2rem; }
    @include t {
        width: 100%;
        padding: 0; } }

.title {
    margin-right: auto; }

.button {
    @include m {
        width: 100%;
        margin-top: 1rem; } }

.toggle {
    position: relative;
    margin-left: 1.5rem;
    svg {
        &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            opacity: 0; } }
    &.active {
        background: $blue;
        box-shadow: inset 0 0 0 0.125rem $blue;
        @include dark {
            background: $blue; }
        svg {
            fill: $neutrals8;
            &:first-child {
                opacity: 0; }
            &:nth-child(2) {
                opacity: 1; } } } }

.row {
    display: flex;
    align-items: flex-start;
    margin-top: -3.5rem;
    @include t {
        flex-direction: column-reverse;
        margin-top: 0; } }

.wrapper {
    flex-grow: 1;
    padding-right: 128px;
    padding-top: 7.5rem;
    @include x {
        padding-right: 4rem; }
    @include d {
        padding-right: 2rem; }
    @include t {
        width: 100%;
        padding: 0; } }

.filters {
    flex-shrink: 0;
    width: 352px;
    padding: 3rem;
    border-radius: 1.5rem;
    box-shadow: inset 0 0 0 0.063rem $neutrals6, 0px 2.5rem 2rem -1.5rem rgba(15, 15, 15, 0.12);
    @include d {
        width: 304px;
        padding: 2rem; }
    @include t {
        display: none;
        width: 100%; }
    @include dark {
        box-shadow: inset 0 0 0 0.063rem $neutrals3, 0px 2.5rem 2rem -1.5rem rgba(15, 15, 15, 0.12);
        background: $neutrals2; }
    &.active {
        @include t {
            display: block;
            margin-top: 2rem; }
        @include m {
            margin-top: 1rem; } } }

.nav {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.375rem 2rem;
    @include t {
        margin-top: 2.5rem; }
    @include m {
        margin-top: 2rem; } }

.link {
    margin: 0 0.375rem;
    padding: 0.375rem 0.75rem;
    border-radius: 0.875rem;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8; } }

.item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem 4rem 1rem 1rem;
    border-radius: 1.25rem;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background .2s;
    @include m {
        padding-right: 2rem; }
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 1.5rem;
        width: 0.75rem;
        height: 0.75rem;
        transform: translateY(-50%);
        transition: all .2s;
        @include m {
            right: 0.75rem; } }
    &:before {
        border-radius: 50%;
        background: $blue; }
    &:after {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50% / 0.375rem auto;
        opacity: 0; }
    &:hover {
        background: $neutrals7;
        @include dark {
            background: $neutrals2; }
        &:before {
            transform: translateY(-50%) scale(0); }
        &:after {
            opacity: 1; } }
    &:not(:last-child) {
        margin-bottom: 1rem; } }

.preview {
    position: relative;
    flex-shrink: 0;
    width: 6rem;
    height: 6rem;
    margin-right: 1.5rem;
    @include m {
        width: 4rem;
        height: 4rem;
        margin-right: 1rem; }
    & > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    @include m {
        width: 1.5rem;
        height: 1.5rem; }
    @include dark {
        border: 0.063rem solid $neutrals3; }
    img {
        max-width: 1rem;
        @include m {
            max-width: 0.75rem; } } }

.details {
    flex-grow: 1; }

.subtitle {
    @include body-bold-1;
    @include m {
        margin-bottom: 0.188rem;
        font-size: 1rem; } }

.description {
    @include body-2;
    color: $neutrals3;
    @include m {
        font-size: 0.75rem; }
    @include dark {
        color: $neutrals6; } }

.date {
    @include caption-bold-2;
    color: $neutrals4; }

.loader {
    margin: 2rem auto 0; }
