@import "../../styles/helpers";

.label {
    margin-bottom: 0.75rem;
    @include hairline-2;
    color: $neutrals5; }

.textarea {
    width: 100%;
    height: 6rem;
    padding: 0.625rem 0.875rem;
    resize: none;
    border-radius: 0.75rem;
    border: 0.125rem solid $neutrals6;
    background: none;
    @include poppins;
    @include caption-bold-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }
