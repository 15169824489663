@import "../../../styles/helpers";

.info {
    margin-bottom: 2rem;
    @include body-bold-1; }

.group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 0.063rem solid $neutrals6;
    @include dark {
        border-color: $neutrals3; } }

.checkbox {
    &:not(:last-child) {
        margin-bottom: 1.5rem; } }

.btns {
    display: flex;
    margin: 0 -0.375rem;
    .button {
        width: calc(50% - 0.75rem);
        flex: 0 0 calc(50% - 0.75rem);
        height: 2rem;
        margin: 0 0.375rem;
        padding: 0 0.5rem;
        @include dark {
            box-shadow: inset 0 0 0 0.125rem $neutrals4;
            &:hover {
                box-shadow: inset 0 0 0 0.125rem $blue; } } } }
