@import "../../styles/helpers";

.loaderContent {
  // position: absolute
  // top: 0
  // left: 0
  // bottom: 0
  // right: 0
  // background: #e6e8ec52
  margin: auto;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center; }

.section {
    // background: white
    width: 100%;
    padding: 0;
    @include x {
        padding: 1.25rem 0; }
    @include m {
        padding: 1.5rem 0; } }
.table {
  border: 0.125rem solid black; }

.orderitem_main_container {
    border-radius: 0.625rem;
    background-color: transparent;
    padding: 0.125rem 0rem !important;
    margin: 0px;
    padding-left: 0px !important;
    @include s {
     padding: 0.625rem !important; } }


.orderitem {
    margin-left: 0 !important;
    // background: #FBFBFB
    display: flex;
    flex-wrap: wrap;
    @include s {
      //justify-content: center
      margin-left: 0px !important; }

    .playerwrapper {
      width: 215px;
      height: 168px;
      margin-right: 1.25rem;

      @include m {
        width: 5rem;
        height: 5rem; }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.313rem; } }

    .itemdetails {
        padding: .625rem;
        background: #3E2F5F;

        flex: 1;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: "1060px") {
            font-size: 0.625rem;
            padding-left: 0.063rem;
            //min-width: 270px
            button, a {
                height: 1.875rem !important;
                width: max-content !important;
                padding: 0px 0.938rem !important;
                font-size: 0.625rem; } }



        h4:first-of-type {
            width: 6.25rem;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #F2F6F9; }

        .itemdetailsrow {
            display: flex;
            justify-content: space-between;
            padding: 0.063rem 0.625rem;
            flex-wrap: nowrap;
            @include s {
              display: block; } }




        .itemdetailsrow:first-of-type {} } }

.createdAt {
  color: rgba(75, 75, 75, 0.79);
  @include s {
    font-size: 0.813rem; } }

.viewOnPolygon {
  width: max-content;
  border-radius: 2.5rem;
  padding: 0.063rem 0.625rem 0.063rem 0.063rem;
  border: 0.063rem solid black;
  a {
    font-size: 0.75rem; }
  img {
    padding-right: 0.313rem;
    height: 1.5rem; } }


.pagination {
  display: flex;
  justify-content: center;
  column-gap: 0.313rem;
  list-style: none;
  margin-top: 2rem;

  overflow: auto;
  bottom: 0;
  left: 0;
  right: 0 {
 }    //   background: #f6fdfd
  padding: 1.188rem 0px;
  border-radius: 0.625rem;
  a {


    border-radius: 0.625rem;
    padding: 0.625rem;
    color: #FFF;
    background: #3e2f5f;
    border-radius: 0.625rem;
    border-color: #3e2f60; } }

.pagination__link {

  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }



.pagination__link__active a {
  color: #FFF !important;
  background: #1f8ff9;
  border-radius: 0.625rem; }

.pagination__link__disabled a {
  color: #FFF;
  border: 0.125rem solid #3e2f5f {
   border-radius: 0.625rem; } }



.bold {
  font-weight:bold {}
  color: #C0C0C0;
  margin-bottom: 0.438rem;
  @include m {
    margin: 0 0.313rem 0 0; }
  @include s {
    color: rgba(75, 75, 75, 0.79);
    font-size: 0.813rem;
    font-weight:normal important {} } }

.pending {
  border: 0.125rem solid #F89117;
  background: #F89117;
  color: white;
  border-radius: 1rem;
  padding: 0.313rem 1.25rem 0.313rem 1.25rem  !important;
  font-size: 0.75rem;
  width: 8em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include s {
    padding: 0px !important; }
  p {
    padding: 0px;
    text-transform: capitalize; } }

.failed {
  border: 0.125rem solid #ec4a3a;
  background: #ec4a3a;
  color: white;
  width: 8em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding: 0.313rem 1.25rem 0.313rem 1.25rem  !important;
  font-size: 0.75rem;
  @include s {
    padding: 0px !important; }
  p {
    padding: 0px;
    text-transform: capitalize; } }


.success {
  border: 0.125rem solid #5BD28C;
  background: #5BD28C;
  color: white;
  border-radius: 1rem;
  padding: 0.313rem 1.25rem 0.313rem 1.25rem  !important;
  font-size: 0.75rem;
  width: 8em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include s {
    padding: 0px !important; }
  p {
    padding: 0px; } }


.buttonWrapper {
    display: flex;
    justify-content: space-between;

    padding: 0.063rem 0.625rem;

    flex-wrap: nowrap !important;
    flex-direction: row;

    align-items: center; }


.noRecordsFound {
    flex: 0 0 100%;

    display: flex;

    flex-direction: row;

    justify-content: center; }


.maticSymbol {
    width: 1rem;
    // height: 1rem
    padding-left: 0.25rem; }

.flexCenter {
    display: flex;
    flex-direction: row;
    align-items: center; }


.trxValue {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #F2F6F9;
  text-align: center !important;
  border-right: solid 1px #FFF;
  text-transform: capitalize;

  @media (max-width: 768px) {
    border-right: 0px;
    margin-bottom: .5rem;
    font-size: 12px; }

  &:last-child {
    text-align: right !important;
    border-right: 0px !important;
    @media (max-width: 768px) {
      text-align: center !important; } } }

.trxHeaders {
  color: #8B9CA9;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px; }

.trxInnerHeaders {
  color: #8B9CA9;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  padding: 0 !important;
  margin-bottom: .5rem; }


.trxViewButton {
  padding: 16px 24px;
  color: #FFFFFF;

  /* Gradient_Blue */
  background: linear-gradient(143.38deg, #04DCFD 11.56%, #1F90FA 94.12%);
  border-radius: 8px; }
