@import "../../../styles/helpers";

.user {
    position: relative;
    padding-right: 0px;
    padding-left: 2px;
    @include m {
        position: static; } }

.head {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem 0.25rem 0.25rem;
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.125rem #16c2fd;
    @include button-2;
    cursor: pointer;
    transition: box-shadow .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include dark {
        box-shadow: inset 0 0 0 0.125rem $neutrals3; }
    @include m;

    @include dark {
        box-shadow: inset 0 0 0 0.125rem $neutrals3;
        // +m
 }        //  // box-shadow: none
    &:hover {
        box-shadow: inset 0 0 0 0.125rem #16c2fd;
        @include m {
            box-shadow: none; } } }

.avatarLogin {
    flex-shrink: 0;
    width: 2.813rem;
    height: 2.813rem;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%; } }

.avatar {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%; } }

.wallet {
    margin-left: 0.75rem;
    @include m {
        // display: none
        font-size: 0.688rem; } }

.currency {
    color: $green;
    font-size: 0.75rem; }

.body {
    position: absolute;
    top: calc(100% + 1.25rem);
    left: 50%;
    width: 256px;
    margin-left: -128px;
    padding: 0.813rem 1rem 1.25rem;
    box-shadow: 0px 0px 0.813rem #ff76dd98;
    border-radius: 0.75rem;
    background: $neutrals8;
    opacity: 0;
    transform: scale(.9);
    transform-origin: 50% 3.125rem;
    will-change: transform;
    animation: showUser .4s forwards;
    @include t {
        left: auto;
        right: 0; }
    @include m {
        left: 1rem;
        right: 1rem;
        top: calc(100% + 1rem);
        width: auto;
        margin: 0; }
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 2rem;
        height: 0.813rem;
        transform: translateX(-50%);
        background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50% / 100% auto;
        @include m {
            display: none; } }
    @include dark {
        background: $neutrals2;
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A"); } } }

.subBody {
    position: absolute;
    top: calc(100% + 1.25rem);
    left: 50%;
    width: 256px;
    margin-left: -128px;
    padding: 2rem 1rem 1.25rem;
    box-shadow: 0px 1rem 4rem 0 rgba(31, 47, 70, 0.4);
    border-radius: 0.75rem;
    background: $neutrals8;
    opacity: 0;
    transform: scale(.9);
    transform-origin: 50% 3.125rem;
    will-change: transform;
    animation: showUser .4s forwards;
    @include t {
        left: auto;
        right: 0; }
    @include m {
        left: 1rem;
        right: 1rem;
        top: calc(100% + 1rem);
        width: auto;
        margin: 0; }
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 2rem;
        height: 0.813rem;
        transform: translateX(-50%);
        background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50% / 100% auto;
        @include m {
            display: none; } }
    @include dark {
        background: $neutrals2;
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A"); } }

    @include a {
        position: static;
        top: 0;
        left: 0;
        transform: none;
        z-index: 9999; } }

.subBody {
    left: 150%;
    top: 0px;
    &:before {
        display: none; }
    @include dark {
        background: $neutrals2; } }

@keyframes showUser {
    0% {
        opacity: 0;
        transform: scale(.9); }
    100% {
        opacity: 1;
        transform: scale(1); } }

.name {
    @include body-bold-1; }

.code {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem; }

.number {
    font-weight: 500;
    color: $neutrals4; }

.copy {
    margin-left: 0.5rem;
    svg {
        fill: $blue;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: darken($blue, 10); } } }

.wrap {
    margin-bottom: 0.438rem;
    padding: 0.5rem;
    border-radius: 1rem;
    box-shadow: 0px 1.5rem 1.5rem -0.5rem rgba(15, 15, 15, 0.2); }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 0.625rem; }

.preview {
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%; } }

.info {
    @include caption-2;
    color: $neutrals4; }

.price {
    @include body-bold-1; }

.button {
    width: 100%;
    height: 2rem;
    padding: 0 0.625rem;
    font-size: 0.875rem; }

.item {
    display: flex;
    align-items: center;
    padding: 1.063rem 0 0.75rem;
    @include button-2;
    color: $neutrals4;
    &:not(:last-child) {
        border-bottom: 0.063rem solid $neutrals6;
        @include dark {
            border-color: $neutrals3; } }
    &[href] {
        &:hover {
            color: $blue;
            svg {
                fill: $blue; } } } }

.icon {
    flex-shrink: 0;
    width: 1.25rem;
    margin-right: 0.5rem;
    svg {
        fill: $neutrals4;
        transition: fill .2s; } }

.text {
    margin-right: auto;
    @include dark {
        color: $neutrals5; } }

.textLink, .activeLink {
    color: #1a1a1a;
    @include dark {
        color: #dfdfdf; }
    &:hover {
        background: $light-pink;
        padding: 0px 0.625rem;
        border-radius: 0.313rem; } }

.activeLink {
    background: $light-pink;
    padding: 0px 0.625rem 0.625rem 0.625rem;
    border-radius: 0.313rem;
    position: relative; }

.closeIcon {
  display: flex;
  align-items: center;
  padding: 0.625rem;
  border-radius: 2.5rem;
  height: 2.5rem;
  background: #FCFCFD;
  border: 0.063rem solid #E5E5E5;
  margin-left: 0.938rem;
  @include m {
    font-size: 2rem !important;
    height: 2.813rem; }
  @include s {
    font-size: 1.2rem !important; } }
