@import "../../styles/helpers";

.counter {
    display: flex;
    flex-direction: column;

    justify-content: center;
    p {
        color: #8B9CA9;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; } }

.counterWrap {
    display: flex;
    padding-left: 0.625rem;
    justify-content: flex-start;
    border-radius: 8px;
    border: 1px solid #3E2F5F;
    width: 280px;
    padding: .5rem .5rem;
    margin-right: .5rem;

    @include m {
        width: auto; }

    .counterDay, .counterHour, .counterMinute, .counterSecond {
        width: 65px;
        text-align: center;
        margin: 0;

        @include m {
            width: 55px; }

        > div {
            margin: 0 auto; } }

    h4 {
        color: #F2F6F9;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0; }

    h5 {
        color: #8B9CA9;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0; } }

.counterHour, .counterMinute, .counterSecond, .counterDay {
    text-align: left;
    margin-bottom: 1.2em;
    display: flex;

    span {
        color: #3E2F5F;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; }

    h5 {
        font-weight: 400;
        color: #666666;
        @include m {
            font-size: 0.7em; } } }

.counter div span {
    font-size: 1.563rem;
    margin-left: 0.938rem; }

.form {
    position: relative;
    // width: 40%
    min-width: 18.75rem;
    margin: 2.5rem 0px;
    .formContain {
        z-index: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }

    div {
        margin-bottom: 0.75rem;
        position: relative;
        label {
            color: #F2F6F9;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal; }
        input, .usdcInput {
            border-radius: 8px;
            border: 2px solid #04DCFD;
            color: #F2F6F9;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            background: transparent;
            width: 90%;
            height: 56px;
            padding: 16px 24px;
            align-items: flex-start; }
        div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between; } } }

.input, .inputETH {
    width: 100%;
    padding: 0 0.5rem 0 0.625rem;
    border-radius: 0.313rem;
    border: 0.125rem solid $neutrals5;
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    font-size: 0.75rem;
    font-weight: bolder;
    @include s {
        width: 15.625rem; }
    // background-color: $green
    @include dark {
        border-color: $neutrals3; }
    &:focus {
        border-color: $neutrals3; }
    @include placeholder {
        color: $neutrals4; } }

.inputETH {
    padding: 0.438rem 0.5rem;
    background-color: $white;
    color: $neutrals1;
    overflow: hidden; }

.highBid {
    text-align: center;
    // border: 0.063rem solid $green
    border: 0.063rem solid white;
    border-radius: 0.25rem;
    color: white;
    margin-top: 0.938rem;
    padding: 0.313rem 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0.188rem 0.5rem; }

.buttonFullWidth {
    // width: 100%
    padding: 0.813rem;
    border: 0.125rem solid $black;
    transition: 0.3s ease-in-out;
    color: $black;
    text-transform: uppercase;
    font-weight: 900;
    border-radius: 2.063rem;
    &:hover {
        background: $black;
        color: $white; } }

.buttonFullWidth_buynow {
    margin-top: 0.625rem;
    width: 100%;
    background: $black;
    padding: 0.5rem 0.938rem;
    color: $white;
    border-radius: 0.375rem;
    background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);

    span {
        color: $white;

        font-weight: 900; }
    &:disabled {
        background-color: #a1a1a1d4;
        border: 0.063rem solid #a1a1a1d4;
        color: $white; } }

.buttonFullWidth_buynow:disabled {
    margin-top: 0.625rem;
    width: 100%;
    background: #a1a1a1d4;
    padding: 0.5rem 0.938rem;
    border: 0.063rem solid #a1a1a1d4;
    border-radius: 0.375rem;
    color: $white;
    font-weight: 900;
    cursor: no-drop; }

.auctionContainer {
    box-shadow: 0px 0.563rem 0.938rem 0px #00000014;
    border-radius: 0.375rem;
    padding: 0.938rem;
    .auctionPriceSection {
        display: flex;
        background: #261A39;
        padding: 1rem;
        margin: 1rem 0;

        .auctionPricePoint {
            flex: 0 0 30%;
            text-align: center;
            p {
                font-size: 1em;
                color: #8B9CA9;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; } } } }


.warningMessage {
    font-family: 'Poppins';
    font-weight: 400;
    padding-top: 1.8em;
    font-size: 0.8em;
    line-height: 160%;
    color: #666666; }

.loaderContent {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0 ,0 ,0.20 );
    margin: auto;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center; }

.priceDisplay {
    font-size: 2.2em;
    line-height: 1.6em;
    letter-spacing: 0em;
    margin-bottom: 0;
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; }


.timerContent {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.813rem;
    letter-spacing: -1.0.313rem;
    color: #000000; }

.maticSymbol {
    width: 0.938rem;
    height: 0.8rem;
    padding-left: 0.25rem; }

.flexCenter {
    display: flex;
    flex-direction: row;
    align-items: center; }

.liveBidding {
    animation: rotation 10s infinite linear; }

@keyframes rotation {
    from {
        transform: rotate(0deg); }

    to {
        transform: rotate(359deg); } }
