@import "../../styles/helpers";

.container {
    // padding: 1.25rem 10%
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 3.125rem;
    @include m {
        flex-direction: column;
        align-items: center; } }

.form {
    position: relative;
    width: 55%;
    min-width: 18.75rem;
    padding-top: 0.7em;
    @include m {
        width: 80%; }
    >div {
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative; }
    label {
        width: 10rem;
        padding-left: 0.625rem;
        font-weight: 600;
        @include s {
            padding-left: 0px; } } }
.pageTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 129.5%;
    letter-spacing: -0.0.313rem;
    color: #FFF; }
.input, .inputETH {
    @include poppins;
    background: #3E2F5F;
    color: #FFF;
    margin: 0px 0.625rem;
    width: 200px !important;
    height: 2.625rem;
    padding: 0.375rem 0.375rem 0.375rem 0.75rem;
    border: 0.125rem solid #3E2F5F !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.938rem;
    border-radius: 0.5rem;
    @include m {
        margin: 0.625rem 0px; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals4;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals3; }
    @include placeholder;
    @include s {
        width: 100% !important; } }

.inputETH {
    width: 200px !important;
    height: 2.625rem;
    padding: 0.375rem 0.375rem 0.375rem 0.75rem;
    border: 0.125rem solid $neutrals5;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.938rem;
    border-radius: 0.5rem;
    @include dark {
        border: 0.125rem solid $neutrals3; } }

.input123 {
    width: 200px; }

.btn {
    position: absolute;
    top: 0.313rem;
    right: 1.125rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: $neutrals8;
    @include m {
        top: 0.938rem; }
    svg {
        fill: $neutrals8; } }

.priceField {
    font-size: 0.938rem;
    color: #FFF; }
.title {
    width: 100%;
    padding: 1.25rem 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include body-1;
    @include t {
 } }        // display: none
.backArrowCircle {
    // background: #FFFFFF
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    margin-right: 0.625rem;
    cursor: pointer;
    @include m {
        margin-left: 0.625rem; } }


.fieldWrapper {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    flex-wrap: nowrap !important;
    gap: 0.4em; }



.inputWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 1em;

    input, select {
        background: #3E2F5F;
        border-color: #3E2F5F;
        color: #FFF; }

    @include s {
        width: 100% !important; }
    > div, p {
        width: 16.8em;
        @include s {
            width: 100% !important; } } }


.mediaWrapper {
    flex: 0 0 38%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem; }

.imageContainer {
    width: 100%;
    height: auto;

    img {
        width: 100%;
        height: auto; } }

.resellPublishButton {
    width: 200px;
    border-radius: 8px;
    display: flex;
    padding: 12px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
    cursor: pointer;
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    float: left;
    margin-left: 0.7rem;

    @include m {
        width: 100%;
        margin-left: 0; } }
