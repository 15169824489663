@import "../../styles/helpers";

.wishlistCard {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: transparent;
    border-radius: 0.375rem;
    margin: 0.8em 0; }

.col_1 {
    flex: 0 0 20%; }

.col_2 {
    flex: 0 0 80%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0.8em 0.5em;
    background: #312543;
    border-radius: 8px;
    @include t {
        flex: 0 0 78%; }
    // +m
    //     padding: auto

    .row_1 {
        border-bottom: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 1em 0em;
        @include m {
            border-bottom: none;
            padding: 10px 0; }


        p {
            //flex: 0 0 75%
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 1.2em;
            line-height: 100%;
            letter-spacing: -0.0.313rem;
            color: #FFF; }

        .actionButtons {
            flex: 0 0 25%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;

            button {
                background: linear-gradient(143.38deg, #04DCFD 11.56%, #1F90FA 94.12%);
                color: white;
                border-radius: .625em;
                width: 7em;
                height: 2.5em;
                font-weight: 600;
                font-size: 1em;
                cursor: pointer; } } }

    .row_2 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        @include m {
            flex-direction: column;
            padding-top: 0.4em; }

        .col_1 {
            flex: 0 0 30%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            @include t {
                flex: 0 0 40%; }
            @include m {
                flex: 0; }

            .dateLabel {
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 1.1em;
                line-height: 141.5%;
                letter-spacing: -0.0.313rem;
                color: #C0C0C0; }

            .dateWrapper {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 1.1em;
                line-height: 100.5%;
                letter-spacing: -0.0.313rem;
                color: #FFF;
                @include m {
                    color: #C0C0C0;
                    font-weight: 500; }

                .time {
                    margin-left: 1em; } } }

        .col_2 {
            flex: 0 0 33%;
            @include t {
                flex: 0 0 35%; }
            @include m {
                flex: 0; }

            .priceLabel {
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 1.1em;
                line-height: 141.5%;
                letter-spacing: -0.0.313rem;
                color: #C0C0C0;
                @include m {
                    font-weight: 500;
                    margin-right: 0.625rem;
                    font-size: 1rem; } }

            .priceWrapper {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 1.1em;
                line-height: 141.5%;
                letter-spacing: -0.0.313rem;
                color: #FFF; } }

        .col_3 {
            flex: 0 0 33%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: self-start; } } }

.closeButton {
    height: 2.5em;
    width: 2.5em;
    border-radius: 50%;
    border: 0.063rem solid $white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 1em;
    cursor: pointer;
    @include t {
        margin-left: 1em; }

    @include m {
        border-radius: 1.2em;
        width: 7em;
        height: 2.5em;
        font-weight: 600;
        font-size: 1em; }

    img {
        width: 1.1em;
        height: 1.1em; } }

.inStock {
    // border: 0.125rem solid #5BD28C
    border: 0.063rem solid black;
    // background: #5BD28C
    background: white;
    // color: white
    color: black;
    border-radius: 2em;
    padding: 0.313rem 1.25rem 0.313rem 1.25rem  !important;
    font-size: 1em;
    width: 14em;
    height: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include t {
        width: 9em; }
    @include s {
        padding: 0px !important; }
    p {
        padding: 0px; } }

.inStock_mobile {
    color: #5BD28C;
    font-weight: 500; }

.outOfstock_mobile {
    color: #EF5D5D;
    font-weight: 500; }

.actionButton_mobile {
    display: flex;
    margin-top: 0.625rem;
    &:last-child {
        button {
            background: linear-gradient(143.38deg, #04DCFD 11.56%, #1F90FA 94.12%);
            color: white;
            border-radius: .625em;
            width: 7em;
            height: 2.5em;
            font-weight: 600;
            font-size: 1em;
            cursor: pointer; } } }


.outOfstock {
    // border: 0.125rem solid #EF5D5D
    border: 0.063rem solid black;
    // background: #EF5D5D
    background: black;
    color: white;
    border-radius: 2em;
    padding: 0.313rem 1.25rem 0.313rem 1.25rem  !important;
    font-size: 1em;
    width: 14em;
    height: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include s {
        padding: 0px !important; }
    p {
        padding: 0px; } }


.backButtonWrapper {
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.625rem;
    cursor: pointer; }

.pagination {
    display: flex;
    justify-content: center;
    column-gap: 0.313rem;
    list-style: none;
    margin-top: 2rem;
    overflow: auto;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.188rem 0px;
    border-radius: 0.625rem;

    a {
        border-radius: 0.625rem;
        padding: 0.625rem;
        color: #FFF;
        background: #3e2f5f;
        border-radius: 0.625rem;
        border-color: #3e2f60; } }

.pagination__link {
    border-radius: 0.625rem !important;
    font-weight: bold;
    white-space: nowrap;
    border: 0.125rem solid; }

.pagination__link__active a {
    color: #FFF !important;
    background: #1f8ff9;
    border-radius: 0.625rem; }

.pagination__link__disabled a {
    color: #FFF;
    border: 0.125rem solid #3e2f5f {
        border-radius: 0.625rem; } }


.wishListItem {
    margin-left: 0rem !important;
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
    @include s {
        margin-left: 0px !important;
        margin-right: 2px;
        padding: 0 !important; }

    .playerwrapper {
        width: 215px;
        height: 130px;

        @include m {
             width: 100px;
             height: 170px; }

        img {
            width: 100%;
            height: 100%;
            border-radius: 0.313rem; } }

    .itemdetails {
        padding-left: 0.938rem;
        flex: 1;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: "1060px") {
            font-size: 0.625rem;
            padding-left: 0.063rem;

            button, a {
                height: 1.875rem !important;
                width: max-content !important;
                padding: 0px 0.938rem !important;
                font-size: 0.625rem; } }

        h4:first-of-type {
            width: 6.25rem;
            @include s {
              width: fit-content;
              font-size: 0.75rem; } }
        .itemdetailsrow {
            display: flex;
            justify-content: space-between;
            padding: 0.063rem 0.625rem;
            flex-wrap: nowrap;
            @include s {
                display: block; }
            div {
                text-align: left;
                @include m {
                    display: flex;
                    align-items: center; }
                @include s {
                    flex-basis: 50%;
                    margin-bottom: 0.938rem; } }


            div:nth-child(1),div:nth-child(2) {
                padding-bottom: 0.625rem; } }


        .itemdetailsrow:first-of-type {} } }



.maticSymbol {
    width: 1rem;
    height: 0.9rem;
    padding-left: 0.25rem; }

.price {
    display: flex;
    flex-direction: row;
    align-items: center; }

.loaderContent {
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center; }
