@import "../../styles/helpers";



.container {
    display: flex;
    flex-direction: column;
    gap: 2.2em;
    margin: 3.125rem auto;
    @include m {
        margin: 1.25rem auto; }
    h1 {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 2.8em;
        line-height: 110.18%;
        letter-spacing: -0.03em;
        color: $black; } }

.backArrowCircle {
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.625rem;
    cursor: pointer; }

.round {

    width: 500px;
    // max-width: 50%
    padding: 0.438rem;
    height: 500px;
    margin-right: 0.4em;
    margin-bottom: 0.2em;
    color: #111;
    border-radius: 1%;
    text-align: center;
    font-size: 5.625rem;
    float: left;
    display: flex;
    @include m {
        width: 95%;
        margin: auto auto 2.5rem auto; }
    @include s {
        width: 95%;
        margin-bottom: 1.25rem;
        padding: 0px; }
    img {
        object-fit: contain;
        // max-height: 100%
        height: 100%;
        width: 100%;
        border-radius: 2%; }
    @media screen and (max-width: 600px) {
        width: 300px;
        height: 300px; } }



.newsContent {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 0.85em;
    line-height: 153.18%;
    color: $black; }

.contentWrapper {
    @include m {
        display: flex;
        flex-direction: column; } }
