@import "../../../styles/helpers";

.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    > div {
        font-family: 'Poppins'; }
    >p {
        font-family: 'Poppins'; }
    @include m {
        flex-direction: column-reverse; } }

.bannerDescription {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 1.5em;
    h1 {
        font-size: 2.6em;
        line-height: 1.2em;
        padding-right: 0.6em;
        @include m {
            text-align: center;
            padding-top: 2rem; } }
    p {
        font-weight: 600;
        font-size: 0.9em;
        line-height: 153.18%;
        color: black;
        padding-right: 5em;
        word-wrap: break-word;
        @include m {
            padding-right: 0px; } } }

.bannerPreview {
    flex: 0 0 50%;
    height: auto;
    img {
        box-shadow: 0px 0px 0.813rem 0px rgba(0, 0, 0, .75);
        width: 100%;
        height: 100%;
        border-radius: 0.5rem; } }

.readMore {
    font-weight: 500 !important;
    font-size: 0.9em !important;
    color: #030303 !important;
    padding-right: 0.6em !important; }

.readMoreWrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer; }

.card {
    display: flex;
    width: 75%;
    margin: 1.25rem auto;
    padding: 1.25rem;
    border: 0.063rem solid $secondary;

    &:hover {
        .control {
            visibility: visible;
            opacity: 1; } }
    @include dark {
        // background: $neutrals8
        color: $neutrals8; } }
