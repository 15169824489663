@import "../../../../styles/helpers";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1rem;
    border-bottom: 0.063rem solid $neutrals6;
    @include dark {
        border-color: $secondary; }
    &:not(:last-child) {
        margin-bottom: 1rem; } }

.header {
    font-size: 1.5rem;
    font-weight: 400; }

.billingInput {
    height: 2.625rem;
    padding: 0 0.875rem 0 0.875rem;
    border-radius: 0.313rem;
    border: 0.125rem solid #B1B5C3;
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    line-height: 1.71429;
    color: #23262F;
    transition: border-color .2s; }
