#accordionFAQ{
  .accordion{
    --bs-accordion-bg: transparent;
  }
  .accordion-item{

    background: rgba(31, 21, 46, 1) !important;
    margin-bottom: 1rem;
    border: 0px;

    .accordion-header{
      button{
        font-family: Poppins;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        color:#FFF !important;
        outline: none !important;

        &:not(.collapsed){
          color:#FFF !important;
          background-color: transparent !important;
          outline: none !important;
        }

        &::after{
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
        }

        @media (max-width: 768px){
          font-size: 15px;
        }
      }
    }
    .accordion-body{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFF;

      @media (max-width: 768px){
        font-size: 13px;
        line-height: 20px;
      }

    }


  }
}
