@import "../../styles/helpers";
.checkout_container {
    @include m {
        display: flex;
        justify-content: center; } }
.checkout {
    width: 31.25rem;
    margin: auto;
    position: relative;
    @include m {
        width: 100%; }
    h2 {
        color: #231F20; }
    .addCard {
        padding: 0.625rem;
        border: 0.125rem solid #ff76dc;
        border-image-slice: 1 !important;
        border-width: 0.125rem;
        transition: 0.6s;
        border-radius: 1.563rem !important;
        transition: 0.3s ease-in-out;
        background: #ff76dc;
        color: #231F20;
        text-transform: uppercase;
        font-weight: 900;
        &:hover {
            background: #231F20;
            color: #ffffff; } } }

.checkoutContainer {
    display: flex;
    background-color: #FBFBFB !important;
    margin: auto;
    width: 720px;
    padding: 1.25rem 3.125rem;
    column-gap: 2.5rem;
    border-radius: 0.625rem;
    @include m {
        width: 31.25rem;
        column-gap: 0px;
        padding: 0.125rem 0.313rem;
        margin: 0px;
        justify-content: center;
        background-color: #fff !important; }

    .colOne {
        cursor: pointer;
        @include m {
            display: none; } }
    .colTwo {

        margin-top: 0.625rem;
        width: 100%;
        padding: 0px 2.5rem;
        @include m {
            padding: 0px 0px;
            width: auto; } } }

.formControl {
    width: 100%;
    padding: 0.813rem;
    border-radius: 0.375rem;
    background: #f7f7f7;
    border: 0.063rem solid #ddd;
    @include m {
        background: white; } }

.amountField {
    background-color: #fff;


    padding: 0.625rem;
    margin: 1.25rem 0px 1.25rem 0px;
    @include m {
        background-color: #F8F8F8;
        border-radius: 0.625rem; }
    h3 {
        font-size: 2rem;
        font-weight: 500; }
    p {
        color: #AFAFAF; } }



.inputArea {
    margin-top: 1.25rem;

    p {
        font-weight: 700; } }
.payArea {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.25rem;

    button {
        padding: 0.625rem 1.25rem;
        color: $white;
        width: 200px;
        background: $black;
        border: 0.063rem solid #CDCACA;
        border-radius: 0.375rem;
        @include m {
            width: max-content; } } }
.cardDetails {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    p {
        font-weight: 700; }
    button {
        background-color: #f3f3f3;
        padding: 0.438rem 0.625rem;
        border-radius: 0.625rem;
        font-weight: 700; } }

.cardSelector {
    display: flex;
    flex-direction: row; }







.checkoutHead {
    display: flex;
    align-items: center;
    column-gap: 0.625rem; }
.formGroup {
    width: 100%;
    display: block;
    margin: 0.625rem 0px;

    label {
        display: block;
        color: #231F20;
        font-weight: 700; }
    .formControl {
        width: 100%;
        padding: 0.813rem;
        border-radius: 0.375rem;
        background: #f7f7f7;
        border: 0.063rem solid #ddd;

        @include m {
            background-color: white; } }
    .formBtn {
        width: 100%;
        padding: 0.813rem;
        border: 0.125rem solid #ff76dc;
        border-image-slice: 1 !important;
        border-width: 0.125rem;
        transition: 0.6s;
        border-radius: 1.563rem !important;
        transition: 0.3s ease-in-out;
        background: #ff76dc;
        color: #231F20;
        text-transform: uppercase;
        font-weight: 900;
        margin-top: 0.938rem;
        &:hover {
            background: #231F20;
            color: #ffffff; } } }


.addCardName {
    width: 100%;
    display: flex;
    margin: 0.625rem 0px;
    flex-direction: row; }



.addCardModal {
    width: 31.25rem;

    min-height: 200px;
    // margin: 3.125rem auto

    border-radius: 0.375rem;
    color: #231F20;
    position: relative;
    @include m {
        width:  350px; }

    h2 {
        text-align: left;
        margin-bottom: 1.25rem; }

    .close {
        position: absolute;
        top: 0.313rem;
        right: 0.625rem;
        color: #231F20;
        font-size: 1.25rem; } }

.modalDialog {
    overflow: auto;
    height: 100vh; }
.row {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .col {
        width: calc(100% /2 - 0.625rem);
        @include m {
            width: 100%; } } }
.error {
    color: #ff0000 !important;
    display: block;
    text-align: left; }

.supportedCardsWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    h3 {
        font-size: 0.938rem; }
    img {
        height: auto;
        width: 2rem;
        margin-left: 0.625rem; } }
