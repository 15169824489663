@import "../../../styles/helpers";
.checkout {
    width: 31.25rem;
    margin: auto;
    position: relative;
    @include m {
        width: 100%; }
    h2 {
        color: #231F20; }
    .addCard {
        padding: 0.625rem;
        border: 0.125rem solid #231F20;
        border-image-slice: 1 !important;
        border-width: 0.125rem;
        transition: 0.6s;
        border-radius: 0.625rem !important;
        transition: 0.3s ease-in-out;
        background: #231F20;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
        position: absolute;
        top: 0;
        right: 0;
        color: #BFBFBF;
        font-size: 0.938rem;
        font-style: normal;
        font-family: 'Poppins';
        font-weight: 500;
        line-height: 1.5rem;
        text-transform: capitalize; } }


.formGroup {
    width: 100%;
    display: block;
    margin: 0.625rem 0px;

    label {
        display: block;
        color: #231F20; }
    .formControl {
        width: 100%;
        padding: 0.813rem;
        border-radius: 0.375rem;
        background: #f7f7f7;
        border: 0.063rem solid #ddd; }

    .formBtn {
        padding: 0.625rem;
        border: 0.125rem solid #231F20;
        border-image-slice: 1 !important;
        border-width: 0.125rem;
        transition: 0.6s;
        border-radius: 0.625rem !important;
        transition: 0.3s ease-in-out;
        background: #231F20;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
        color: #BFBFBF;
        font-size: 0.938rem;
        font-style: normal;
        font-family: 'Poppins';
        font-weight: 500;
        line-height: 1.5rem;
        text-transform: capitalize; } }


.addCardName {
    width: 100%;
    display: flex;
    margin: 0.625rem 0px;
    flex-direction: row; }



.addCardModal {
    width: 31.25rem;
    background: #ffffff;
    min-height: 200px;
    margin: 3.125rem auto;
    padding: 1.25rem;
    border-radius: 0.375rem;
    color: #231F20;
    position: relative;
    @include m {
        width: 95%; }
    h2 {
        text-align: center; }

    .close {
        position: absolute;
        top: 0.313rem;
        right: 0.625rem;
        color: #231F20;
        font-size: 1.25rem; } }

.modalDialog {
    overflow: auto;
    height: 100vh; }
.row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .col {
        width: calc(100% /2 - 0.625rem);
        @include m {
            width: 100%; } } }
.error {
    color: #ff0000 !important;
    display: block;
    text-align: left; }

.wireWithDrawLabel {
    margin: 0.25rem 0;
    color: #000000;
    font-size: 0.938rem;
    font-style: normal;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 1.5rem; }

.submitButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.25rem; }


