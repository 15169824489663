.react-tel-input .country-list{
  background: #1f152e !important;
}

.react-tel-input .flag-dropdown.open .selected-flag{
  background: #3e2f60 !important;
}
.react-tel-input .country-list .country.highlight{
  background: #233457 !important;
}
.react-tel-input .country-list .search{
  background-color: #1f152e !important;
  padding: 10px 0 6px 10px;
  z-index: 10000;
}
.react-tel-input .country-list .search-box{
  width: 85% !important;
}
