@import "../../styles/helpers";
.header {
    display: flex;
    box-shadow: 0px 0.563rem 0.938rem 0px #00000014;
    padding: 1.25rem;
    column-gap: 1.25rem;
    border-radius: 0.625rem;
    color: #7B7B7B; }


.selected {

    button {
        @include poppins;
        background-color: black;
        color: white;
        height: 2.5rem;
        padding: 0.625rem 0.938rem;
        border-radius: 0.625rem; } }
.disSelected {
    button {
        @include poppins;
        background-color: white;
        color: black;
        height: 2.5rem;
        padding: 0.625rem 0.938rem;
        border-radius: 0.625rem; } }

.rows {
    margin-top: 0.625rem;
    border-radius: 0.625rem;
    padding: 0.625rem;
    box-shadow: 0px 0.563rem 0.938rem 0px #00000014;
    display: flex;
    align-items: center;
    flex-wrap: no-wrap;
    .avatar {
        flex: 0 0 12%;
        @include s {
            flex: 0 0 16%; } }
    .creator {
        flex: 0 0 68%;
        @include s {
            flex: 0 0 65%; } }
    .amount {
        flex: 0 0 20%;
        @include s {
            flex: 0 0 19%; } } }

.bioCard {
    box-shadow: 0px 0.563rem 0.938rem 0px #00000014;
    box-sizing: border-box;
    padding: 0.938rem;
    border-radius: 0.375rem;
    margin-top: 0.625rem;
    display: flex;
    justify-content: space-between;
    .bioCol {
        margin-top: 0.625rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        div {
            margin-top: 0.625rem; } }

    .bioCol:first-child {
        flex: 0 0 55%;
        padding-right: 4em;
        @include s {
            padding-right: 2em; } }
    .bioCol:last-child {
        flex: 0 0 45%;
        align-items: flex-start;
        justify-content: flex-start; } }

.maticSymbol {
    width: 0.875rem;
    height: 0.563rem;
    padding-left: 0.25rem; }

.flexCenter {
    display: flex;
    flex-direction: row;
    align-items: baseline; }

