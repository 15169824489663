@import "../../styles/helpers";

.title {
    margin-bottom: 2rem; }

.text {
    margin-bottom: 2rem;
    @include body-2;
    color: $neutrals4; }

.info {
    margin-bottom: 0.188rem;
    @include body-bold-1; }

.input {
    width: 100%;
    height: 3rem;
    border-bottom: 0.063rem solid $neutrals6;
    @include poppins;
    @include body-bold-2;
    color: $neutrals2;
    @include placeholder {
        color: $neutrals4; } }

.btns {
    margin-top: 2rem; }

.button {
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 0.5rem; } }
