/*Responsive CSS*/

* {
  margin: 0;
  padding: 0;
}

ul.faq,
li.q,
li.a {
  list-style: none;
}

#container123 {
  /* width: 60%; */
  /* margin: 3.125rem auto; */
  /* overflow: auto; */
  width: 100%;
  max-width: 1280px;
  margin: 2.5rem auto;
  padding: 0 5rem;
}

#container123 h1 {
  text-align: center;
  margin-bottom: 1.25rem;
}
#container123 p {
  padding-bottom: 0.625rem;
}
.tabs {
  /* float: left; */
  height: 3.75rem;
}

.qandas {
  margin-top: 3.125rem;
}

.title123 {
  height: 3.125rem;
  background: #fff;
  color: #000;
  text-align: center;
  padding: 0.813rem 2.25rem;
  margin: 1.813rem 0.875rem;
  cursor: pointer;
  border-radius: 0.75rem;
  float: left;
}

.faq a {
  color: #ff76dc;
  text-decoration: none;
}
.faq li {
  padding: 1.25rem;
  color: #fff;
  background-color: #000;
}

.faq li.q,
.faq div.q {
  background: #000;
  font-weight: bold;
  font-size: 120%;

  cursor: pointer;
}

.faq img {
  float: right;
  margin-top: 0.188rem;
}
.faq li.a {
  display: none;
  color: #fff;
  font-size: 1.125rem;
  width: 100%;
  text-align: justify;
}

.rotate {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
@media only screen and (max-width: 1340px) and (min-width: 1130px) {
  #container123 {
    width: 90%;
  }
  /*.title {*/
  /*  height: 1.25rem;*/
  /*  background: #fff;*/
  /*  color: #000;*/
  /*  text-align: center;*/
  /*  padding: 0.813rem 1.625rem;*/
  /*  margin: 1.813rem 0.625rem;*/
  /*  cursor: pointer;*/
  /*  border-radius: 0.75rem;*/
  /*  float: left;*/
  /*}*/
  .tabs {
    /* float: left; */
    margin: 2% 15%;
  }
  .qandas {
    width: 70%;
    margin-left: 14%;
  }
}

@media only screen and (max-width: 1129px) and (min-width: 450px) {
  .title123 {
    height: 1.25rem;
    background: #fff;
    color: #000;
    text-align: center;
    padding: 0.813rem 1rem;
    margin: 0.438rem 0.625rem;
    cursor: pointer;
    border-radius: 0.75rem;
    float: left;
    width: 70%;
  }
  .tabs {
    /* float: left; */
    margin: 2% 15%;
  }
  #container123 {
    width: 80%;
  }
}
@media only screen and (max-width: 449px) {
  #container123 {
    width: 90%;
    margin: 1.25rem auto;
    padding: 0px;
  }
  .tabs {
    /* float: left;     */
    margin: 2% 15%;
    height: 230px;
  }
  .title123 {
    height: 4.875rem;
  }
}
