
.deliveryForm, .paymentMethodForm{
  width: 100%;
  .react-tel-input .flag-dropdown, .react-tel-input .flag-dropdown.open, .react-tel-input .flag-dropdown.open .selected-flag{
    border: 0px !important;
    background-color: transparent !important;
  }

  .react-tel-input .form-control{
    width: 100%;
    background: transparent;
    color: #FFF !important;
  }

  label{
    display: none;
  }

  input{
    background: transparent;
    color: #FFF;

    &::placeholder{
      color: #FFF !important;
      opacity: 0.6 !important;
    }
  }
}


.paymentMethodForm{
  max-width: 360px;
}
