@import "../../styles/helpers";

.loader,
.loader:after {
  border-radius: 50%; }

.loader {
  font-size: 0.625rem;
  position: relative;
  text-indent: -9999em;
  border-top: 0.313rem solid $neutrals5;
  border-right: 0.313rem solid $neutrals5;
  border-bottom: 0.313rem solid $neutrals5;
  border-left: 0.313rem solid #3772ff;
  transform: translateZ(0);
  animation: loader 1.1s infinite linear;
  @include dark {
    border-top: 0.313rem solid $neutrals3;
    border-right: 0.313rem solid $neutrals3;
    border-bottom: 0.313rem solid $neutrals3; } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
