@import "../../styles/helpers";


.t3container {
    margin: "auto";
    height: 31.25rem;
    width: 31.25rem;
    @include m {
        height: 330px;
        width: 350px; } }
