@import "../../styles/helpers";

.container {
    max-width: 896px; }

.top {
    margin-bottom: 4rem;
    @include m {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-bottom: 0.063rem solid $neutrals6;
        @include dark {
            border-color: $neutrals3; } } }

.title {
    margin-bottom: 1rem; }

.info {
    color: $neutrals4;
    strong {
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; } } }

.row {
    display: flex;
    margin: 0 -1rem;
    @include d {
        display: block;
        margin: 0; } }

.col {
    flex: 0 0 calc(50% - 2rem);
    width: calc(50% - 2rem);
    margin: 0 1rem;
    @include d {
        width: 100%;
        margin: 0; }
    &:not(:last-child) {
        @include d {
            margin-bottom: 4rem; }
        @include m {
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            border-bottom: 0.063rem solid $neutrals6;
            @include dark {
                border-color: $neutrals3; } } } }

.user {
    display: flex;
    @include d {
        max-width: 416px; } }

.avatar {
    flex-shrink: 0;
    width: 128px;
    height: 128px;
    margin-right: 2rem;
    @include m {
        width: 4rem;
        height: 4rem;
        margin-right: 1rem; }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.details {
    flex-grow: 1; }

.stage {
    margin-bottom: 0.5rem;
    @include body-bold-2; }

.text {
    margin-bottom: 1rem;
    @include caption-2;
    color: $neutrals4; }

.file {
    display: inline-block;
    position: relative;
    overflow: hidden; }

.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 140px;
    opacity: 0; }

.list {
    margin-bottom: 2.5rem;
    @include d {
        display: flex;
        margin: 0 -1.25rem 4rem; }
    @include m {
        display: block;
        margin: 0 0 2rem; } }

.item {
    @include d {
        flex: 0 0 calc(50% - 2.5rem);
        width: calc(50% - 2.5rem);
        margin: 0 1.25rem; }
    @include m {
        width: 100%;
        margin: 0; }
    & > .button {
        margin-top: 2rem; }
    &:not(:last-child) {
        margin-bottom: 2.5rem;
        @include d {
            margin-bottom: 0; }
        @include m {
            margin-bottom: 2rem; } } }

.category {
    margin-bottom: 2rem;
    @include body-bold-2; }

.fieldset {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 2rem; } } }

.box {
    position: relative;
    .button {
        position: absolute;
        right: 0.75rem;
        bottom: 0.5rem;
        height: 2rem;
        padding: 0 0.625rem; } }

.note {
    color: $neutrals4; }

.btns {
    display: flex;
    margin-top: 2.5rem;
    padding-top: 2.5rem;
    border-top: 0.063rem solid $neutrals6;
    @include m {
        display: block;
        text-align: center; }
    @include dark {
        border-color: $neutrals3; }
    .button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 2rem;
            @include m {
                margin: 0 0 2rem; } } } }

.clear {
    display: inline-flex;
    align-items: center;
    @include button-1;
    color: $neutrals4;
    transition: color .2s;
    svg {
        margin-right: 0.5rem;
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        color: $blue;
        svg {
            fill: $blue; } } }
