@import "../../../styles/helpers";

.group {
    flex: 0 0 50%;
    &:first-child {
        padding-right: 1rem;
        @include m {
            padding-right: 0; } }
    &:nth-child(2) {
        padding-left: 1rem;
        @include m {
            padding-left: 0; } }
    &:not(:last-child) {
        @include m {
            border-bottom: 0.063rem solid $neutrals6;
            @include dark {
                border-color: $neutrals3; } } }
    &.active {
        @include m {
            .head {
                svg {
                    transform: translateY(-50%) rotate(180deg); } }
            .menu {
                display: flex; } } } }

.head {
    margin-bottom: 0.125rem;
    @include body-bold-2;
    @include t {
        margin-bottom: 2rem; }
    @include m {
        position: relative;
        margin-bottom: 0;
        padding: 2rem 2rem 2rem 0;
        @include hairline-2; }
    svg {
        display: none;
        @include m {
            display: block;
            position: absolute;
            top: 50%;
            right: 0.625rem;
            transform: translateY(-50%);
            fill: $neutrals4;
            transition: transform .2s; } } }

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include m {
        display: none;
        padding-bottom: 2rem; } }

.link {
    @include button-2;
    color: $neutrals4;
    transition: color .2s;
    &:hover {
        color: $neutrals1;
        @include dark {
            color: $white; } }
    &:not(:last-child) {
        margin-bottom: 1.5rem; } }

