// common styles
@import "./reset";
@import "./helpers";
@import "./common";
@import "./typography";
// global classes
@import "./blocks/section";
@import "./blocks/container";
@import "./blocks/button";
@import "./blocks/title";
@import "./blocks/slick";
@import "./blocks/status";
@import "./blocks/web3modal.sass";
@import "./blocks/table.sass";

.swal2-styled.swal2-default-outline {
    &:focus {
        box-shadow: none !important; } }

.sketchfab-embed-wrapper {
    width: 100%;
    height: 98%;
    margin: auto;
    // padding-top: 5vh
    iframe {
        width: 100%;
        height: 100%; } }
#dropArrowHeaderUp,#dropArrowHeaderDown {
    margin-left: 0!important; }

.swal2-close:hover {
    color: black !important; }

.name-menu {
    background-color: $black;
    color: $white;
    padding: 0.313rem; }

.drop-down-menu:hover div {
    transform: scale(1);
    color: $white !important;
    opacity: 0.8; }

.tabWithdraw .MuiBox-root {
    padding: 1.5rem 0!important; }

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #04DCFD!important; }
.MuiCheckbox-colorSecondary {
    padding: 0!important;
    color: #04DCFD !important; }
.drop-down-menu+div {
    flex-basis: 2.5rem;
 }    // margin-left: 0.625rem

.drop-down-menu div:last-child {
    align-self: flex-start; }

.red-star {
    color: red; }

.blackBtn {
    background-color: black;
    color: white;
    border-radius: 0.313rem;
    width: 7.5rem;
    padding: 0.625rem 0.313rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 0.75rem;
    border: 0.063rem solid black; }

.blackBtn:hover {
    background-color: white;
    color: black;
    border: 0.063rem solid black; }

.backdrop {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1; }
