[class^="button"] {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding: 0 1.5rem;
    background: $secondary;
    border-radius: 1.875rem;
    @include poppins;
    text-align: center;
    color: $neutrals8;
    transition: all .2s;
    &:hover {
        background: darken($secondary, 10); }
    &.disabled {
        opacity: .5;
        pointer-events: none; }
    &.done {
        background: $neutrals5; }
    &.loading {
        background: darken($blue, 15);
        font-size: 0; }
    &.error {
        box-shadow: 0 0 0 0.125rem $neutrals6 inset;
        background: none;
        color: $pink;
        @include dark-common {
            box-shadow: 0 0 0 0.125rem $neutrals4 inset; } }
    svg {
        fill: $neutrals8; }
    &:not([class^="button-circle"]) {
        svg {
            &:first-child {
                margin-right: 0.938rem; }
            &:last-child {
                margin-left: 0.938rem; } } } }

.button-stroke {
    background: none;
    box-shadow: 0 0 0 0.125rem $secondary inset;
    color: $neutrals2;
    svg {
        fill: $neutrals2;
        transition: fill .2s; }
    &:hover {
        background: $secondary;
        box-shadow: 0 0 0 0.125rem $secondary inset;
        color: $neutrals8;
        svg {
            fill: $neutrals8; } }
    @include dark-common {
        box-shadow: 0 0 0 0.125rem $secondary inset;
        color: $neutrals8;
        svg {
            fill: $neutrals8; }
        &:hover {
            box-shadow: 0 0 0 0.125rem $secondary inset; } } }
.button-black {
    width: 100% !important;
    background: $black;
    border: 0.063rem solid $black;
    border-radius: 0.375rem;
    height: 2.688rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5rem;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    &:hover {
        background: $black; } }

.lii-buy-button {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--Gradient_Blue, linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%));
    cursor: pointer;
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }

.lii-he-button {
    border-radius: 8px;
    border: 2px solid var(--Gradient_Blue, #04DCFD);
    padding: 14px 24px !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @include m {
        padding: 5px 10px !important;
        font-size: 14px; } }

.button-pink {
    background: $pink;
    &:hover {
        background: darken($pink, 12); } }

.button-small {
    height: 2.5rem;
    border-radius: 1.875rem;
    padding: 0.625rem 1rem;
    font-size: 0.875rem; }

.button-circle-stroke {
    flex: 0 0 3rem;
    width: 3rem;
    height: 3rem;
    padding: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 0.125rem $neutrals6 inset;
    background: transparent;
    transition: all .2s;
    svg {
            fill: $neutrals4;
            transition: fill .2s; }
    &:hover {
            background: $blue;
            box-shadow: 0 0 0 0.125rem $blue inset;
            svg {
                    fill: $neutrals8; } }
    @include dark-common {
        box-shadow: 0 0 0 0.125rem $neutrals3 inset;
        &:hover {
            background: $neutrals3; } } }

.button-circle-stroke.button-small {
    flex: 0 0 2.5rem;
    width: 2.5rem;
    height: 2.5rem; }



