@import "../../styles/helpers";

.head {
    margin-bottom: 5rem;
    padding-bottom: 2.5rem;
    border-bottom: 0.125rem solid $neutrals6;
    @include m {
        margin-bottom: 2.5rem;
        padding-bottom: 1.5rem; }
    @include dark {
        border-color: $neutrals3; } }

.back {
    display: inline-flex;
    align-items: center;
    color: $neutrals2;
    svg {
        margin-right: 1.5rem;
        transition: transform .2s;
        @include m {
            flex-shrink: 0;
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 1rem; } }
    @include dark {
        color: $neutrals8;
        svg {
            fill: $neutrals8; } }
    &:hover {
        svg {
            transform: translateX(-0.125rem); } } }

.stage {
    @include m {
        font-size: 1.5rem; } }

.body {
    display: flex;
    @include m {
        display: block; } }

.menu {
    flex-shrink: 0;
    width: 544px;
    margin-right: auto;
    @include d {
        width: 384px; }
    @include t {
        width: 18.75rem; }
    @include m {
        width: 100%;
        margin-bottom: 2.5rem; } }

.wrapper {
    flex-grow: 1;
    padding-left: 128px;
    @include x {
        padding-left: 4rem; }
    @include t {
        padding-left: 2rem; }
    @include m {
        padding-left: 0; }
    & > div {
        &:not(:last-child) {
            margin-bottom: 3rem; } } }

.link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 2rem 4rem 2rem 2rem;
    border-radius: 0.75rem;
    @include body-bold-1;
    cursor: pointer;
    @include t {
        padding: 0 1.875rem 0 0;
        font-size: 1.25rem; }
    &:hover {
        .arrow {
            opacity: 1; } }
    &.active {
        box-shadow: inset 0 0 0 0.125rem $neutrals6;
        @include t {
            box-shadow: none; }
        @include dark {
            box-shadow: inset 0 0 0 0.125rem $neutrals3;
            @include t {
                box-shadow: none; } }
        .icon {
            &:before {
                opacity: 1; }
            svg {
                &:first-child {
                    opacity: 0; }
                &:nth-child(2) {
                    opacity: 1; } } }
        .arrow {
            opacity: 1; } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 1.5rem; } } }

.icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    border-radius: 50%;
    flex-shrink: 0;
    @include d {
        margin-right: 1.5rem; }
    @include t {
        margin-right: 1rem; }
    &:before {
        content: "";
        position: absolute;
        top: 0.188rem;
        left: 0.188rem;
        right: 0.188rem;
        bottom: 0.188rem;
        border-radius: 50%;
        background: $neutrals8;
        opacity: 0;
        transition: opacity .2s;
        @include dark {
            background: $neutrals1; } }
    svg {
        transition: opacity .2s;
        &:first-child {
            fill: $neutrals8; }
        &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            opacity: 0; } } }

.arrow {
    position: absolute;
    top: 50%;
    right: 2.313rem;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity .2s;
    @include d {
        right: 1.5rem; }
    @include t {
        right: 0; }
    svg {
        @include dark {
            fill: $neutrals8; } } }

.bg {
    img {
        width: 100%;
        border-radius: 1.5rem; } }

.title {
    margin-bottom: 0.5rem;
    @include m {
        text-align: center;
        font-size: 2rem; } }

.text {
    margin-bottom: 1.5rem;
    @include caption-2;
    color: $neutrals4;
    @include m {
        text-align: center; }
    span {
        font-weight: 600;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; } } }

.box {
    margin-bottom: 1.5rem;
    padding: 18%;
    background: $neutrals7;
    border-radius: 1rem;
    @include m {
        padding: 10%; }
    @include dark {
        background: $neutrals2; } }

.code {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 29%;
    border-radius: 0.75rem;
    border: 0.063rem solid $neutrals6;
    background: $neutrals8;
    box-shadow: 0px 4rem 4rem -3rem rgba(31, 47, 70, 0.12);
    @include m {
        padding: 20%; }
    @include dark {
        background: $neutrals5;
        border-color: $neutrals3; }
    img {
        width: 100%; } }

.preview {
    margin: 2.5rem 0;
    img {
        width: 100%;
        border-radius: 0.75rem; } }

.variants {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2.5rem; }

.checkbox {
    &:not(:last-child) {
        margin-bottom: 0.75rem; } }

.button {
    @include m {
        width: 100%; } }

.btns {
    display: flex;
    @include m {
        display: block; }
    .button {
        &:not(:last-child) {
            margin-right: 0.5rem;
            @include m {
                margin: 0 0 0.5rem; } } } }
