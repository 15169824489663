.analyticsWrapper {
    display: flex;
    flex-direction: column;
    padding: 1.875rem 0.938rem;
    background-color: #3E2F5F;
    border-radius: 0.625rem;
    gap: 0.4rem;
    height: 345px;
    margin-bottom: 2rem;
    width: 100%;

    @media screen and (max-width: 767px) {
        height: auto; } }

.section1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 30px;

    @media(max-width: 1024px) {
        grid-template-columns: 1fr 1fr !important; }

    @media(max-width: 768px) {
        grid-template-columns: 1fr !important; } }

.section2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1 1 50%; }

.section3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1 1 50%; }

.boxInfo {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    padding: 0;
    color: #FFF;
    align-items: flex-start;
    .label {
        font-size: 0.875rem;
        color: #FFF;
        font-weight: bold; }
    .count {
        font-size: 1.875rem;
        margin: 0; } }

.iconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 4.063rem;
    height: 4.063rem;
    border-radius: 50%;
    background: white; }

.boxContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1 1 50%;
    gap: 1.2rem; }

.dateFilterContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 15px; }

#demo-simple-select-helper {
    color: #FFF !important; }
