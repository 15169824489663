#aboutHeader{
  text-align: left;
  @media(max-width: 768px) {
    margin: 0 auto;
  }
  h2{
    font-family: 'Poppins';
    font-size: 65px;
    font-weight: 500;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFF;

    @media(max-width: 768px){
      font-size: 30px;
      line-height: 40px;
      text-align: center;
    }

    p{
      color:#04DCFD;
      display: inline-block;
      @media(max-width: 768px){
        display: inline-block;
      }
    }
  }
}
.aboutFeature {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 2rem auto;
  h1 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 500;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFF;

    @media (max-width: 768px){
      font-size: 40px;
      line-height: 45px;
    }
  }

  p {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #8B9CA9;

    @media (max-width: 768px){
      font-size: 18px;
      line-height: 26px;
    }

    li{
      font-size: 14px;
      line-height: 20px;
      margin: .2rem 0;

      &::marker{
        padding-top: 5px
      }
    }
  }
}
.aboutFeatures{
  .gridSection{
    text-align: center;
    h2{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
      color: #FFFFFF

    }
    p{
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #8B9CA9;

    }
  }
}
