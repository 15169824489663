@import "../../styles/helpers";

.loaderContent {
  // position: absolute
  // top: 0
  // left: 0
  // bottom: 0
  // right: 0
  // background: #e6e8ec52
  margin: auto;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center; }


.filterContainer {
  margin-top: 1.25rem; }

.viewContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include m {
    justify-content: center; } }
.card {
  width: 100%;
  margin: 2rem .5rem 0;
  border-radius: 0.625rem;
  min-width: 260px;
  @media (max-width: 768px) {
    min-width: 100%; }
  @include x {
    margin: 2rem 0.625rem 0;
    @include d {
      margin: 2rem 1rem 0; }
    @include m {
      width: 100%;
      margin: 0.625rem;
      &:not(:last-child) {
        margin-bottom: 2rem; } } } }

.noRecordsFound {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.pagination {
  display: flex;
  justify-content: center;
  column-gap: 0.313rem;
  list-style: none;
  margin-top: 2rem;
  position: relative;
  overflow: auto;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0 {
 }    //   background: #f6fdfd
  padding: 0.938rem 0px;
  border-radius: 0.625rem;
  a {

    border-radius: 0.625rem;
    padding: 0.938rem;
    color: #FFF;
    background: #3e2f5f;
    border-radius: 0.625rem;
    border-color: #3e2f60; } }

.pagination__link {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid #3e2f5f; }

.pagination__button {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }

.pagination__link__active a {
  color: #FFF !important;
  background: #1f8ff9;
  border-radius: 0.625rem; }

.pagination__link__disabled a {
  color: #FFF;
  border: 0.125rem solid #3e2f5f {
    border-radius: 0.625rem; } }


