@import "../../styles/helpers";

.loaderContent {
  // position: absolute
  // top: 0
  // left: 0
  // bottom: 0
  // right: 0
  // background: #e6e8ec52
  margin: auto;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center; }

.table {
  // border: 0.125rem solid black
  width: 100%; }

.section {
  width: 100%;
  padding: 0;
  @include x {
    padding: 1.25rem 0; }
  @include m {
    padding: 1.5rem 0; } }

.orderitem_main_container {
  border-radius: 0.625rem;
  width: 100%;
  background-color: #3E2F5F;
  padding: 0.625rem .25rem !important;
  margin: 1.125rem 0px;
  color: #FFF;
  cursor: pointer;
  @include s {
    padding: 0.625rem !important; } }

.orderitem {
  margin-left: .25rem !important;
  display: flex;
  flex-wrap: wrap;
  @include s {
    margin-left: 0px !important; } }

.itemdetails {
  text-align: center;
  padding-left: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: "1060px") {
    font-size: 0.625rem;
    padding-left: 0.063rem;
    button, a {
      height: 1.875rem !important;
      width: max-content !important;
      padding: 0px 0.938rem !important;
      font-size: 0.625rem;

      h4:first-of-type {
        width: 6.25rem;
        @include s {
          width: fit-content;
          font-size: 0.75rem; } } } }

  .itemdetailsrow {
    display: flex;
    justify-content: flex-start;
    padding: 0.063rem 0.313rem;
    flex-wrap: nowrap;
    @include s {
      display: block;
      div {
        text-align: left;
        @include m {
          display: flex;
          align-items: center;
          @include s {
            flex-basis: 50%;
            margin-bottom: 0.938rem;

            div:nth-child(1),div:nth-child(2) {
              padding-bottom: 0.625rem; } } }

        .itemdetailsrow:first-of-type {} } } } }

.noRecordsFound {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.pagination {
  display: flex;
  justify-content: center;
  column-gap: 0.313rem;
  list-style: none;
  margin-top: 2rem;
  position: relative;
  overflow: auto;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0 {
 }    //   background: #f6fdfd
  padding: 0.938rem 0px;
  border-radius: 0.625rem;
  a {

    border-radius: 0.625rem;
    padding: 0.938rem;
    color: #FFF;
    background: #3e2f5f;
    border-radius: 0.625rem;
    border-color: #3e2f60; } }
.pagination__link {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }

.pagination__button {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }

.pagination__link__active a {
  color: #FFF !important;
  background: #1f8ff9;
  border-radius: 0.625rem; }

.pagination__link__disabled a {
  color: #FFF;
  border: 0.125rem solid #3e2f5f {
    border-radius: 0.625rem; } }

.displaycolumn {
  display: flex;
  flex-direction: column;
  font-size: 24px; }

