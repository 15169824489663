@import "../../styles/helpers";

.footer {
    background: #1F152E;
    @include m {
        max-width: 100vw; }
    .container {
        @include m {
            padding: 0px 0.313rem;
            max-width: 99%;
            margin-top: 2.438em; } }
    @include dark {
        background: #000000; } }

.row {
    display: flex;
    padding: 5rem 1.25rem 3rem 1.25rem;
    border-bottom: 0.063rem solid $neutrals6;
    @include t {
        display: block; }
    @include m {
        padding: 4rem 0 3rem; }
    @include s {
        padding: 1.25rem 0; }
    @include dark {
        border-color: $neutrals3; } }
.newsLetterWrap {
    width: 27.313rem;
    @include s {
        max-width: 20.313rem;
        margin: 0 auto; } }
.col {
    p {
        @include s {
            font-size: 1.563rem;
            font-weight: 500; } }

    &:first-child {
        flex-grow: 2;
        padding-right: 2rem;
        // border: 0.125rem solid
        font-weight: 400;
        font-size: 2.813rem;
        line-height: 1.2;
        @include t {
            position: relative;
            margin-bottom: 2rem;
            padding: 0;
            text-align: center; } }
    // &:nth-child(2)
    //     display: flex
    //     flex-shrink: 0
    //     width: 352px
    //     +d
    //         width: 276px
    //     +t
    //         width: auto
    //         margin-bottom: 2rem
    //     +m
    //         display: block
    //         border-width: 0.063rem 0
    //         border-style: solid
    //         border-color: $neutrals6
    //         +dark
    //             border-color: $neutrals3
    &:nth-child(2) {
        flex-shrink: 0;
        width: 27.313rem;
        padding-left: 2rem;
        font-weight: 300;
        padding-top: 1.4em;
        @include d {
            width: 20.938rem; }
        @include t {
            width: 100%;
            padding: 0;
            text-align: center; } } }

.logo {
    display: inline-block;
    margin-bottom: 2rem;
    img {
        width: 8rem;
        @include m {
            width: 13.438rem !important; } } }

.info {
    max-width: 16rem;
    @include body-1;
    @include d {
        font-size: 1rem; } }

.version {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    @include t {
        margin: 0; } }

.details {
    margin-right: 1rem;
    @include caption-2;
    font-size: 1rem;
    @include m {
        margin-right: 0.625rem; } }

.category {
    margin-bottom: 2.5rem;
    @include body-bold-2;
    @include t {
        margin-block-end: 1.5rem; } }

.text {

    margin-bottom: 1.5rem; }

.foot {
    display: flex;
    justify-content: space-around;
    padding: 2rem 1.25rem 2rem 1.25rem;
    @include caption-2;
    @include m {
        display: block;
        padding: 1.5rem 1.25rem 2rem 1.25rem; } }

.copyright {
    color: $secondary;
    font-size: 0.813rem;
    font-weight: 500;
    @include m {
        text-align: center; }
    @include s {
        text-align: center; } }

.note {
    @include m {
        display: none; }
    a {
        margin-left: 1rem;
        font-weight: 600;
        color: $blue;
        transition: color .2s;
        &:hover {
            color: darken($white, 10); } } }

.input {
    width: 70%;
    min-width: 12.5rem;
    height: 2.625rem;
    padding: 0 3rem 0 0.875rem;
    border-radius: 0.625rem;
    background: transparent;
    border:transparent {}
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        //border-bottom: 0.125rem solid $neutrals4
        color: $white; }
    &:focus {
        border-color: $neutrals3; }
    @include placeholder {
        color: $neutrals4; }

    @include s {
        width: 100%; } }

.subscribeBtn {
    border: 0.125rem solid $black;
    background: $black;
    color: $white;
    width: 7.5rem;
    border-radius: 0.375rem;
    padding: 0.625rem 0px;
    margin-left: 15;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold;
    @include s {
        width: 85%;
        margin: 0.625rem 0 0 0; } }


.footerLinks {
    a {
        font-weight: 900;
        color: $white !important;
        font-size: 1rem; }
    a:not(:last-child) {
        margin-bottom: 0.938rem; } }

.hightLightColorNone {
    -webkit-tap-highlight-color: $white !important; }

.terms {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle; }

.termsBox {
    cursor: pointer;
    width: 1.25rem;
    height: 1.125rem;
    margin-right: 0.625rem;
    &:checked {
        background-color: $secondary; } }

.social_media_icons {
    fill: $black;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    opacity: .85;
    &:hover {
        fill: $secondary;
        opacity: 1; } }
.santa_casa_logo {
    width: 6.25rem;
    height: auto;
    cursor: pointer;
    @include m {
        margin-bottom: 1rem; }
    @include s {
        margin-bottom: 0.5rem; } }

.social_media_icon_wrap {
    button {
        margin-right: 1.25rem;
        -webkit-tap-highlight-color: transparent; }
    img {
        height: 1.8em;
        width: 1.8em;
        cursor: pointer; }
    &:last-child {
        margin-right: 0; }
    @include s {
        text-align: center; } }

.email123 {
    padding: 0.625rem 0.313rem; }

.sub123 {
    padding: 0.563rem 0.313rem;
    color: $neutrals8;
    margin-left: 0.625rem;
    border: 0.063rem solid $secondary;
    &:hover {
        background-color: $secondary !important; } }
.aboutpanel {
    display: flex;
    column-gap: 2.5rem;
    justify-content: space-evenly;
    .footerLink {
        -webkit-tap-highlight-color: transparent !important;
        color: $white !important; }
    .communityTab {
        font-weight: 700;
        @include s {
            margin: 1.25rem 0px 0.625rem 0px; } }
    @include s {
        flex-direction: column;
        align-items: start;
        padding-top: 1rem; }

    ul {
        font-weight: 600;
        line-height: 1.7rem;
        @include s {
            line-height: 0.8rem; }
        a:link {
            color: $white !important; }
        a:vistited {
            color: $white !important; } }

    .socialMediaHandles {
        margin-top: 0.625rem; } }

.about_ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        //margin: 1.25rem 0px 0.625rem 0px
        ul {
 }            //margin-right: 1.25rem

        a {
            padding-right: 0.938rem; } }

.pseudoText {
    position: relative;

    width: 60%;
    min-width: 6.25rem;
    height: 2.625rem;
    padding: 0 3rem 0 1.125rem;
    border-radius: 0.625rem;
    background: transparent;
    border: 0.063rem solid #E8E8E8;
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include s {
        width: 85%; }
    img {
        margin-left: 0.313rem;
        position: absolute;
        top: 0.75rem;
        left: 0; } }

.form {
    display: flex;
    justify-content: flex-start;
    column-gap: 0.313rem;
    @include s {
        flex-direction: column;
        align-items: center; } }
