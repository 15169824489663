.infoTab{
  color: #FFF;

  h4{
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  p, a{
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    a{
      color: #F2F6F9 !important;
    }
  }
}

.react-tel-input .flag-dropdown{
  background-color: #3e2f5f !important;
  border: 1px solid #3e2f5f !important;
}

