@import "../../styles/helpers";

.player {
    position: relative;
    img {
        width: 100%;
        border-radius: 1rem; }
    @include m {
        img, video {
            height: 350px !important; } }
    video {
        width: 100% !important; } }

.preview {
    height: 31.25rem;
    // background-color: #000000
    @include m {
        height: 350px; }
    * {
        height: 100%; } }

.control {
    position: absolute;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    z-index: 3;
    display: flex;
    align-items: center;
    height: 2.5rem;
    padding: 0 1.25rem;
    border-radius: 1.25rem;
    background: $neutrals8;
    @include dark {
        background: $neutrals1; } }

.button {
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $neutrals2;
            @include dark {
                fill: $neutrals8; } } }
    &:not(:last-child) {
        margin-right: 1.5rem;
        @include m {
            margin-right: 1rem; } } }

.line {
    position: relative;
    flex-grow: 1;
    height: 0.5rem;
    margin-right: 1.5rem;
    background: $neutrals6;
    border-radius: 0.25rem;
    @include m {
        margin-right: 1rem; }
    @include dark {
        background: $neutrals3; } }

.progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 0.25rem;
    background: $blue; }

.time {
    margin-right: 1.5rem;
    @include body-bold-2;
    @include m {
        margin-right: 1rem; } }
