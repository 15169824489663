.lii-section{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.highlightLeft{

  h3 {
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 1280px){
      font-size: 33px;
    }
    @media (max-width: 768px) {
      font-size: 25px;
    }
  }

  p{
    color: #8B9CA9;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 1280px){
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 15px;
    }
  }
}
.highlightRight{
  .number{
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #04DCFD;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 25rem;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;

    @media (max-width: 1280px){
      font-size: 15rem;
    }

    @media (max-width: 768px){
      font-size: 7rem;
    }
  }
  .title{
    color: #F8FCFF;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 1280px){
      font-size: 15px;
    }
    @media (max-width: 768px){
      font-size: 15px;
    }
  }
  .desc{
    color: #8B9CA9;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1rem;
    @media (max-width: 1280px){
      font-size: 12px;
    }
    @media (max-width: 768px){
      font-size: 10px;
    }
  }
}

.lii-view-more{
  border-radius: 8px;
  border: 2px solid #04DCFD;
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 14px;
  color: #F2F6F9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
