@import "../../styles/helpers";

.loaderContent {
    // position: absolute
    // top: 0
    // left: 0
    // bottom: 0
    // right: 0
    // background: #e6e8ec52
    margin: auto;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center; }

.profileContainer {
    display: flex;
    column-gap: 1.25rem;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column; } }

.wrapper {
    display: flex;
    padding: 1.875rem 0.938rem;
    border-radius: 0.625rem;

    .profilePic {
        margin-right: 1.25rem;
        min-width: 5rem;
        img {
            height: 7rem;
            width: 7rem;
            max-width: 7rem;
            border-radius: 50%;
            border: solid 5px #04DCFD; } }
    // object-fit: cover;
    @include m {
        padding: 1rem .5rem; } }


.dashButton {
    padding: 8px 16px;
    gap: 14px;
    background: linear-gradient(143.38deg, #04DCFD 11.56%, #1F90FA 94.12%);
    border-radius: 8px;
    color: #F2F6F9 !important;
    text-decoration: none !important;
    margin-top: 1rem;

    @include s {
        padding: 4px 8px; } }


.btn_black {
    @media only screen and (max-width: "1630px") {
        background-color: #000;
        color: white;
        font-size: 12px; } }

.userDetails {

    @media only screen and (max-width: "1630px") {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between; }
    @include s {
        flex: 1 1 0px; }
    h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #F2F6F9;
        margin-bottom: 0;

        @include s {
            font-size: 1.25rem; } }
    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #8B9CA9;
        margin-bottom: 0; } }



.userDetailsRight {

    @include s {
        flex: 1 1 0px; }
    h3 {
        font-size: 1.875rem;
        @include s {
            font-size: 1.25rem; } }
    p {
        font-size: 0.875rem;
        color: rgba(0, 0, 0, 0.4); } }

.dashtab {
    display: flex;
    margin-top: 2.5rem;
    flex-direction: column;
    align-items: start;
    @include s {
        margin-top: 0; } }

.menuWrapper {
    display: flex;
    background: #1F152E;
    border-radius: 5px;
    margin-bottom: 1em;
    column-gap: 0.625rem;
    padding: 0.625rem;
    font-size: 0.75rem;
    color: #7B7B7B;
    word-break: break-word;
    @include m {
        width: 100%;
        display: inline-block; }

    .selected {
        cursor: pointer;
        background: #3E2F5F;
        border-radius: 5px;
        padding: 0.625rem 1.25rem;
        color: white;
        @include s {
            padding: 0.625rem;
            position: relative;
            display: inline-block; } }

    .nonSelected {
        cursor: pointer;
        padding: 0.625rem .625rem;
        @include s {
            padding: 0.625rem;
            position: relative;
            display: inline-block; } } }

.subMenuWrapper {
    display: flex;
    border-radius: 0.625rem;
    padding: 0.125rem;
    font-size: 0.75rem;
    color: #7B7B7B;

    .subSelected {
        cursor: pointer;
        padding: 0.625rem .625rem;
        color: #FFFFFF;
        border-bottom: 0.125rem solid #FFFFFF; }

    .subNonSelected {
        cursor: pointer;
        padding: 0.625rem .625rem;
        color: #F2F6F9;
        opacity: 0.5;
        border-bottom: 0.125rem solid #F2F6F9; } }

.walletDivider {
    display: flex;
    flex-direction: column;
    gap : 10px;
    flex-wrap: nowrap;
    flex: 0 0 86%;
    justify-content: flex-start;
    .divider {
        margin: 0em 2.2em;
        @include m {
            margin: 0em 0.5em; } } }


.rightWallet {
    display: flex;
    background: #1F152E;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1rem;
    border-radius: 5px;
    min-width: 250px;

    @include s {
        flex: 1 1 0px;
        display: none; }
    @media screen and (max-width: '720px') {
        flex-wrap: wrap; }
    @media screen and (max-width: '610px') {
        justify-content: center;
        width: 100%; } }


.maticSymbol {
    width: 1.75rem;
    padding-left: 0.25rem;
    margin-right: .25rem;
    @include s {
        width: 1.438rem; } }

.flexCenter {
    display: flex;
    flex-direction: row;
    align-items: center; }


.flexStraightAlign {
    // display: flex
    // // justify-content: space-between
 }    // align-items: center

.cryptoRow {
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;

    @media screen and (max-width : 610px) {
      flex-direction: column;
      align-items: center; }

    h3 {
        font-size: 1.875rem;
        @include s {
            font-size: 1.5rem; } }
    p {
        font-size: 0.875rem;
        color: rgba(0, 0, 0, 0.4); } }


.copyWalletDiv {
    color: #FFF;
    font-size: 0.7rem;
    cursor: pointer;
    text-align: center;
    font-family: 'Poppins';
    margin-top: .5rem;
    border-radius: 10px;
    FONT-WEIGHT: 700;
    width: 100%;

    span {
        display: block;
        font-weight: normal;
        font-size: 0.5rem; } }

.cryptoRowTwo {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;


    h3 {
        font-size: 24px;
        font-family: Poppins;
        color: #F2F6F9;
        margin-bottom: 0px;
        @include s {
            font-size: 1.5rem; } }
    p {
        font-size: 0.875rem;
        color: rgba(0, 0, 0, 0.4); } }

.wrap {
    @media screen and (max-width : 610px) {
        width: 100%; } }

.usdcLogo {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 4px;
    margin-right: 0.25rem;
    @include s {
        margin-top: 0.5rem; } }

.mailLoginWrapper {
    display: flex;
    margin-left: 2rem;
    @include s {
        flex-direction: column;
        margin-left: 0;
        align-items: center; } }

.optionalButtonsDiv {
    display: flex;
    gap: 2rem;
    @media screen and (max-width : 590px) {
        flex-direction: column; } }


