.container321 {
    font-size: 1.125rem;
    background-color: #000;
    padding: 0;
    margin: 0;
    color: #fff;
}
.logo img {
    width: 560px;
    margin-top: 4%;
}

.content {
    font-size: 1.313rem;
    color: #fff;
    text-align: center;
    margin-top: 4%;
}

.content-top {
    margin: 2% 0 0 0;
}

.first-drop {
    height: 2.787rem;
    width: 18.873rem;
    color: #FF76DC;
    font-size: 37.3rem;
    letter-spacing: 0;
    line-height: 2.787rem;
    margin-left: 48%;
    margin-top: -11%;
}

.dec-1 {
    height: 68.1.938rem;
    width: 199.0.813rem;
    color: #FFFFFF;
    font-size: 57.3.25rem;
    letter-spacing: 0;
    line-height: 89.1.938rem;
    margin-left: 45%;
    margin-top: 6%;
}

sup {
    font-size: 1.5rem !important;
}

.content-bottom {
    width: 100%;
    margin-left: 10%;
    float: left;
    font-weight: normal;
}

.content-footer img {
    width: 137px;
}

.content-footer {
    padding-right: 10%;
    margin-top: 2%;
}

.policy {
    text-align: center;
    width: 100%;
    margin-top: 1%;
    color: #FFF;
    font-size: 16px
}

@media (max-width: 1024px){
    .policy {
        font-size: 12px
    }
}

.policyheading {
    text-align: left;
    margin-top: 2%;
    text-decoration: underline;
    font-weight: bold;
}
h3{
    margin-top: 5%;
}

.policysubheading {
    text-align: left;
    margin-top: 2%;
    font-style: italic;
}

.points {
    text-align: justify;
    margin-top: 1%;
}

td {
    padding: 0.75rem 0;
    text-align: left;
}

.policylist {
    text-align: justify;
    list-style-type: disc;
    padding-left: 40px;
    margin-top: 1%;
}

.policysublist {
    text-align: justify;
    list-style-type: square;
    padding-left: 40px;
    margin-top: 1%;
}

.policynumberedlist {
    text-align: justify;
    list-style-type: decimal;
    margin-top: 1%;
    /* padding-left: 40px; */
}

.policynlowarromanlist {
    text-align: justify;
    list-style-type: lower-roman;
    padding-left: 40px;
    margin-top: 1%;
}

.green-text {
    background-color: rgb(158, 226, 247);
}

.red-text {
    background-color: rgba(207, 207, 207, 0.312);
}

td {
    padding: 0.75rem 0;
    text-align: left;
}

/* .columns {
    display: flex;
    text-align: justify;
    justify-content: space-between;
  } */
/* .column {
    flex: 1;
    margin-right: 20px;
} */

.columns {
    display: flex;
    flex-direction: column;
}

.column {
    width: 100%;
    padding-right: 20px;
}
