@import "../../styles/helpers";

.header {
    position: relative;
    z-index: 20;
    padding: 1rem 0;
    //-webkit-backdrop-filter: blur(10px)
    //backdrop-filter: blur(10px)
    //background: #2c1f48e3
    @include mobileDrawer {
        padding: 0.625rem;
        border: none; }
    @include dark {
        box-shadow: none;
        background: #000000; } }
.logo {
    height: 3rem;
    width: 10rem; }
.logInButton {
    padding: 0.2em 1em 0.2em 0.2em;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 140px;
    @include m {
        width: auto; }
    @include mobileDrawer {
 }        // display: none
    @include a {
        padding: 0 0.813rem; } }


.container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .button {
        margin-right: 0.75rem;
        @include mobileDrawer {
            // display: none
            font-size: 0.688rem; }
        @include a {
            padding: 0 0.813rem;
            height: 2.125rem; }

        @media only screen and (max-width: 375px) {
            font-size: 0.625rem;
            padding: 0 0.438rem;
            height: 1.938rem; } }
    @include m {
        justify-content: space-between; } }

.logo {
    position: relative;
    z-index: 12;
    display: inline-block;
    flex-shrink: 0;
    width: 8rem;
    flex: 0 0 15%;
    @include d {
 }        // margin-right: auto
    @include mobileDrawer {
        width: 10em;
        height: 3em;
        -webkit-tap-highlight-color: transparent; }
    img {
        width: 100%;
        @include mobileDrawer {
            width: 5rem !important;
 } } }            // height: 3.625rem!important

.wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 70%;
    @include d {
 }        // flex-grow: 0
    @include mobileDrawer {
        position: fixed;
        top: 75px;
        left: 0;
        right: 0;
        width: 100%;
        background: #2c1f48e3;
        padding: 1rem;
        .search {
            width: 70%;
            margin: 0 auto;
            @include m {
                width: 100%; } } }
    //+mobileDrawer
    //    position: absolute
    //    top: 0
    //    left: 0
    //    right: 0
    //    flex-direction: column
    //    align-items: stretch
    //    height: 100vh
    //    margin: 0
    //    padding: 8.75rem 2rem 2.5rem
    //    box-shadow: 0px 4rem 4rem rgba(31, 47, 70, 0.2)
    //    background: $neutrals8
    //    visibility: hidden
    //    opacity: 0
    //    transition: all .2s
    //    +dark
    //        background: $neutrals1
    //    &.active
    //        visibility: visible
    //        opacity: 1
    //        color: $secondary
    //    &.activeWhite
    //        visibility: visible
    //        opacity: 1
    //        color: $white
    .button {
        display: none;
        @include mobileDrawer {
            display: inline-flex;
            margin-top: 1rem; } } }


.nav {
    display: flex;
    margin-right: auto;
    // border: 0.125rem solid $neutrals6
    width: 100%;
    justify-content: space-evenly;

    @include d {
 }        // display: none
    @include mobileDrawer {
        display: flex;
        flex-direction: column;
        margin: 0 0 2.5rem;
        border: none; }
    @include dark {
        border-color: $neutrals3; } }
.textboxwrapper {
    // flex-grow: 1
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 0.125rem solid black


    width: 70%;
    @include m {
        display: none; } }
.link {
    margin: 0.5rem;
    @include dm-sans;
    line-height: 1.875rem;
    color: $neutrals3;
    transition: color .2s;
    font-size: 1.063rem;
    @include dark {
        color: $neutrals6; }
    @include mobileDrawer {
        margin-left: 0;
        font-size: 1.063rem;
        line-height: 4rem;
        text-align: left; }

    &:hover,
    &.active {
        color: black;
        border-bottom: 0.125rem solid $neutrals3; }
    &:nth-child(n+6) {
        display: none;
        @include mobileDrawer {
            display: block; } } }
.searchImage {
    padding: 0 0.625rem; }
.search {
    position: relative;
    background: #1F152E;
    height: 56px;
    line-height: 56px;
    flex-shrink: 0;
    width: 50%;
    margin-right: 1.5rem;
    border-radius: 1em;
    border: 0px;
    input {
        padding-left: 10%;
        font-size: 1em;
 }        //box-shadow: 0 0 0rem #1F152E inset;

    @include mobileDrawer {
        display: block;
        width: 100%;
        margin: auto 0 0; } }


#mobileSearch {
    align-items: center;
    margin-right: 1rem;
    display: none;
    @include t {
        display: flex; } }

.textboxBorder {
    border: 0px;
    width: 60%;
    height: 2.188rem;
    position: relative;
    border-radius: 3.375rem;


    input {
        @include poppins;
        position: absolute;
        top: 0.125rem;
        left: 0;
        color: black;
        font-size: 0.75rem;
        width: 90%;
        padding: 0.188rem;
        border: none;
        margin-left: 0.625rem; }


    img {
        position: absolute;
        top: 0.438rem;
        right: 0.625rem; }

    @include m {
        width: 60%; } }
.input {
    width: 100%;
    height: 2.5rem;
    padding: 0 2.625rem 0 1rem;
    background: none;
    border-radius: 1rem;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $white !important; }
    &:focus {
        border-color: $white !important; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 2.625rem;
    svg {
        fill: $black;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } } }

.notification {
    margin-right: 1.5rem;
    @include mobileDrawer {
        margin-right: 1.25rem; } }

.burger123 {
    display: none;
    @include mobileDrawer {
        display: block;
        position: relative;
        margin-left: 1.25rem;
        width: 2rem;
        height: 2rem;
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        &.active {
            &:before {
                transform: translateY(0) rotate(-45deg); }
            &:after {
                transform: translateY(0) rotate(45deg); } }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 1rem;
            left: 0.375rem;
            width: 1.275rem;
            height: 0.125rem;
            background: $neutrals4;
            border-radius: 0.125rem;
            transition: transform .2s; }
        &:before {
            transform: translateY(-0.25rem); }
        &:after {
            transform: translateY(0.188rem); } }

    // @media only screen and (max-width: "375px")
    //     margin-left: 0.75rem
 }    //     margin-right: 0.5rem


.disabledLink {
  pointer-events: none; }

.metaMask {
    position: fixed;
    top: 50%;
    left: calc(50% - 150px);
    z-index: 99; }

.headerEndButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1rem; }

.headerEndProfile {
    position: relative; }

.search_box , .menu_icon ,.search_box_hover {
  display: flex;
  align-items: center;
  padding: 0.625rem;
  border-radius: 2.5rem;
  height: 2.5rem;
  background: transparent; //#FCFCFD
  border: 0.063rem solid white;
  margin-left: 0.313rem;
  @include m {
    font-size: 2rem !important;
    height: 2.813rem; }
  @include s {
    font-size: 1.2rem !important; } }

.search_box_hover .search_txt {
  width: 9.375rem;
  padding: 0  0.375rem; }

.search_box:hover .search_txt {
    width: 9.375rem;
    padding: 0 0.375rem; }

.search_txt {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  transition: 0.5s;
  font-size: 1rem;
  width: 0px; }

.list {
    span {
        font-weight: 600 !important; } }
.colorWhite {
    color: $white; }


.love {
    flex-grow: 2;
    margin-right: 0.625rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    cursor: pointer;
    margin-top: 0.4em;
    padding-top: 1em;
    flex: 0 0 20%;
    .loveIcon {
        border-radius: 50%;
        background: transparent;
        //padding: 0.625rem
 }        //box-shadow: 0px 8.741276741027832px 6.993021011352539px 0px #0000000A;
    img {
        border-radius: 50%;
        width: 3.75rem;
        height: 3.75rem; } }

.homeHover {
    margin: 0.5rem;
    @include dm-sans;
    line-height: 1.875rem;
    color: white;
    transition: color .2s;
    font-size: 1.063rem;
    @include dark {
        color: $neutrals6; }
    @include mobileDrawer {
        margin-left: 0;
        font-size: 1.063rem;
        line-height: 4rem;
        text-align: left; }

    &:hover,
    &.active {
        color: #03DDFD;
        border-bottom: 0.125rem solid $neutrals3; }
    &:nth-child(n+6) {
        display: none;
        @include mobileDrawer {
            display: block; } } }
