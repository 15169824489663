@import "../../styles/helpers";

.section {
    padding: 3.125rem 0px;
    @include x {
        padding: 1.25rem 0; }
    @include m {
        padding: 0.875rem 0; } }
.container {
    display: flex;
    column-gap: 0.625rem;
    @include t {
        padding: 0 1.25rem; }
    @include m {
        display: block;
 } }        //flex-direction: column-reverse


.row_1 {
    flex: 0 0 5%; }

.row_2 {
    flex: 0 0 40%;
    .col_2 {
        background: #F4F4F4;
        border-radius: 0.625rem;
        padding: 1.375rem;
        margin-top: 7%; }

    .col_3 {
        background: #F4F4F4;
        border-radius: 0.625rem;
        padding: 1.375rem;
        margin-top: 7%;
        display: flex;
        flex-direction: column; }

    .col_4 {
        margin-top: 8%;
        display: flex;
        flex-direction: column; } }


.row_3 {
    flex: 0 0 35%;
    background: #F4F4F4;
    border-radius: 0.625rem;
    margin-left: 3%; }
.videoWrap {
    video {
        border-top-left-radius: 0.625rem;
        border-top-right-radius: 0.625rem;
        object-fit: cover; } }
.backArrowCircle {
    // background: #FFFFFF
    border: 0.063rem solid #E8E8E8;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer; }

.placeABidHeading {
    font-size: 2.125rem;
    font-family: 'Poppins'; }

.placeABidSubHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem; }

.bidPlacedBy {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 15.7416px;
    padding: 0 5%;
    line-height: 114%;
    color: #000000;
    span {
        font-weight: 600;
        font-family: 'Poppins';
        font-style: normal;
        font-size: 14.7416px;
        line-height: 114%; } }

.bidPlacedAmount {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 5%;
    margin-top: 4%; }


.bidPlacedAmountCrypto {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.625rem;
    letter-spacing: -1.0.313rem;
    color: #000000; }

.bidPlacedAmountDollar {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.625rem;
    letter-spacing: -1.0.313rem;
    color: #666666;
    position: relative; }

.auctionEndTimer {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .days {
        padding-right: 0.625rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap; }
    .hours {
        // flex: 0 0 20%
        padding-right: 0.625rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap; }
    .minutes {
        // flex: 0 0 20%
        padding-right: 0.625rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap; }
    .seconds {
        // flex: 0 0 20%
        padding-right: 0.625rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap; } }

.auctionEndLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #666666; }

.auctionTimerContent {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 2.813rem;
    letter-spacing: -1.0.313rem;
    color: #000000; }

.auctionTimerLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #666666;
    white-space: nowrap; }

.amountLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #1A1A1A; }

.totalAmount {
    display: flex;
    flex-direction: row;
    // justify-content: space-between
    border-bottom: 1;
    // border-bottom: 0.063rem solid #E2E2E2
    padding-bottom: 1%;
    margin-top: 1%;
    flex-wrap: wrap; }

.amountNumbers {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2.375rem;
    line-height: 3.563rem;
    color: #1A1A1A; }

.amountBalance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; }

.balanceLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 3.25rem;
    color: #1A1A1A; }

.amountBalanceNumber {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 3.25rem;
    text-align: right;
    color: #1A1A1A;
    span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 3.25rem;
        text-align: right;
        color: #1A1A1A; } }

.amountUnit {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.0rem;
    line-height: 3.25rem;
    text-align: right;
    color: #1A1A1A;
    margin-top: 1px; }

.submitBtn {
    margin-top: 0.625rem;
    padding: 0px 0.313rem;
    width: 100%;
    button {
        width: 100%;
        border: 0.125rem solid $black;
        background: $black;
        font-weight: 700;
        color: white;
        height: 2.313rem;
        border-radius: 0.625rem;
        padding: 0.625rem 1rem;
        font-size: 0.75rem; } }

.rows {
    border-radius: 0.625rem;
    height: 3.25rem;
    min-width: 12em;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem;
    display: flex;
    background: $white;
    box-shadow: 0px 0.625rem 1.25rem #ccc;
    border-radius: 99999px;
    flex-wrap: nowrap;
    @include m {
        margin-right: 0.625rem; }
    margin-left: 2em;
    margin-bottom: 10px;
    cursor: pointer;
    .avatar {
        flex-grow: 0;
        margin-right: 0.313rem;
        img {
            width: 2.188rem;
            height: 2.188rem;
            border-radius: 50%; } }
    .creator {
        flex-grow: 3;
        h5 {
            line-height: 0.7em;
            font-size: 1em; }
        div {
            opacity: 0.9;
            font-size: 0.9em;
            white-space: nowrap;
            overflow: hidden;
            min-width: 8em;
            text-overflow: ellipsis; } }
    .amount {
        flex-grow: 1; } }

.additionalDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 8%;
    flex-wrap: wrap; }

.productTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 141.5%;
    letter-spacing: -0.0.313rem;
    color: #000000;
    padding: 1rem; }

.buttonFullWidth_buynow {
    margin-top: 0.625rem;
    width: 100%;
    background: $black;
    padding: 0.5rem 0.938rem;
    border: 0.188rem solid $black;
    color: $white;
    border-radius: 0.375rem;

    span {
        color: $white;

        font-weight: 900; } }

.warningMessage {
    font-family: 'Poppins';
    font-weight: 400;
    padding-top: 0.8em;
    font-size: 0.8em;
    line-height: 160%;
    color: #666666; }

.mobile_image_container {
    padding: 0.625rem 0px;
    display: flex; }
.productDetails {
    .mediaContainer {
        max-height: 38rem;
        min-height: 38rem;
        overflow: hidden; } }

.mobile_col_2 {
    background: #F4F4F4;
    border-radius: 0.625rem;
    padding: 1.375rem;
    margin-top: 7%; }

.mobile_col_3 {
    background: #F4F4F4;
    border-radius: 0.625rem;
    padding: 1.375rem;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column; }

.maticSymbol {
    width: 1.813rem;
    height: 1.813rem;
    padding-left: 0.375rem;
    position: absolute;
    top: 0.375rem; }

.maticSymbol2 {
    width: auto;
    margin-left: 0.313rem;
    height: 0.875rem; }

.flexCenter {
    display: flex;
    flex-direction: row;
    align-items: center; }

.bidPlacedAmountCrypto {
    display: flex;
    flex-direction: row;
    align-items: center; }

.flexCenter {
    display: flex;
    flex-direction: row;
    align-items: center; }
