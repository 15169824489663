@import "../../../styles/helpers";

.container {
    background: $black;
    width: 100%;
    height: 15em;
    border-radius: 0.8em;
    box-shadow: 0.563rem 2.25rem 137px -4rem rgba(0, 0, 0, .75);
    min-width: 100%; }

.latestHeading {
    color: $white; }

.timeDate {
    color: $white;
    font-size: 0.8em; }

.latestNewsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 15em;
    overflow-x: auto;
    max-width: 100%;
    width: 100%;
    overflow-y: clip;
    .latestNewsCard {
        flex: 0 0 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 2em 4em;
        height: 15em;
        min-width: 225px; } }


.divider {
    background: #b3acac;
    width: 0.1em;
    margin: 2.3em 0em; }



