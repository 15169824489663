


.fullscreenModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; }

.fullscreenModalContent {
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 0.625rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
  position: relative; }

.modalCloseButton {
  position: absolute;
  top: 1rem;
  right: 0.425rem;
  background-color: #3E2F5F;
  border: none;
  color: #FFF;
  border-radius: 50%;
  width: 30px;
  line-height: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer; }


@media (max-width: 768px) {
  .fullscreenModalContent {
    max-width: 100%;
    max-height: 100%;
    padding: 0.625rem; } }
