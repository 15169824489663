.formHeader {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 0.625rem;
    flex-direction: column;
    h3 {
        font-size: 1.375rem;
        margin: 1.563rem 0px 0px 0px; }
    .subheading {
        margin: 0px 0px 1.563rem 0px;
        font-weight: 500; }

    .button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 220px;
        padding: 0px 0.625rem;
        font-size: 0.75rem;
        border-radius: 0.375rem;
        cursor: pointer;
        border: 0.125rem solid #000;
        background: #000;
        font-weight: 700;
        color: white;
        height: 2.313rem;
        margin-top: 0.625rem {
         width: 100%; } }
    .text {}


    .image {
        position: absolute;
        top: 0.313rem;
        left: 0.938rem; } }




.buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
