@import "../../../styles/helpers";

.inner {
    padding: 3rem;
    background: $neutrals8;
    box-shadow: 0px 4rem 4rem rgba(31, 47, 70, 0.12);
    border-radius: 1rem;
    @include d {
        padding: 1.5rem; }
    @include t {
        position: relative;
        width: 352px;
        margin: auto; }
    @include dark {
        background: $neutrals1;
        box-shadow: inset 0 0 0 0.063rem $neutrals3; } }

.close {
    display: none;
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    background: $neutrals8;
    border: 0.125rem solid $neutrals6;
    border-radius: 50%;
    font-size: 0;
    @include t {
        display: inline-block; }
    svg {
        fill: $neutrals2;
        transition: transform .2s; }
    @include dark {
        border-color: $neutrals3;
        background: $neutrals1;
        svg {
            fill: $neutrals8; } } }

.info {
    margin-bottom: 1.5rem;
    @include body-bold-1; }

.preview {
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    & > img {
        width: 100%; } }

.link {
    padding: 1.25rem 0;
    color: $neutrals2;
    @include dark {
        color: $neutrals8; } }

.line {
    display: flex;
    align-items: flex-start;
    &:first-child {
        margin-bottom: 0.75rem; } }

.title {
    margin-right: auto;
    padding-top: 0.063rem;
    @include body-bold-2; }

.price {
    flex-shrink: 0;
    margin-left: 0.75rem;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 0.125rem $green;
    font-size: 0.75rem;
    line-height: 1.625rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $green; }

.counter {
    @include dark {
        color: $neutrals6; } }

.users {
    display: flex;
    margin-right: auto; }

.avatar {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 0.125rem solid $neutrals8;
    @include dark {
        border-color: $neutrals1; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; }
    &:not(:first-child) {
        margin-left: -0.5rem; } }

.foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 0.063rem solid $neutrals6;
    @include caption-2;
    color: $neutrals4;
    @include dark {
        border-color: $neutrals3; } }

.status {
    svg {
        margin: -0.125rem 0.25rem 0 0;
        fill: $neutrals4; }
    span {
        font-weight: 600;
        color: $neutrals3;
        @include dark {
            color: $neutrals8; } } }

.clear {
    display: inline-flex;
    align-items: center;
    margin-top: 1.5rem;
    @include button-1;
    color: $neutrals4;
    transition: color .2s;
    svg {
        margin-right: 0.5rem;
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        color: $blue;
        svg {
            fill: $blue; } } }
