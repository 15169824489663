.orderRow{
  background-color: #1F152E;
  border-radius: .5rem;

  .orderImg{
    max-height: 250px;
  }

  h5, h6{
    color: gray;

    .shippingDesc{
      display: block;
      font-size: smaller;
      color: #FFF;
      margin-top: 1rem;
    }

    p{
      color: #FFF;
    }
  }

}

