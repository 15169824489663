
.assetOwners{
  display: flex;
  flex-direction: row;
  @media(max-width: 1024px){
    flex-direction: column;
  }
}
.assetOwner{
  width: 250px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 1rem;

  .ownerPic{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: solid 1px #8B9CA9;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .ownerInfo{
    margin-left: 10px;
  }
  .ownerName{
    color: #8B9CA9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: inherit;
    margin-bottom: 0;
  }
  .ownerUsername{
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
