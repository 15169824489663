.signInButton{
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border-radius: 8px;
  background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
  color: #F2F6F9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.input-wrapper{
  border-bottom: solid 2px #FFF;
  width: 100% !important;
}
.signInEmail{
  border: 0px;
  background: transparent;
  text-align: left;
  width: 100%;
  color: #FFF;
}
