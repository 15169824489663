@import "../../styles/helpers";

.EarningsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  gap: 1rem; }

.menuWrapper {
  display: flex;
  align-items: start;
  box-shadow: 0px 0.563rem 0.938rem 0px #00000014;
  border-radius: 0.625rem;
  margin-bottom: 1em;
  column-gap: 0.625rem;
  font-size: 0.75rem;
  color: #7B7B7B; }

.selected {
  cursor: pointer;
  padding: 0.625rem 0.625rem;
  color: #FFFFFF;
  border-bottom: 0.125rem solid #FFFFFF; }

.nonSelected {
  cursor: pointer;
  padding: 0.625rem 0.625rem;
  color: #F2F6F9;
  opacity: 0.5;
  border-bottom: 0.125rem solid #F2F6F9; }

.table {
  // border: 0.125rem solid black
  width: 100%;
  background: transparent !important;
  boxShadow: '0px' !important;

  th {
    border: 0px;
    color: rgba(139, 156, 169, 1);
    text-align: center; }

  td {
    color: #FFF;
    border: 0px;
    padding: 1.625rem;
    font-size: 0.875rem;
    font-style: normal;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 1.688rem;
    text-transform: capitalize;
    background-color: #3E2F5F; } }


.section {
  width: 100%;
  padding: 0;
  @include x {
    padding: 1.25rem 0; }
  @include m {
    padding: 1.5rem 0; } }

.orderitem_main_container {
  border-radius: 0.625rem;
  width: 100%;
  background-color: transparent;
  padding: 0.625rem 1.25rem !important;
  margin: 1.125rem 0px;
  @include s {
    padding: 0.625rem !important; } }

.orderitem {
  display: flex;
  flex-wrap: wrap;
  @include s {
    margin-left: 0px !important; } }

.itemdetails {
  padding-left: 0.938rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: "1060px") {
    font-size: 0.625rem;
    padding-left: 0.063rem;
    button, a {
      height: 1.875rem !important;
      width: max-content !important;
      padding: 0px 0.938rem !important;
      font-size: 0.625rem;

      h4:first-of-type {
        width: 6.25rem;
        @include s {
          width: fit-content;
          font-size: 0.75rem; } } } }

  .itemdetailsrow {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    @include s {
      display: block;
      div {
        text-align: left;
        @include m {
          display: flex;
          align-items: center;
          @include s {
            flex-basis: 50%;
            margin-bottom: 0.938rem;

            div:nth-child(1),div:nth-child(2) {
              padding-bottom: 0.625rem; } } }

        .itemdetailsrow:first-of-type {} } } } }

.noRecordsFound {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.pagination {
  display: flex;
  justify-content: center;
  column-gap: 0.313rem;
  list-style: none;
  margin-top: 2rem;
  position: relative;
  overflow: auto;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0 {
 }    //   background: #f6fdfd
  padding: 0.938rem 0px;
  border-radius: 0.625rem;
  a {

    border-radius: 0.625rem;
    padding: 0.938rem;
    color: #FFF;
    background: #3e2f5f;
    border-radius: 0.625rem;
    border-color: #3e2f60; } }
.pagination__link {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }

.pagination__button {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }

.pagination__link__active a {
  color: #FFF !important;
  background: #1f8ff9;
  border-radius: 0.625rem; }

.pagination__link__disabled a {
  color: #FFF;
  border: 0.125rem solid #3e2f5f {
    border-radius: 0.625rem; } }

.bold {
  font-weight: 700;
  font-size: 0.875rem; }

.column1 {
  // margin-left: 1.563rem !important
  border: 0.125rem solid black; }
.column2 {
  // margin-left: 1.563rem !important
  border: 0.125rem solid black; }

.column3 {
  margin-left: 4.688rem !important;
  border: 0.125rem solid black; }
