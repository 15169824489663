@import "../../styles/helpers";

.container {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    .detailContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-radius: 0.625rem;
        padding: 3.375rem 0;
        @include m {
            flex-direction: column-reverse;
            align-items: center;
            padding: 0.625rem 0; } } }

.title {
    margin-right: auto;
    margin-bottom: 1.25rem;
    @include body-1; }

.backArrowContainer {
    border: 0.063rem solid #E8E8E8;
    border-radius: 50%;
    padding: 0.75rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer; }

.form {
    position: relative;
    width: 100%;
    min-width: 18.75rem;
    margin: 0.625rem 0px;
    div {

        position: relative;
        color: #FFF;
        .userForm {
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            @include a {
                flex-direction: column;
                align-items: center; } } } }

.heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    @include m {
        margin-bottom: 1.125rem; }
    @include m {
        font-size: 0.938rem; }
    h2 {
        margin-left: 1%; } }
.input {
    width: 60%;
    min-width: 6.25rem;
    height: 2.625rem;
    padding: 0 0.875rem 0 0.875rem;
    border-radius: 0.313rem;
    border: 0.125rem solid $neutrals5;
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3; }

    &:focus {
        border-color: $neutrals3; }
    @include placeholder {
        color: $neutrals4; } }

.submitt {
    border-radius: 0.313rem;
    @include m {
        margin-bottom: 0.813rem; } }

.uploadPic {
    width: 301px;
    box-shadow: 0px 0px 0.313rem #5fc8f8;
    border-radius: 0.313rem;
    img {
        width: 100%;
        border-radius: 0.313rem; }
    button {
        width: 100%;
        border-radius: 0.313rem;
        z-index: 2;
        margin-top: -0.125rem;
        background: #3772FF;
        &:disabled, &:disabled &:hover {
            background: #044fff6e;
            cursor: not-allowed; } }
    @include m {
        margin: 1.75rem 0px; } }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

/* Firefox */
input[type=number] {
    -moz-appearance: textfield; }

.subscribeBtn {
    border: 0.125rem solid $black;
    background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
    border-radius: 8px;
    color: #F2F6F9 !important;
    text-decoration: none !important;
    padding: 8px 16px;
    max-width: 275px;
    width: 100%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold; }

.cancelBtn {
    border: 0.125rem solid #04DCFD;
    background: transparent;
    border-radius: 8px;
    color: #F2F6F9 !important;
    text-decoration: none !important;
    padding: 8px 16px;
    max-width: 275px;
    width: 100%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold; }

.inputBox {
    width: 100%;
    margin: 1.5rem;

    color: $white;

    select {
        background: #3e2f5f; }

    input {
        box-sizing: border-box;
        background: #3e2f5f !important;
        color: #FFF !important;
        height: 2.813rem;
        border-radius: 0.375rem;
        padding: 0px 0.875rem;
        width: 100% !important;
        font-family: Arial;
        color: $white !important;
        border: 1px solid #3e2f5f !important;
        &:disabled {
            cursor: not-allowed; }
        &::placeholder {
            color: #F2F6F9;
            opacity: 1; }

        &::-ms-input-placeholder {
          color: #F2F6F9; } }

    textarea {
        box-sizing: border-box;
        height: 140px;
        border: 0.063rem solid #CDCACA;
        border-radius: 0.375rem;
        padding: 0.875rem;
        width: 100%;
        font-family: Arial;
        background: #3e2f5f;
        color: $white !important; } }

.editProfileContainer {
    display: flex;
    flex-direction: column; }

.editProfileContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

.col_1 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
    flex: 0 0 25%; }

.col_2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 25%;
    @include m {
        width: 70%; }
    @include s {
        width: 100%; } }

.col_3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 25%;
    @include m {
        width: 70%; }
    @include s {
        width: 100%; } }

.inputBox {
    display: flex;
    flex-direction: column;
    @include m {
        align-items: flex-start !important; }
    label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #FFF; } }

.editProfilePicture {
    position: absolute !important;
    top: 43%;
    left: 52%;
    cursor: pointer;
    z-index: 1000; }

.profilePicture {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    width: 8.75rem;
    height: 8.75rem;
    border-radius: 50%;
    img {
        object-fit: cover;
        width: 8.75rem;
        height: 8.75rem; } }

.editProfileFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important;
    padding-right: 4%;
    @include m {
        flex-direction: row !important;
        justify-content: center !important; } }

.editProfilePicIcon {
    width: 4.875rem !important;
    height: 4.875rem !important;
    cursor: pointer; }

.profilePicSubText {
    color: #FFF;
    p {
        opacity: 0.9; } }

.required:after {
    content: " *";
    color: red; }

.loaderContent {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0 ,0 ,0.20 );
    margin: auto;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center; }

.info {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-Content: center !important; }

.verifyBtn {
    border: 0.125rem solid #000;
    background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
    border-radius: 8px;
    color: #F2F6F9 !important;
    text-decoration: none !important;
    padding: 2px 16px; }

.css-1fdsijx-ValueContainer {
    background: #3e2f5f !important; }

.react-tel-input .form-control {
    background: none !important;
    @media (max-width: 768px) {
        width: 100% !important; } }

