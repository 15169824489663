@import "../../styles/helpers";

.section {
    text-align: center;
    .container {
        max-width: 896px; } }

.top {
    max-width: 736px;
    margin: 0 auto 5rem;
    @include d {
        margin-bottom: 4rem; }
    @include m {
        margin-bottom: 2rem; } }

.title {
    margin-bottom: 1rem; }

.info {
    color: $neutrals4;
    span {
        color: $neutrals2;
        @include dark {
            color: $neutrals8; } } }

.list {
    display: flex;
    margin: 0 -1rem 2rem;
    @include m {
        display: block;
        margin: 0 0 2rem; } }

.item {
    flex: 0 0 calc(50% - 2rem);
    width: calc(50% - 2rem);
    margin: 0 1rem;
    padding: 1rem 1rem 1.5rem;
    border-radius: 1rem;
    border: 0.063rem solid $neutrals6;
    transition: box-shadow .2s;
    @include m {
        width: 100%;
        margin: 0; }
    @include dark {
        border-color: $neutrals3; }
    &:not(:last-child) {
        @include m {
            margin-bottom: 1.5rem; } }
    &:hover {
        box-shadow: 0px 2.5rem 2rem -1.5rem rgba(15, 15, 15, 0.12); } }

.preview {
    margin-bottom: 1.5rem;
    img {
        width: 100%;
        border-radius: 0.5rem; } }

.note {
    @include caption-2;
    color: $neutrals4; }
