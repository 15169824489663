@import "../../styles/helpers";

.dashButton {
    cursor: pointer;
    border-radius: 2.438em;
    box-shadow: 0px 0.563rem 0.938rem 0px #00000014;
    padding: 0.625rem 0.938rem;
    margin-top: 0.313rem;
    width: max-content;
    min-width: 8rem;
    @include poppins;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 0.625rem;
    pointer: cursor;
    color: $black;
    // width: 15em
    @media only screen and (max-width: "1060px") {
        background-color: white;
        padding: 0.313rem 0.313rem 0.313rem 0.625rem;
        @include s {
            font-size: 0.688rem;
            img {
                height: 1.063rem; } } } }

.btn_black {
    @media only screen and (max-width: "1060px") {
        background-color: #000;
        color: white; } }

.sumsub {
    width: 50vw;
    min-height: 70vh;
    @media screen and (max-width: '768px') {
        width: 80vw; } }

