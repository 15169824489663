.medium_modal{
  max-width: 760px !important;



  .react-tel-input .flag-dropdown, .react-tel-input .flag-dropdown.open, .react-tel-input .flag-dropdown.open .selected-flag{
    border: 0px !important;
    background-color: transparent !important;
  }

  .react-tel-input .form-control{
    width: 100%;
  }

  .deliveryForm, .paymentMethodForm{
    input{
      background: transparent;
      color: #FFF;

      &::placeholder{
        color: #FFF !important;
        opacity: 0.6 !important;
      }
    }
  }
}

