@import "../../../styles/helpers";

.container {
   color: black; }

.allNewsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-top: 5em;
    @include m {
        flex-direction: column; }
    .col_1 {
        flex: 0 0 33%; }
    .col_2 {
        flex: 0 0 33%; }
    .col_3 {
        flex: 0 0 33%; } }


.timeDate {
    color: #7B7F90;
    font-size: 0.8em; }

.allNewsCard {
    padding: 2em;
    padding-left: 0em;
    max-height: 23em;
    overflow: hidden;
    @include m {
        padding: 0px 0px 2.5rem 0px; }
    h3 {
        letter-spacing: -0.02em;
        color: #1C1235;
        font-size: 1.2em;
        line-height: 1.4em;
        margin-bottom: 1em; }
    p {
        max-height: 8.6em;
        overflow: hidden; }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0.8em; } }

.allNewsImageCard {
    padding: 2em;
    padding-left: 0em;
    max-height: 41em;
    overflow: hidden;
    @include m {
        padding: 0px 0px 2.5rem 0px; }
    h3 {
        letter-spacing: -0.02em;
        color: #1C1235;
        font-size: 1.2em;
        line-height: 1.4em; }
    p {
        max-height: 7em;
        overflow: hidden; }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0.8em;
        @include m {
            width: 80%; }
        @include s {
            width: 100%; } } }
.readMore {
    font-weight: 600 !important;
    font-size: 1em !important;
    color: #030303 !important;
    padding-right: 0.6em !important; }

.readMoreWrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 1em 0em; }

