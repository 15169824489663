
.nav.nav-tabs {
  .nav-item {
    .nav-link {
      color: #F2F6F9;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: transparent !important;
      border: 0px !important;
      opacity: 0.5;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      &.active {
        color: #F2F6F9 !important;
        opacity: 1 !important;
        border-bottom: solid 2px #1F90FA !important;
      }
    }
  }
}

.tab-pane{
  border: solid 2px #1F90FA !important;
  margin-top: 1rem;
  padding: 1.5rem;
  border-radius: 0.6rem;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: .5rem;
  }
}
