@import "../../styles/helpers";

.head {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 326px;
    padding: 2rem 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    @include t {
        height: 260px;
        padding-bottom: 4rem; }
    @include m {
        height: 230px;
        padding-bottom: 3.5rem; }
    .container {
        display: flex;
        justify-content: flex-end; }
    &.active {
        .btns {
            opacity: 0; }
        .file {
            opacity: 1;
            visibility: visible; } } }

.btns {
    transition: opacity .2s;
    .button {
        box-shadow: 0 0 0 0.125rem $neutrals4 inset;
        color: $neutrals8;
        @include m {
            flex-grow: 1;
            padding: 0 0.625rem; }
        svg {
            fill: $neutrals8; }
        &:hover {
            box-shadow: 0 0 0 0.125rem $blue inset; }
        &:not(:last-child) {
            margin-right: 1rem; } } }

.file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $neutrals8;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    @include m {
        align-items: flex-start;
        padding-top: 2.5rem; }
    &:before,
    &:after {
        content: "";
        position: absolute; }
    &:before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($neutrals1, .6); }
    &:after {
        top: 0.5rem;
        left: 0.5rem;
        right: 0.5rem;
        bottom: 0.5rem;
        border: 0.125rem dashed $neutrals6;
        border-radius: 0.75rem; }
    input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        font-size: 1400px;
        opacity: 0; }
    svg {
        margin-bottom: 1.5rem;
        fill: $neutrals8;
        @include m {
            width: 1.5rem;
            height: 1.5rem;
            margin-bottom: 0.75rem; } }
    .button {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        z-index: 4;
        @include t {
            bottom: 3rem; }
        @include m {
            right: 50%;
            transform: translateX(50%); } } }

.wrap {
    position: relative;
    z-index: 2; }

.info {
    font-size: 1.5rem;
    line-height: (32/24);
    font-weight: 600;
    @include m {
        font-size: 1rem; } }

.text {
    @include body-2;
    @include m {
        font-size: 0.75rem; } }

.body {
    position: relative;
    z-index: 3;
    padding: 5rem 0;
    @include t {
        padding-top: 0; }
    @include m {
        padding: 0 0 4rem; }
    .container {
        display: flex;
        align-items: flex-start;
        @include t {
            display: block; } } }

.user {
    flex-shrink: 0;
    width: 256px;
    margin-top: -193px;
    @include t {
        width: 100%;
        margin: -2rem 0 3rem; } }

.wrapper {
    flex: 0 0 calc(100% - 256px);
    width: calc(100% - 256px);
    padding-left: 4rem;
    @include x {
        padding-left: 2rem; }
    @include t {
        width: 100%;
        padding-left: 0; } }

.nav {
    display: flex;
    margin: 0 -0.375rem 2rem;
    @include m {
        margin: 0 -2rem 2rem;
        overflow: auto;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; }
        &:before,
        &:after {
            content: "";
            flex-shrink: 0;
            width: 2rem;
            height: 0.063rem; } } }

.link {
    flex-shrink: 0;
    margin: 0 0.375rem;
    padding: 0.375rem 0.75rem;
    border-radius: 0.875rem;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    @include m {
        margin: 0; }
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        @include m {
            margin-right: 0.75rem; } } }

.following,
.followers {
    padding-top: 1rem;
    @include m {
        padding-top: 0; } }
