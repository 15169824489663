@import "../../../styles/helpers";

.section {
    overflow: hidden !important;
    background-color: #F9F9F9;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-top: 3rem;

    @include h {
 }        //padding: 3rem 0 !important
    @include x {
        padding-bottom: 112px; }
    @include m {
        padding-bottom: 4rem; } }

.top {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    @include m {
        display: block; }
    @include s {
        margin-top: 5em; } }

.stage {
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @include m {
        font-size: 30px; } }



.label {
    margin-bottom: 0.75rem;
    @include hairline-2;
    color: $neutrals5; }

.field {
    .dropdown {
        min-width: 256px;
        @include m {
            min-width: 100%; } } }

.box {
    display: flex;
    align-items: center;
    width: 100%;
    // border: 0.125rem solid black
    justify-content: space-around;
    margin-right: auto;

    span {
        margin-right: 0.313rem; }
    @include m {
        margin: 0 0 1rem; }
    .dropdown {
        min-width: auto;
        margin-left: -1.5rem;
        @include m {
            display: inline-block; } } }

.wrapper {
    margin: 0 auto;
    width: 75%;
    @media only screen and (max-width: 599px) {
        width: auto;
        margin: 0 auto; } }

.autoHeight {
    height: auto !important; }

.item {
    // padding: 1.5rem
    overflow: hidden;
    background: $neutrals8;
    transition: box-shadow .2s;
    width: 100%;

    height: fit-content;
    color: $white;
    background: #261A39;
    box-shadow: 0px 4rem 4rem -3rem rgba(31, 47, 70, 0.247);
    border-radius: 0.625rem;
    @include m {
        height: fit-content;
        //width: 75vw !important
        //height: 450px
        //width: 75vw
        width: 100%; }
    @include h {
        height: fit-content; }

    @include dark;
    // &:hover
    //     box-shadow: 0px 4rem 4rem -3rem rgba(31, 47, 70, 0.12)
    //     .avatar
    //         width: 165px
    //         +d
    //             width: 174px
    // +s
    //     margin-left: 8.5%
    @media only screen and (max-width: 600px) {
        margin-bottom: 1.25rem; }

    // @media only screen and (max-width: "390px")
 }    //     margin-left: 5.5%
.head {
    display: flex;
    align-items: center;
    margin-bottom: 0.875rem;
    padding-bottom: 0.75rem;

    @include dark {
        border-color: $neutrals3; } }

.rating {
    display: flex;
    align-items: center;
    height: 1.5rem;
    margin-right: auto;
    padding: 0 0.5rem;
    border-radius: 0.75rem;
    @include caption-bold-2;
    color: $neutrals8; }

.icon {
    margin-right: 0.25rem;
    img {
        width: 1rem; } }

.control {
    display: flex; }

.button {
    svg {
        fill: $neutrals5;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } }
    &:not(:last-child) {
        margin-right: 0.5rem; } }

.body {
    text-align: left;
    padding-bottom: 0.3em;
    display: flex; }


.avatar {
    position: relative;
    //width: 100%;
    margin: 0 auto 0.375rem;
    transition: width .2s;
    height: 350px;

    overflow: hidden;
    @include m {
        height: 600px; }
    @include h {
 }        //height: 240px
    video {
 }        // transform: scale(1)
    & > img {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover; }
    p {
        color: $white;
        position: absolute;
        top: 91.5%;
        left: 4%;
        font-weight: 700; } }

.avatarVideoCard {
    position: relative;
    width: 100%;
    margin: 0 auto 0.375rem;
    transition: width .2s;
    border-top-right-radius: 0.625rem;
    border-top-left-radius: 0.625rem;

    overflow: hidden;
    video {
 }        // transform: scale(1)
    & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
 }        // border-radius: 0.313rem

    p {
        color: $white;
        position: absolute;
        top: 91.5%;
        left: 4%;
        font-weight: 700; } }

.reward {
    position: absolute;
    right: -0.25rem;
    bottom: -0.25rem; }

.cardTitle {
    color: #FFF;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;

    @include m {
 }        //font-size: 18px
    p {
        margin-bottom: 0; } }
.name {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0.125rem;
    font-weight: 500;
    word-break: break-all;
    border-bottom: 0.063rem solid #3E2F5F;
    padding-bottom: 0.75rem;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    @include m {
        margin-top: 0.2em;
        margin-bottom: 0.4em; }
    img {
        height: 3rem;
        width: 3rem;
        border-radius: 50%; }
    .ownerName {
        text-align: left;
        line-height: 1rem;
        margin-left: 0.625rem;
        // border: 0.125rem solid black
        p {
            color: #F2F6F9;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0; }
        h5 {
            color: #8B9CA9;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0; } }
    .love {
        flex-grow: 2;
        margin-right: 0.625rem;
        display: flex;
        justify-content: flex-start;
        cursor: default;
        margin-bottom: 1rem;
        .loveIcon {
            cursor: pointer;
            border-radius: 50%;
            box-shadow: 0px 8.741276741027832px 6.993021011352539px 0px #0000001c;
            width: 1.875rem;
            height: 1.875rem; }
        img {
            border-radius: 0%;
            width: 1.6rem; } } }





.nameDate {
    color: $secondary; }
.fixed_bottom {
    position: absolute;
    bottom: 0.125rem;
    left: 50%;
    margin-left: -4.875rem; }
.price {
    @include caption-2;
    color: $neutrals4;
    span {
        font-weight: 600;
        color: $neutrals2;
        @include dark {
            color: $neutrals6; } } }
.priceSection {
    padding: 0px 0.313rem;
    display: flex;
    justify-content: space-between;

    &:first-child {
        font-weight: 700;
        border: 0.125rem solid black; }

    &:nth-child(2) {
        font-weight: 500; } }

.auctionContainer {
    display: flex;
    width: 100%;
    margin-top: 0.313rem;
    column-gap: 0.313rem;
    justify-content: space-between;
    padding: 0px 0.625rem;
    font-weight: 700; }

.timeleft {
    flex: 0 0 50%;
    border: 0.125rem solid black;
    display: flex;
    align-items: center;
    border-radius: 0.625rem;
    padding: 0px 0.625rem;
    height: 3.08em;
    span {
        margin-left: 0.4em;
        font-size: 0.9em; } }



.bidNowButton {
    flex-grow: 1;
    button {
        width: 100%;
        border: 0.125rem solid $black;
        background: $black;
        font-weight: 700;
        color: white;
        height: 3.6em;
        border-radius: 0.625rem;
        padding: 0.625rem 1rem;
        font-size: 0.75rem; } }

.videoCover {
    video {
        object-fit: cover; } }

.cardAssetImg {
    transition: transform .6s;
    border-radius: 0.625rem; }

.cardAssetImg:hover {
    transform: scale(1.2); }

.priceLabel {
    color: #8B9CA9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 1rem;
    margin-bottom: 0; }

.priceAmount {
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0; }

.slide {
    display: flex !important;
    justify-content: center;

    @media (max-width: 599px) {
        width: 100%; } }


