@import "../../styles/helpers";

.card {
    display: flex;
    width: 75%;
    margin: 1.25rem auto;
    padding: 1.25rem;
    border: 0.063rem solid $secondary;

    &:hover {
        .control {
            visibility: visible;
            opacity: 1; } }
    @include dark {
        // background: $neutrals8
        color: $neutrals8; } }

.preview {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    // justify-content: center
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    flex-basis: 30%;
    margin-right: 0.625rem;
    & > img {
        width: 200px; } }

.control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.35);
    visibility: hidden;
    border-radius: 1rem;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: $neutrals8; } }

.category {
    position: absolute;
    top: 0.688rem;
    left: 0.5rem; }

.link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0.625rem 0;
    color: $neutrals2;
    cursor: pointer;
    @include dark {
        color: $neutrals8; } }

.body {
    display: flex;
    flex-basis: 99%;
    flex-wrap: wrap;
    justify-content: space-between;
 }    // align-items: center

.line, .linedate {
    &:first-child {
        margin-bottom: 0.75rem; }
    &:nth-child(2) {
        // margin-top: auto
        margin-bottom: 0.75rem; } }
.linedate {
    text-align: right;
    @include m {
        font-size: 0.75rem !important;
        text-align: left; } }

.title {
    margin-right: auto;
    padding-top: 0.063rem;
    flex-basis: 99%;
    overflow: auto hidden;
    // height: 1.688rem
    font-size: 1.125rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-weight: 600;
    word-wrap: break-word;
    // white-space: nowrap
    color: $secondary;
    margin-bottom: 0.313rem;
    @include m {
        font-size: 0.875rem; } }

.title::-webkit-scrollbar {
    display: none; }

.price {
    flex-shrink: 0;
    padding: 0.063rem 0.25rem;
    border-radius: 0.125rem;
    box-shadow: inset 0 0 0 0.063rem $green;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $green;
    flex-basis: 50%; }
.users {
    display: flex; }


.counter {
    word-break: break-word;
    word-wrap: break-word;
    * {
        color: #000 !important; }
    @include dark {
        * {
            color: #fff !important; } }
    a, .readMore {
        color: $secondary !important;
        font-size: bold; } }

.foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 0.063rem solid $neutrals6;
    @include caption-2;
    color: $neutrals4;
    @include dark {
        border-color: $neutrals3; } }

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  margin: auto;
  width: 75%;
  margin-top: 3rem;
  a {
    padding: 0.625rem;
    color: #FFF;
    background: #3e2f5f;
    border-radius: 0.625rem;
    border-color: #3e2f60; } }

.pagination__link {
  font-weight: bold; }

.pagination__link__active a {
    color: #FFF !important;
    background: #1f8ff9;
    border-radius: 0.625rem; }

.pagination__link__disabled a {
    color: #FFF;
    border: 0.125rem solid #3e2f5f {
        border-radius: 0.625rem; } }

.news_row2 {
    margin-top: 5em;
    // overflow-x: inherit
    // @media only screen and (max-width: "1023px")
 }    //     overflow-x: scroll

