.sectionTitle{
  color:  #F2F6F9;
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 0;
}

.sectionDesc{
  color: #8B9CA9;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

#threedSlider{
  img{
    border-radius: 0.6rem !important;

    @media(max-width: 599px){
      height: 90% !important;
    }
  }
}

.nftDetails{
  color: #F2F6F9;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.nftPeople{
  img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  color: #F2F6F9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  p{
    color: #8B9CA9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
  }
}
.slideControl{
  cursor: pointer;
}
