@import "../../styles/helpers";
.profileContainer {
    display: flex;
    column-gap: 1.25rem;
    @media only screen and (max-width: "1060px") {
        flex-wrap: wrap; } }

.wrapper {
    display: flex;
    background-color: #F4F4F4;
    border-radius: 0.625rem;
    height: 21.563rem;
    justify-content: center;
    @media only screen and (max-width: "1060px") {
        height: auto;
        padding-bottom: 15px; }


    .profilePic {
        margin-right: 1.25rem;
        img {
            height: 3.438rem;
            width: 3.438rem; } }

    @media only screen and (max-width: "1060px") {
        width: 100%; } }

.info {
    @media only screen and (max-width: "1060px") {
        background-color: white !important; } }

.cryptoRow {
    @media screen and (min-width: 1024px) and (max-width: 1250px) {
        display: grid;
        grid-template-columns: 1fr 1fr; } }

.dashButton {
    cursor: pointer;
    border-radius: 2.438em;
    box-shadow: 0px 0.563rem 0.938rem 0px #00000014;
    padding: 0.625rem 0.938rem;
    margin-top: 0.313rem;
    width: max-content;
    min-width: 8rem;
    @include poppins;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 0.625rem;
    pointer: cursor;
    color: $black;
    // width: 15em
    @media only screen and (max-width: "1060px") {
        background-color: white;
        padding: 0.313rem 0.313rem 0.313rem 0.625rem;
        @include s {
            font-size: 0.688rem;
            img {
                height: 1.063rem; } } } }

.btn_black {
    @media only screen and (max-width: "1060px") {
        background-color: #000;
        color: white; } }

.userDetails {
    @media only screen and (max-width: "1060px") {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between; }
    @include s {
        flex: 1 1 0; }
    h3 {
        font-size: 1.875rem;
        @include s {
            font-size: 1.25rem; } }
    p {
        font-size: 0.875rem;
        color: rgba(0, 0, 0, 0.4); } }

.dashtab {
    display: flex;
    margin-top: 2.5rem;
    flex-direction: column;
    align-items: center; }

.menuWrapper {
    display: flex;
    box-shadow: 0px 0.563rem 0.938rem 0px #00000014;
    border-radius: 0.625rem;
    margin-bottom: 1em;
    column-gap: 0.625rem;
    padding: 0.625rem;
    font-size: 0.75rem;
    color: #7B7B7B;

    .selected {
        cursor: pointer;
        background: $black;
        border-radius: 0.625rem;
        padding: 0.625rem 1.25rem;
        color: white;
        @include s {
            padding: 0.625rem; } }

    .nonSelected {
        cursor: pointer;
        padding: 0.625rem 1.25rem;
        @include s {
            padding: 0.625rem; } } }

.subMenuWrapper {
    display: flex;
    border-radius: 0.625rem;
    padding: 0.625rem;
    font-size: 0.75rem;
    color: #7B7B7B;

    .subSelected {
        cursor: pointer;
        padding: 0.625rem 1.5rem;
        color: $black;
        border-bottom: 0.125rem solid $black; }

    .subNonSelected {
        cursor: pointer;
        padding: 0.625rem 1.5rem;
        color: $black;
        border-bottom: 0.125rem solid #BEBEBE; } }

.walletDivider {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 0 0 86%;
    justify-content: flex-start;
    .divider {
        margin: 0em 2.2em;
        @include m {
            margin: 0em 0.5em; } } }


.rightWallet {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @include s {
        flex: 1 1 0px; } }

.maticSymbol {
    width: 1.75rem;
    padding-left: 0.25rem;
    @include s {
        width: 1.438rem; } }

.flexCenter {
    display: flex;
    flex-direction: row;
    align-items: center; }


.flexStraightAlign {
    display: flex;
    // justify-content: space-between
    align-items: center; }
