@import "../../styles/helpers";

.loaderContent {
  // position: absolute
  // top: 0
  // left: 0
  // bottom: 0
  // right: 0
  // background: #e6e8ec52
  margin: auto;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center; }

.section {
    padding: 0.625rem 0px;
    @include x {
        padding: 1.25rem 0; }
    @include m {
        padding: 0.875rem 0; } }


.bg {
    position: relative;
    flex-grow: 1;
    margin-right: 6rem;
    @include x {
        margin-right: 4rem; }
    @include d {
        margin-right: 2rem; }
    @include t {
        margin: 0 0 2rem; } }

.details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 384px;
    @include d {
        width: 336px; }
    @include t {
        width: 100%; } }

.preview {
    position: relative;
    img {
        width: 100%;
        border-radius: 1rem;
        max-width: 560px;
        box-shadow: 0.063rem 0px 0.5rem rgba(0, 0, 0, .2); } }

.categories {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem 0 0 -0.5rem; }

.category {
    margin: 0.5rem 0 0 0.5rem; }

.options {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 1.5rem;
    transform: translateX(-50%); }

.title {
    width: 100%;
    padding: 1.25rem 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include body-1;
    @include t {
 } }        // display: none

.wrapper {
    flex-grow: 1;
    width: 100%;
    padding: 1.25rem 0.938rem;
    @include t {
        padding-left: 0; } }

.imagePlayerWrapper {
    width: 350px;
    height: 350px;
    video {
        object-fit: cover;
        border-radius: 0.625rem; }
    @include m {
        height: 18.75rem;
        width: 18.75rem; }
    @include s {
        height: 200px;
        width: 200px; } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -2rem -1rem 0;

    @include m {
        display: block;
        margin: 0; } }

.card {
    flex: 0 0 calc(25% - 2rem);
    width: calc(25% - 2rem);
    margin: 2rem .5rem 0;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;

    @include x {
        flex: 0 0 calc(33% - 1.25rem);
        width: calc(33% - 1.25rem);
        margin: 2rem 0.625rem 0; }
    @include d {
        flex: 0 0 calc(50% - 2rem);
        width: calc(50% - 2rem);
        margin: 2rem 1rem 0; }
    @include m {
        width: 100%;
        margin: 0;
        min-width: 100%;
        &:not(:last-child) {
            margin-bottom: 2rem; } } }

.mobileDetailsCheckout {
    display: flex;
    flex-direction: column;
    align-items: center;
    >div {
        margin-bottom: 1.25rem; } }

.detailsCheckout {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 3%;
    margin-top: 1.25rem;
    >div {
        flex: 0 0 25%; }
    @include m {
        flex-direction: column;
        align-items: center;
        overflow: auto;
        >div {
            padding-bottom: 0.625rem;
            border-bottom: 0.063rem solid #b8b8b8; } } }

.singleDetail, .singleDetail1 {
    display: inline-block;
    width: 50%;
    font-size: 0.875rem;
    padding: 0.313rem;
    min-width: 290px; }

.singleDetail1 {
    width: 20%;
    min-width: 6.25rem; }

.noRecordsFound {
    flex: 0 0 100%;
    display: flex;
    flex-direction: row;
    justify-content: center; }

.pagination {
  display: flex;
  justify-content: center;
  column-gap: 0.313rem;
  list-style: none;
  margin-top: 2rem;
  position: relative;
  overflow: auto;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0 {
 }    //   background: #f6fdfd
  padding: 0.938rem 0px;
  border-radius: 0.625rem;
  a {


    border-radius: 0.625rem;
    padding: 0.938rem;
    color: #FFF;
    background: #3e2f5f;
    border-radius: 0.625rem;
    border-color: #3e2f60; } }

.pagination__link {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }

.pagination__button {
  border-radius: 0.625rem !important;
  font-weight: bold;
  white-space: nowrap;
  border: 0.125rem solid; }



.pagination__link__active a {
    color: #FFF !important;
    background: #1f8ff9;
    border-radius: 0.625rem; }

.pagination__link__disabled a {
    color: #FFF;
    border: 0.125rem solid #3e2f5f {
        border-radius: 0.625rem; } }

.backArrowCircle {
 }    // background: #FFFFFF


.pageTitle {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-left: 1rem;

    color: #F2F6F9; }


.infoRow {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 1.25rem;
    .infoLabel {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 141.5%;
        letter-spacing: -0.0.313rem;
        color: #8B9CA9; }

    .infoContent {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 141.5%;
        letter-spacing: -0.0.313rem;
        color: #FFF;
        margin-top: 1%; } }

.col_1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }

.viewContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include m {
        justify-content: center; } }
