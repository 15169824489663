.selectWallet {
    width: 100%;
    height: 40px !important;
    background: #3e2f60;
    color: #FFFFFF;
}

.confirmButton, .cancelButton {
    width: 50%;
    border-radius: 8px;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
    cursor: pointer;
    color: #F2F6F9 !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
