

.withDrawModalDescription {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.875rem;
    text-align: center;
    color: #272727;
    margin-top: 0.875rem;
    margin-bottom: 0.75rem;
    text-align: center; }

.cryptoToCurrency {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    align-items: center; }

.cryptoToUSDWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center; }


.USDValueLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #1A1A1A; }

.USDValueAmount {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.688rem;
    text-align: right;
    color: #1A1A1A; }

.USDValue {
    width: 100%;
    display: flex;
    justify-content: space-around; }

.amountCalculator {
    padding: 0.5rem;
    height: 7.5rem;
    width: 100%;
    border: 0.063rem solid #F4F4F4;
    background-color: #F4F4F4;
    border-radius: 0.5rem; }

.formGroup {
    width: 100%;
    display: block;
    margin: 0.625rem 0px;

    label {
        display: block;
        color: #231F20;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #272727; }

    .formControl {
        width: 100%;
        padding: 0.813rem;
        border-radius: 0.375rem;
        background: #f7f7f7;
        border: 0.063rem solid #ddd; }

    .formBtn {
        background: #1A1A1A;
        border-radius: 0.375rem;
        padding: 0.75rem;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.688rem;
        text-transform: capitalize;
        color: #FFFFFF;
        width: 100%; } }

.amountCalculatorSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 3.75rem;
    align-items: center; }




