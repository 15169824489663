.analyticsContainer {
    display: grid;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr; } }

@media screen and (max-width : 1071px) {
    .analyticsContainer {
        flex-direction: column; } }
