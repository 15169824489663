@import "../../../styles/helpers";

.tableTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #F2F6F9;
    margin: 3rem 0;

    @include s {
        font-size: 18px;
        margin-bottom: 0; } }







