@import "../../../styles/helpers";


.checkout {
    width: auto;
    margin: auto;
    position: relative;
    @include m {
        width: 100%; }
    h2 {
        color: #231F20; }
    .addCard {
        padding: 0.625rem;
        border: 0.125rem solid #ff76dc;
        border-image-slice: 1 !important;
        border-width: 0.125rem;
        transition: 0.6s;
        border-radius: 1.563rem !important;
        transition: 0.3s ease-in-out;
        background: #ff76dc;
        color: #231F20;
        text-transform: uppercase;
        font-weight: 900;
        position: absolute;
        top: 0;
        right: 0;
        &:hover {
            background: #231F20;
            color: #ffffff; } } }


.formGroup {
    width: 100%;
    display: block;
    margin: 0.625rem 0px;

    label {
        display: block;
        color: #231F20;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #272727; }

    .formControl {
        width: 100%;
        padding: 0.75rem;
        border-radius: 0.375rem;
        background: #FFFFFF;
        border: 0.063rem solid #1A1A1A; }

    .formBtn {
        background: #1A1A1A;
        border-radius: 0.375rem;
        padding: 0.75rem;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.688rem;
        text-transform: capitalize;
        color: #FFFFFF;
        width: 100%; } }


.addCardName {
    width: 100%;
    display: flex;
    margin: 0.625rem 0px;
    flex-direction: row; }

.cardLabel {
    display: block;
    color: #231F20;
    font-family: 'Poppins';
    font-style: normal;
    color: #272727;
    font-family: Poppins;
    font-size: 0.938rem;
    font-weight: 400;
    line-height: 1.688rem;
    letter-spacing: 0em;
    padding-bottom: 0.313rem; }


.addCardModal {
    width: 31.25rem;
    background: #ffffff;
    min-height: 200px;
    margin: 3.125rem auto;
    padding: 1.25rem;
    border-radius: 0.375rem;
    color: #231F20;
    position: relative;
    @include m {
        width: 100%; }
    h2 {
        text-align: center; }

    .close {
        position: absolute;
        top: 0.313rem;
        right: 0.625rem;
        color: #231F20;
        font-size: 1.25rem; } }

.modalDialog {
    overflow: auto;
    height: 100vh; }
.row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .col {
        width: calc(100% /2 - 0.625rem);
        @include m {
            width: 100%; } } }
.error {
    color: #ff0000 !important;
    display: block;
    text-align: left; }

.agreeBoxWrapper {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.6em; }


.agreeBoxText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #272727; }

.cardLabel::after {
    content: "*";
    color: red; }


.supportedCardsWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    h3 {
        font-size: 0.938rem; }
    img {
        height: auto;
        width: 2rem;
        margin-left: 0.625rem; } }




