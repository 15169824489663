.lii-section{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;

  @media (max-width: 1024px) {
    min-height: 70vh;
  }
}

.connectLeft{

  h3 {
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 599px) {
      font-size: 30px;
    }
  }

  p{
    color: #8B9CA9;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 599px) {
      font-size: 15px;
    }
  }
}

#newsletterBox{
  border-radius: 16px;
  background: #1F152E;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;

  p{
    color: #F2F6F9;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
  }

  input[type=text]{

    display: flex;
    padding: 12px 0px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
    border: 0px;
    outline: none;
    border-radius: 0px;
    margin-left: 1rem;


    &::placeholder{
      color: #FFF !important;
      opacity: 0.6 !important;
    }

  }

  button{
    border-radius: 8px;
    background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: flex-start;
    gap: 14px;
    align-self: stretch;
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.connectRight{
  .number{
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #04DCFD;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 400px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media (max-width: 599px){
      font-size: 200px;
    }
  }
  .title{
    color: #F8FCFF;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 599px){
      font-size: 15px;
    }
  }
  .desc{
    color: #8B9CA9;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1rem;
    @media (max-width: 599px){
      font-size: 10px;
    }
  }
}

.lii-view-more{
  border-radius: 8px;
  border: 2px solid #04DCFD;
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 14px;
  color: #F2F6F9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

