.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none; }

    &.dragging {
        cursor: pointer;
        cursor: hand; } }

.slick-slider {
    .slick-track, .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); } }

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before {
        display: table;
        content: ''; }

    &:after {
        display: table;
        content: '';
        clear: both; } }

.slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 0.063rem; }

[dir='rtl'] .slick-slide {
    float: right; }

.slick-slide {
    img {
        display: block; }

    &.slick-loading img {
        display: none; }

    &.dragging img {
        pointer-events: none; } }

.slick-initialized .slick-slide {
    display: block; }

.slick-loading .slick-slide {
    visibility: hidden; }

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 0.063rem solid transparent; }

.slick-arrow.slick-hidden {
    display: none; }

.slick-arrow {
    position: absolute;
    z-index: 2;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    font-size: 0;
    transition: all .2s;
    svg {
        position: relative;
        z-index: 2;
        // fill: $neutrals4
        transition: fill .2s; }
    &:hover {
        box-shadow: inset 0 0 0 0.125rem $neutrals6; }
    @include dark-common {
        &:hover {
            box-shadow: inset 0 0 0 0.125rem $neutrals3;
            svg {
 } } }                // fill: $neutrals6
    // &.slick-disabled
 }    //     opacity: .7

// creative slider
.creative-slider {
    position: static;
    .slick-list {
        overflow: visible; }
    .slick-slide {
        padding: 0 0.75rem;
        opacity: 0;
        transition: opacity .4s;
        &.slick-active {
            opacity: 1; } }
    .slick-arrow {
        bottom: 0.5rem;
        background: $neutrals8;
        @include dark-common {
            background: transparent; } }
    .slick-prev {
        right: 324px;
        @include d {
            right: 276px; }
        @include t {
            right: auto;
            left: calc(50% - 3.5rem); }
        @include m {
            left: calc(50% - 2.75rem); } }
    .slick-next {
        right: 276px;
        @include d {
            right: 228px; }
        @include t {
            right: calc(50% - 3.5rem); }
        @include m {
            right: calc(50% - 2.75rem); } } }

// popular slider
.popular-slider {
    height: auto;
    min-height: 18.75rem;
    overflow: hidden;
    .slick-disabled {
        display: none!important; }
    @include t {
        padding-bottom: 5rem; }
    @include m {
        padding-bottom: 0.938rem; }
    .slick-list {
        overflow: visible;
        height: max-content !important;
        @include s {
            margin: 0 -136px 0 0; } }
    .slick-track {
        display: inline-flex;
        @include s {
            height: fit-content; } }
    .slick-slide {
        height: auto;
        padding: 0 1rem;
        opacity: 0;
        transition: opacity .4s;
        @include m {
            padding: 0 0.25rem; }
        @include s {
            padding: 0 1.063rem;
 }            //height: fit-content

        &.slick-active {
            opacity: 1; }
        & > div {
            //display: flex
            height: 100%;
            flex-grow: 1;
            & > div {
                height: 100%;
                display: flex;
                justify-content: center; }
            @media screen and (min-width: 474px) {
                justify-content: center;
                align-items: center; } } }

    .slick-arrow {
        width: 2.313rem;
        height: 2.313rem;
        background: #272930;
        border-radius: 100%;
        top: calc(50% - 1.875rem);
        transform: translateY(-50%);
        &:hover,&:active {
 }            // background: #8DB1AD
        &::before {
            display: none; }
        @include t {
            top: auto;
            bottom: 0;
            transform: translateY(0); }
        @include m {
            top: calc((100% / 2) - 2.5rem); }
        img {
            width: 100%; } }
    .slick-prev {
        left: -3.75rem;
        img {
            transform: rotate(180deg); }
        @include x {
            left: -2.5rem; }
        @include t {
            left: calc(50% - 3.5rem); }
        @include m {
            left: -0.313rem; } }
    .slick-next {
        right: -3.75rem;
        @include x {
            right: -2.5rem; }
        @include t {
            right: calc(50% - 3.5rem); }
        @include m {
            right: -0.313rem; } } }

// bid slider
.bid-slider {
    position: static;
    .slick-list {
        overflow: visible;
        @include s {
            margin: 0 -215px 0 0; } }
    .slick-track {
        display: flex; }
    .slick-slide {
        height: auto;
        margin: 0 1rem;
        opacity: 0;
        transition: opacity .4s;
        @include m {
            margin: 0 0.5rem; }
        &.slick-active {
            opacity: 1; }
        & > div {
            display: flex;
            height: 100%;
            flex-grow: 1; } }
    .slick-arrow {
        top: 0.25rem; }
    .slick-prev {
        right: 3rem; }
    .slick-next {
        right: 0; } }

// collection slider
.collection-slider {
    position: static;
    @include m {
        padding-bottom: 4.5rem; }
    .slick-list {
        overflow: visible; }
    .slick-slide {
        padding: 0 1rem;
        opacity: 0;
        transition: opacity .4s;
        &.slick-active {
            opacity: 1; } }
    .slick-arrow {
        top: 0.25rem;
        @include m {
            top: auto;
            bottom: 0; } }
    .slick-prev {
        right: 3rem;
        @include m {
            right: auto;
            left: calc(50% - 2.75rem); } }
    .slick-next {
        right: 0;
        @include m {
            right: calc(50% - 2.75rem); } } }

// discover slider
.discover-slider {
    .slick-list {
        overflow: visible;
        padding-bottom: 4.5rem; }
    .slick-slide {
        padding: 0 0.5rem; }
    .slick-arrow {
        bottom: 0; }
    .slick-prev {
        left: calc(50% - 2.75rem); }
    .slick-next {
        right: calc(50% - 2.75rem); } }
