#payingLoader{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(45, 35, 68, 0.1);
  z-index: 1000;
  border-radius: .5rem;
}
#cartBuyButton{
  background: linear-gradient(143.38deg, #04DCFD 11.56%, #1F90FA 94.12%);
  color: #FFF;
  padding: 16px 24px 16px 24px;
  width: 90%;
  margin: 0 auto;
  position: relative;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;

  &:disabled{
    opacity: 0.6;
    cursor: not-allowed;
  }
}
.checkoutBox{
  background-color: #2b1f46;//rgba(62, 47, 95, 0.5);
  border-radius: .5rem;
  padding: 2rem;
  margin: 1rem 0;
  border: solid 1px #3e2f5f;
}


.addDeliveryBtn{
  background: none;
  border: none;
  outline: none;
  color: #1ea1fb;
  &:hover{
    background: none;
    border: none;
    outline: none;
    color: #1ea1fb;
    opacity: 0.9;
  }
}

.cartTotalsWrapper{
  color: #FFF;
  width: 90%;
  margin: .5rem auto;
  font-family: Poppins;

  > div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    text-align: left;
    width: 100%;
    font-family: Poppins;
  }
}

.cartCountControl{
  border-radius: 50% !important;
  overflow: hidden;
  color: #FFF !important;
  background: #3e2f5f !important;
  border-color: #3e2f5f !important;
  margin: .5rem;
  padding: 0;
  width: 30px !important;
  height: 30px !important;
}

#cartQuantityControls{
  color: #FFF;
  display: flex;
  flex: 1;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      width: 40%;
      text-align: center;
      font-size: 20px;
    }
  }
}
