.hero,
.h1,
.h2,
.h3,
.h4 {
    @include dm-sans;
    font-weight: 700; }

.hero {
    font-size: 6rem;
    line-height: 1;
    letter-spacing: -.02em; }

.h1 {
    font-size: 4rem;
    line-height: 1;
    letter-spacing: -.02em; }

.h2 {
    font-size: 3rem;
    line-height: (56/48);
    letter-spacing: -.02em; }

.h3 {
    font-size: 2.5rem;
    line-height: (48/40);
    letter-spacing: -.01em; }

.h4 {
    font-size: 2rem;
    line-height: (40/32);
    letter-spacing: -.01em; }
