.mediaWrapper{
  height: 250px;
  overflow: hidden;
  cursor: pointer;

  img, video, audio{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .5s; /* Animation */
  }

  @media (max-width: 1024px) {
    height: 150px;
  }

  &:hover{
    img{
      transform: scale(1.3)
    }
  }
}

.lightBoxWrapper{
  width: 100%;
  height: 100%;

  p{
    color: #FFF;
  }
}

.unlockableSelect{
  background-color: #261939 !important;
  border: 1px solid #1f152e !important;
  color: #7b7b7b !important;
  outline: none !important;
  box-shadow: none !important;
  height: 50px;
  width: 95%;
  display: inline-block;
  left: 3%;
  position: relative;
  margin-bottom: 1rem;

  option{
    color: #FFF !important;
    padding: 10px;
    &:checked{
      background-color: #3e2f5f !important;
    }
  }
}
