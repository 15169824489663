.slide {
  color: #FFF;
  h1{
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    span{
      color: #04DCFD;
    }

    @media (max-width: 768px){
      font-size: 30px;
    }
  }
  p{
    color: #D9D9D9;
    width: 70%;
    @media (max-width: 768px){
      width: 100%;
    }
  }
}
.slick-dots{
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  max-width: 1320px;
  margin: 0 auto !important;
  text-align: left !important;
  left: 0;
  right: 0;
}
.custom-dots {

  li button:before {
      font-size: 10px;
      color: #1F90FA;
  }
  li.slick-active button:before{
      font-size: 12px;
      color: #04DCFD;
  }

}
.liiHeroSlider{
  z-index: 2;
}
#heroVideo{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1;

  @media (max-width: 1280px) {
    width: auto;
    height: 100%;
    opacity: 0.4;
  }
}

.sliderCTA{
  border-radius: 8px;
  display: flex;
  padding: 12px 12px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 8px;
  background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
  cursor: pointer;
  color: #F2F6F9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-block;
  margin-top: 1rem;
}
