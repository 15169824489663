@import "../../styles/helpers";

.card {
    overflow: hidden;
    // box-shadow: 0px 0px 4.313rem rgba(0, 0, 0,  .28)
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.188rem 0.375rem, rgba(0, 0, 0, 0.23) 0px 0.188rem 0.375rem;
    // display: flex
    // flex-direction: column
    // cursor: pointer
    //min-height: 30rem
    // &:hover
    //     .control
    //         visibility: visible
    //         opacity: 1
    // +dark
    //     background: $neutrals8
    @include s {
        box-shadow: 0px 0px 4.313rem rgba(0, 0, 0,  .15); }

    .love {
        margin-left: 0.625rem;
        margin-top: 0rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px; }

    .exploreBuyButton {
        width: 100%;
        border-radius: 8px;
        display: flex;
        padding: 12px 6px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
        cursor: pointer;
        color: #F2F6F9;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        float: right;
        margin-right: .5rem;

        &:disabled {
            opacity: 0.7;
            font-size: 14px;
            line-height: 20px; } } }

.cardMyProfile {
    overflow: hidden;
    box-shadow: 0px 0px 4.313rem rgba(0, 0, 0,  .28);
    min-height: 38rem; }

.cardActiveListing {
    overflow: hidden;
    box-shadow: 0px 0px 4.313rem rgba(0, 0, 0,  .28);
 }    // min-height: 32.5rem !important

.preview {
    display: flex;
    flex-direction: column;
    // min-height: 200px
    // justify-content: center
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    max-height: 27rem;
    height: 27rem;
    width: 100%;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    @include m {
        max-height: 42rem;
        height: 42rem; }
    & > img {
        // border: 0.125rem solid black
        // width: 100%
 } }        // height: 100%
.previewImage {
    height: 100%;

    @include m {
        max-height: 42rem !important; }
    img {
        height: 100%;
        border-radius: 0.6rem;
        object-fit: cover;
        width: 100%;
        transition: transform .6s;

        @include m {
            width: 100%; } }
    img:hover {
        transform: scale(1.2); } }

.react-player {
    video {
        object-fit: cover; } }

.previewImage {
    @include m {
        height: 42rem !important; }
    img {
        height: 100%;
        object-fit: cover;
        transition: transform .6s; }
    img:hover {
        transform: scale(1.2); } }
.assetName {
    position: relative;
    font-style: normal;
    font-weight: 600;
    margin: 0 0.625rem;
    text-shadow: 0.0.313rem 0.0.313rem $black;
    color: $white;
    z-index: 4;
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    @include m {
        font-size: 16px; } }
.control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.35);
    visibility: hidden;
    border-radius: 1rem;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: $neutrals8; } }

.name {
    // border: 0.125rem solid
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.125rem;
    font-weight: 500;
    word-break: break-all;
    border-bottom: 0.063rem solid #3E2F5F;
    padding-bottom: 0.75rem;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    @include m {
        margin-bottom: 0px;
        padding-bottom: 0.563rem; }
    img {
        border-radius: 50%; }
    .ownerName {
        margin-left: 0.5rem;
        text-align: left;
        line-height: 2rem;
        color: #8B9CA9;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        p {
            color: #F2F6F9;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0; }
        h5 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 800;
            font-size: 1em;
            line-height: 1.313rem;
            letter-spacing: -0.03em;
            color: #000000; } }

    .love {
        flex-grow: 2;
        margin-right: 0.625rem;
        margin-left: 0.625rem;
        display: flex;
        justify-content: flex-end;

        .loveIcon {
            margin-right: 0.625rem;
            cursor: pointer;
            border-radius: 50%;
            padding: 0.188rem 0.5rem;
            box-shadow: 0px 8.741276741027832px 6.993021011352539px 0px #0000001c;
            width: 1.875rem;
            height: 1.875rem; }
        img {
            border-radius: 0%;
            width: 0.875rem;
            height: 0.875rem; } } }
.priceSection {
    padding: 0px 0.313rem;
    display: flex;
    justify-content: space-between;

    h5 {
        margin-bottom: 0; }

    &:first-child {
        font-weight: 700;
        border: 0.125rem solid black; } }
.category {
    position: absolute;
    top: 0.688rem;
    left: 0.5rem; }

.auctionContainer {
    margin-top: 0.625rem;
    column-gap: 0.313rem;
    justify-content: space-between;
    padding: 0px 0.313rem;
    font-weight: 700;
    height: 55px; }

.counterNumber {
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;

    .counterSep {
        width: 10px;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }

    > div {
        width: 40px;
        text-align: center; } }

.counterLabels {
    color: #8B9CA9;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    > div {
        width: 40px;
        text-align: center; }

    .counterSep {
        width: 10px;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        background: linear-gradient(143deg, #04DCFD 11.56%, #1F90FA 94.12%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; } }



.timeleft {
    border: 0.125rem solid #04DCFD;
    display: flex;
    align-items: center;
    border-radius: 0.625rem;
    padding: 0px 0.625rem;
    color: #FFF;
    height: 100%;
    img {
        margin-right: 0.375rem; }
    span {
        font-size: 0.9em; } }



.bidNowButton {

    flex-grow: 1;
    button {
        width: 100%;
        border: 0.125rem solid $black;
        background: $black;
        font-weight: 700;
        color: white;
        height: 2.313rem;
        border-radius: 0.625rem;
        padding: 0.625rem 1rem;
        font-size: 0.75rem; } }


.fixedContainer {
    margin-top: 0.625rem;
    padding: 0px 0;
    width: 100%;
    height: 60px;
    button {
        width: 100%;
        font-weight: 400;
        color: white;
        border-radius: 0.375rem;
        padding: 0.625rem 1rem;
        font-size: 0.75rem; } }

.favorite {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 2rem;
    height: 2rem;
    background: $neutrals8;
    box-shadow: 0px 0.5rem 1rem rgba(15, 15, 15, 0.2);
    border-radius: 50%;
    svg {
        width: 1.25rem;
        height: 1.25rem;
        fill: $neutrals4;
        transition: fill .2s;
        transition: opacity .2s; }
    &:before {
        content: "";
        position: absolute;
        top: 51%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 1.125rem;
        height: 1.125rem;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity .2s; }
    &:hover {
        svg {
            fill: $neutrals2; } }
    &.active {
        svg {
            opacity: 0; }
        &:before {
            opacity: 1; } }
    @include dark {
        background: $neutrals2;
        &:hover {
            svg {
                fill: $neutrals8; } } } }


.link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    color: $neutrals2;
    @include dark {
        color: $neutrals8; } }

.body {
    // display: flex
    // flex-direction: column
    flex-grow: 1; }

.line {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &:first-child {
        // margin-bottom: 0.75rem
        @media only screen and (max-width: "767px") {
            width: 100%; } }
    &:nth-child(2) {
        // margin-top: auto
 } }        // margin-bottom: 0.75rem

.title {
    margin-right: auto;
    padding-top: 0.063rem;
    flex-basis: 99%;
    overflow: auto hidden;
    // height: 1.688rem
    font-size: 0.625rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-weight: 600;
    word-wrap: break-word;
    // white-space: nowrap
    color: $neutrals1;
    margin-bottom: 0.313rem;
    height: 3.625rem; }

.title::-webkit-scrollbar {
    display: none; }

.price {
    flex-shrink: 0;
    padding: 0.063rem 0.25rem;
    border-radius: 0.125rem;
    box-shadow: inset 0 0 0 0.063rem $green;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $green;
    flex-basis: 50%; }
.users {
    display: flex; }

.avatar {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 0.125rem solid $neutrals8;
    @include dark {
        border-color: $neutrals2; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; }
    &:not(:first-child) {
        margin-left: -0.5rem; } }

.counter {
    word-break: break-word;
    word-wrap: break-word;
    @include dark {
        color: $neutrals1; } }

.foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 0.063rem solid $neutrals6;
    @include caption-2;
    color: $neutrals4;
    @include dark {
        border-color: $neutrals3; } }

.status {
    svg {
        margin: -0.125rem 0.25rem 0 0;
        fill: $neutrals4; }
    span {
        font-weight: 600;
        color: $neutrals3;
        @include dark {
            color: $neutrals8; } } }

.player-wrapper {
    position: relative;
    padding-top: 56.25%; }

.react-player {
    position: absolute;
    top: 0;
    left: 0; }

.indicator {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 2.25rem;
    height: 2.438em;
    z-index: 1;
    img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.priceLabel {
    color: #8B9CA9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0; }

.priceAmount {
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; }
