@import "../../styles/helpers";

.dropdown {
    position: relative;

    z-index: 3;
    &.active {
        z-index: 10;
        .head {
            .arrow {
                svg {
                    transform: rotate(180deg); } } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.head {
    position: relative;
    width: 100%;
    padding: 0 3rem 0 1rem;
    border-radius: 0.375rem;
    border: 0.063rem solid #17b5fb;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 3rem;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: box-shadow .2s;
    @include dark {
        box-shadow: inset 0 0 0 0.125rem #17b5fb; }
    &:hover {
        box-shadow: inset 0 0 0 0.125rem #17b5fb; } }

.selection {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.arrow {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    // border-radius: 50%
    // box-shadow: inset 0 0 0 0.125rem $neutrals6
    transform: translateY(-50%);
    svg {
        fill: $neutrals4;
        transition: transform .2s; }
    @include dark {
        box-shadow: inset 0 0 0 0.125rem $neutrals3;
        svg {
            fill: $neutrals8; } } }

.body {
    position: absolute;
    top: calc(100% + 0.125rem);
    left: 0;
    right: 0;
    padding: 0.5rem 0;
    border-radius: 0.75rem;
    background: #17b5fb;
    visibility: hidden;
    opacity: 0;
    transform: translateY(0.188rem);
    transition: all .2s;
    @include dark {
        background: $neutrals1;
        box-shadow: inset 0 0 0 0.125rem $neutrals3, 0 0.25rem 0.75rem rgba($neutrals1, .1); } }

.option {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    transition: color .2s;
    cursor: pointer;
    color: #FFF;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:hover,
    &.selectioned {
        color: #261A39; } }
