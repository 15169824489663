@import "../../../styles/helpers";
.section {
    width: 100%;
    height: calc(100vh - 6.563rem) !important;
    padding: 0px 0px !important;
    position: relative;
    @include h {
        padding: 0px 0px; }
    @include xh {
        padding: 2.5rem 0px !important; }
    @include m {
        padding: 0px !important; } }
.container {
    position: relative;
    //height: calc(100vh - 6.563rem)
    //height: auto
    //height: 100% !important
    // +m
    //     height: auto
    // +xh
    //  // height: 100% !important
 }    // border: 0.125rem solid black
.head {
    max-width: 600px;
    margin: 0 auto 3.625rem;
    text-align: center;
    @include x {
        margin-bottom: 112px; }
    @include m {
        margin-bottom: 4rem; }
    @include s {
        margin-bottom: 1.25rem; } }
.stage {
    margin-bottom: 0.5rem;
    @include hairline-2;
    color: $neutrals4; }

.title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    @include syne;
    margin-bottom: 1.5rem;

    @include m {
        font-size: 2rem; }
    @media only screen and (max-width: '600px') {
        top: -5.625rem; } }

.wrapper {
    position: relative;
    margin: 0 -0.75rem;
    height: 570px; }

.row {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.player {
    flex-grow: 1;
    @include t {
        max-width: 768px;
        margin: 0 auto; } }
.emblem {
    position: absolute;
    top: calc(100vh - 10rem);
    left: 50%;
    width: 7.5rem;
    height: 7.5rem;
    transform: translateX(-50%);
    z-index: 9;
    @include m {
        top: calc(100vh - 130px); }
    // +xh
    //     top: 95%
    img {
        width: 100%;
        height: 100%;
        object-fit: contain; } }
.details {
    flex-shrink: 0;
    width: 352px;
    margin-left: 128px;
    padding-bottom: 5rem;
    @include x {
        margin-left: 4rem; }
    @include d {
        width: 304px;
        margin-left: 2rem;
        padding-bottom: 4rem; }
    @include t {
        width: 100%;
        margin: 3rem 0 0;
        padding-bottom: 5rem; }
    @include m {
        margin-top: 2rem; } }

.subtitle {
    margin-bottom: 1.563rem;
    font-size: 1.188rem; }

.line {
    display: flex;
    margin: 0.625rem -1rem 1.25rem;
    @include d {
        margin-bottom: 1.5rem; } }

.item {
    display: flex;
    align-items: center;
    flex: 0 0 90%;
    margin: 0 1rem;
    height: 3.125rem; }

.avatar,
.icon {
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem; }

.avatar {
    img {
        width: 100%;
        height: 100%;
        // border-radius: 100%
        border-top-right-radius: 45%;
        object-fit: cover; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $light-pink;
    border-radius: 50%;
    svg {
        fill: $neutrals8; } }

.description {
    flex-grow: 1; }

.category {
    @include caption-2;
    color: $neutrals4; }

.text {
    font-weight: 500; }

.wrap {
    margin-bottom: 2.5rem;
    padding: 1.875rem 1rem;
    box-shadow: 0px 4rem 4rem -3rem rgba(31, 47, 70, 0.12);
    border: 0.125rem solid $secondary;
    // border-radius: 1.5rem
    text-align: center;
    height: 270px;
    @include d {
        margin-bottom: 2rem;
        padding: 1.5rem 1rem; }
    @include dark {
        background: $neutrals1;
        border-color: $secondary; } }

.info {
    @include body-bold-2; }

.currency {
    @include dm-sans;
    font-size: 3rem;
    line-height: (56/48);
    letter-spacing: -.02em;
    color: #FF76DC; }

.price {
    margin-bottom: 1.5rem;
    @include body-bold-1;
    color: $neutrals4; }

.timer {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem; }

.box {
    flex-shrink: 0;
    width: 4rem;
    &:not(:last-child) {
        margin-right: 1.25rem; } }

.number {
    @include dm-sans;
    font-size: 2rem;
    line-height: (40/32);
    letter-spacing: -.08px; }

.time {
    @include body-bold-2;
    color: $neutrals4; }
.floatingNft {
    // position: absolute
    position: relative;
    z-index: -1;
    // height: 710px
    //height:  calc(100vh - 315px)
    height: 100%;
    // height: calc(100vh - 315px)
    width: 100%;
    // border: 0.125rem solid black
    top: 0px;
    left: 0px;
    z-index: 9;
    display: flex;
    @include xh {
        top: 12%; }
    @media only screen and (max-width: "1251px") {
        justify-content: space-around;
        height: min-content; }
    @media only screen and (max-width: "500px") {
        top: 16%; } }

.leftFloater {
    height: 100%;

 }    // border: 0.125rem solid black
.rightFloater {
    height: 100%;

 }    // border: 0.125rem solid black
.centerFloater {
    height: 100%;
    flex-grow: 1;


 }    // border: 0.125rem solid black
.btns {
    display: flex;
    flex-direction: column;
    .button {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 0.5rem; } } }


.cardContainer {
    position: relative;
    width: 370.1.563rem;
    -webkit-animation: fadeEffect 6s;
    animation: fadeEffect 6s;
    animation-delay: 5s;
    @include smallDesk {
        width: auto; }
    @include tabDevice {
        width: auto; }
    // border: 0.125rem solid black

    .cardOne {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 270.1.563rem;
        height: 432px;
        cursor: pointer;
        background: $white;
        border-radius: 0.5rem;
        overflow: hidden;
        padding: 0.625rem;
        box-shadow: 0px 0px 1rem -0.188rem black;
        @include smallDesk {
            width: 220px; }
        @include tabDevice {
            width: 190px; }
        @include h {
                height: 18.75rem;
 }                // margin-top: -3.25rem
        img , video {
            // padding: 0.625rem
            width: 100%;
            height: 100%;
            border-radius: 0.375rem;
            @include h {
                height: 255px; } }
        p {
            // border: 0.125rem solid black
            padding: 0.313rem 0px 0px 0px;
            font-weight:  700; } }
    .bidPlaceOne {
        position: absolute;
        display: flex;
        padding: 0.375rem;
        box-sizing: border-box;
        border-radius: 1.688rem;
        height: 3.313rem;
        width: 199px;
        top: 17.5rem;
        left: -2rem;
        background-color: $white;
        box-shadow: 0px 0px 1rem -0.188rem black;
        @include smallDesk {
            width: 150px;
            right: -1.875rem;
            left: unset; }
        @include tabDevice {
            width: 150px;
            left: 6.188rem; }
        @include h {
            top: 11.25rem; }
        .bidPlaceImage {
            width: 2.5rem;
            height: 2.5rem;
            img {
                height: 100%;
                width: 100%;
                object-fit: fill;
                border-radius: 100%; } }
        p {
            font-weight: 500;
            font-size: 0.75rem; }

        h5 {
            text-transform: uppercase;
            font-size: 0.938rem; } } }


.cardContainer_small {
    position: relative;
    margin-top: 1.563rem;
    width: 370.1.563rem;
    display: flex;
    -webkit-animation: fadeEffect 7s;
    animation: fadeEffect 7s;
    animation-delay: 6s;
    justify-content: flex-end;
    // border: 0.125rem solid black
    @include smallDesk {
        width: auto; }

    @include tabDevice {
        width: 150px;
        margin-left: 6.25rem; }
    .cardOne {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 193.3.563rem;
        height: 255.1.438rem;
        cursor: pointer;
        background: $white;
        border-radius: 0.5rem;
        overflow: hidden;
        padding: 0.625rem;
        box-shadow: 0px 0px 1rem -0.188rem black;
        @include smallDesk {
            width: 10rem;
            margin-right: -5.938rem; }
        @include tabDevice {
            width: 100%; }

        @include h {
            height: 215px;
            margin-top: -1.25rem; }
        .videoSize {
            width: 100%;
            height: 230px;
            @include h {
                height: 11.25rem; } }
        img , video {
            // padding: 0.625rem
            width: 100%;
            height: 225px;
            border-radius: 0.375rem;
            @include h {
                height: 11.25rem; } }
        p {
            // border: 0.125rem solid black
            font-size: 9.1.563rem;
            padding: 0.313rem 0px 0px 0px;
            font-weight:  700; } }
    .bidPlaceOne {
        position: absolute;
        display: flex;
        padding: 0.375rem;
        box-sizing: border-box;
        border-radius: 1.688rem;
        align-items: center;
        top: 122px;
        left: 222px;
        height: 3.313rem;
        width: 199px;
        background-color: $white;
        box-shadow: 0px 0px 1rem -0.188rem $black;
        @include smallDesk {
            width: 150px; }
        @include tabDevice {
            left: 3.125rem;
            width: 150px; }
        .bidPlaceImage {
            width: 2.5rem;
            height: 2.5rem;
            img {
                height: 100%;
                width: 100%;
                object-fit: fill;
                border-radius: 100%; } }
        @include h {
            top: 6.25rem; }
        p {
            font-weight: 500;
            font-size: 0.75rem; }

        h5 {
            text-transform: uppercase;
            font-size: 0.938rem; } } }

.bidPlaceDetails {
    padding-left: 0.375rem;
    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%; } }


.cardContainer_right {
    position: relative;
    -webkit-animation: fadeEffect 9s {
        -webkit-animation: fadeEffect 9s; }
    animation: fadeEffect 9s;
    animation-delay: 8s;
    // border: 0.125rem solid black

    .cardOne {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 397px;
        height: 35em;
        border-radius: 0.5rem;
        overflow: hidden;
        padding: 0.625rem;
        box-shadow: 0px 0px 1rem -0.188rem black;
        cursor: pointer;
        background: $white;
        border: 0.063rem solid #0000;
        border-radius: 0.5rem;
        @include smallDesk {
            width: 285px; }
        @include tabDevice {
            width: 13.125rem; }
        @include h {
            height: 31.25rem; }
        .image_video_container {
            //padding: 0.625rem 0.625rem 0.313rem 0.625rem
            width: 100%;
            height: 32em;
            border-radius: 0.625rem;
            img,video {
                width: 100%;
                height: inherit;
                border-radius: 0.625rem; }
            // +m
            //     padding-top: 0.625rem
            @include s {
                padding: 0.625rem 0.313rem 0.313rem 0.313rem; }
            @media only screen and (max-width: "1251px") {
                height: 27em; }
            @media only screen and (max-width: "690px") {
                height: 21em; }
            @media only screen and (max-width: "500px") {
                height: 12em;
                padding-top: 0px; } }

        p {
            // border: 0.125rem solid black
            padding: 0.313rem 0px 0px 0px;
            font-weight:  700;
            @include m {
                padding: 0 0px 0px 0.625rem; } }
        @media only screen and (max-width: "1251px") {
            height: 30em; }
        @media only screen and (max-width: "767px") {
            width: 345px; }
        @media only screen and (max-width: "690px") {
            width: 18.75rem; }
        @media only screen and (max-width: "690px") {
            width: 270px;
            height: 24em; }
        @media only screen and (max-width: "500px") {
            width: 170px;
            height: 15em; } }

    .bidPlaceOne {
        position: absolute;
        display: flex;
        align-items: center;
        // border: 0.125rem solid black
        padding: 0.375rem;
        box-sizing: border-box;
        border-radius: 1.688rem;
        top: 1.875rem;
        right: 1.25rem;
        background-color: $white;
        max-width: 209px;
        min-width: fit-content;
        height: 3.375rem;
        @media only screen and (max-width: "767px") {
            height: 2.188rem; }
        box-shadow: 0px 0px 1rem -0.188rem black;
        @include m {
            right: 1rem;
            top: 0.938rem; }
        @include smallDesk {
            width: 170px; }
        @include tabDevice {
            width: 170px;
            left: -1.875rem;
            top: 3.75rem; }

        .bidPlaceImage {
            width: 2.5rem;
            height: 2.5rem;
            @media only screen and (max-width: "767px") {
                width: 1.875rem;
                height: 1.875rem; }
            img {
                height: 100%;
                width: 100%;
                object-fit: fill;
                border-radius: 100%; } }
        p {
            font-weight: 500;
            font-size: 0.75rem; }

        h5 {
            text-transform: uppercase;
            font-size: 0.938rem; } } }
.centerFloater {
    flex-grow: 1;
    // background-image: url("./background/aslyLogo.svg")
    ////////////background-position: 45% 50%
    // background-position: 30% 50%
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    @include smallDesk {
        background-position: center; }

    @include h {
        background-size: contain;
        height: 15.625rem;
        width: 100%;
        position: relative;
        top: 6.25rem; }
    // @media only screen and (max-width: "1251px")
    //     background-position: 50% 60%
    // @media only screen and (max-width: "767px")
    //   background-image: url("./background/asliAgif.gif")
    @include s {
        //width: 15.625rem
        background-position: center;
        margin: auto; }
    //     background-position: 100% 70%
    // @media only screen and (max-width: "690px")
    //     background-position: 0% 70%
    //     height: 100%
    // @media only screen and (max-width: "500px")
    //     background-position: 35% 54%

    @include xh {
        background-size: contain;
        width: 100%;
        position: relative;
        top: 15.25rem; } }

.rightCardVideo {
    width: 397px;
    height: 33em;
    @include smallDesk {
        width: 100%; }
    @include h {
        height: 400px; } }



